import React, { useState } from 'react';

const ImagePreview = ({ src }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleSize = () => {
    setIsEnlarged(!isEnlarged);
  };

  return (
    <div className="image-preview" onClick={toggleSize} style={{ cursor: 'pointer' }}>
      <img
        src={src}
        alt="Analyzed"
        className={isEnlarged ? 'large' : 'small'}
      />
    </div>
  );
};

export default ImagePreview;
