import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; 
import countries from 'i18n-iso-countries';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../css/style.scss';

// Импортируем необходимые локали
import enLocale from 'i18n-iso-countries/langs/en.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';
import lvLocale from 'i18n-iso-countries/langs/lv.json';
import esLocale from 'i18n-iso-countries/langs/es.json';

// Инициализируем локали
countries.registerLocale(enLocale);
countries.registerLocale(ruLocale);
countries.registerLocale(lvLocale);
countries.registerLocale(esLocale);

function SignUp() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '', fullName: '' }); 
  const navigate = useNavigate();
  const { login } = useAuth(); 
  const { t, i18n } = useTranslation();

  const [country, setCountry] = useState('');
  const locale = i18n.language || 'en';

  const [showPassword, setShowPassword] = useState(false);

  const getCountryOptions = () => {
    const allCountries = countries.getNames(locale);

    const includedCountries = [
      'UA', 'RU', 'BY', 'KZ',
      'US', 'MX', 'CA', 'AR', 'BR',
      'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT',
      'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE',
      'GB', 'NO', 'IS', 'TR',
      'CH', 'AL', 'AD', 'BA', 'MK', 'MD', 'MC', 'ME', 'RS', 'SM', 'VA', 'LI'
    ];

    return Object.entries(allCountries)
      .filter(([code]) => includedCountries.includes(code))
      .map(([code, name]) => ({ value: code, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    setCountry(null); // Сбрасываем выбранную страну при смене языка, если необходимо
  }, [i18n.language]);

  const handleSignUp = async (event) => {
    event.preventDefault();

    setErrorMessage('');
    setErrors({ email: '', password: '', fullName: '', country: '' });

    // Проверка на заполненность полей
    const newErrors = { email: '', password: '', fullName: '', country: '' };
    if (!email) {
      newErrors.email = t('email_required');
    } else if (!validateEmail(email)) {
      newErrors.email = t('email_invalid');
    }
    if (!password) newErrors.password = t('password_required');
    if (!fullName) newErrors.fullname = t('fullname_required');
    if (!country) newErrors.country = t('select_country');
    
    setErrors(newErrors);

    // Останавливаем отправку формы, если есть ошибки
    if (newErrors.email || newErrors.password) {
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/signup`, {
        user: {
          email,
          password,
          full_name: fullName,
          country,
        }
      });

      if (response.data.token) {
        // Сохраняем токен в localStorage
        localStorage.setItem('token', response.data.token);
        // Обновляем контекст пользователя
        login(response.data.user, response.data.token); // Предполагаем, что login обновляет состояние пользователя в контексте
        // Перенаправляем пользователя на страницу профиля или дашборда
        navigate('/projects'); // Обновите это согласно вашему маршруту профиля
      }
    } catch (error) {
      if (error.response) {
        const { error: serverError } = error.response.data;
        if (serverError === 'User with this email already exists.') {
          setErrorMessage(t('user_already_exists'));
        } else {
          setErrorMessage(t('registration_error'));
        }
      } else {
        // Ошибка установки ответа, или ответ не был получен вообще
        setErrorMessage(t('registration_error'));
      }
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="signup-container">
      <h2>{t('signup_heading')}</h2>

      <p className="mt-3">{t('signup_subheading')}</p>

      <form onSubmit={handleSignUp} className="signup-form" noValidate>
        {errorMessage && <div className="error_message_container">{errorMessage}</div>}
        <div className={`form-group ${errors.fullname ? 'has-error' : ''}`}>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder={t('full_name')}
            className={errors.fullname ? 'error-input' : ''}
          />
          {errors.email && <div className="error-message">{errors.fullname}</div>}
        </div>

        <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            className={errors.email ? 'error-input' : ''}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </div>

        <div className={`form-group ${errors.country ? 'has-error' : ''}`}>
          <select
            value={country || ''}  // Убедитесь, что значение не null
            onChange={(e) => setCountry(e.target.value)}
            className={`mb-0 ${errors.country ? 'error-input' : ''}`}
          >
            <option value="" disabled>{t('select_country')}</option>
            {getCountryOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.country && <div className="error-message">{errors.country}</div>}
        </div>

        <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('password')}
            className={errors.password ? 'password_input error-input' : 'password_input'}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className="password-toggle-icon"
          />
          {errors.password && <div className="error-message">{errors.password}</div>}
        </div>

        <button type="submit" className="signup-button">{t('signUp')}</button>
      </form>
      <p className="login-link">{t('already_signed_up')} <Link to="/login" className="signup_form_link">{t('log_in_here')}</Link></p>
    </div>
  );
}

export default SignUp;
