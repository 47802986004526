import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import TimestampToDate from './TimestampToDate';
import '../css/style.scss';

function UserProfile() {
  const API_URL = process.env.REACT_APP_API_URL;
  const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY;
  const stripePromise = loadStripe(STRIPE_PK_KEY);
  const { user, logout, updateUser } = useAuth(); // функция updateUser для обновления данных пользователя в контексте
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [subscriptionDate, setSubscriptionDate] = useState('');
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const [subscriptionСanceled, setsubscriptionСanceled] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalPlan, setUpgradeModalPlan] = useState(null);

  const handleOpenCancelModal = () => setShowCancelModal(true);
  const handleOpenRenewModal = () => setShowRenewModal(true);
  const handleOpenUpgradeModal = (planKey) => setUpgradeModalPlan(planKey);
  const handleCloseCancelModal = () => setShowCancelModal(false);
  const handleCloseRenewModal = () => setShowRenewModal(false);
  const handleCloseUpgradeModal = () => setUpgradeModalPlan(null);

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');

  const isAdmin = user?.admin;

  const plans = {
    free: {
      title: "Free",
      price: "0€ / " + t('month'),
      features: ["1 " + t('project'), "20 " + t('themes_r'), "10 " + t('posts_r'), "10 " + t('scenarios_r'), "3 " + t('storytelling_r')],
    },
    monthly: {
      title: "Copy Brain - Monthly",
      price: "25€ / " + t('month'),
      features: ["3 " + t('projects_r'), t('unlimited') + ': ' + t('themes'), t('unlimited') + ': ' + t('posts'), t('unlimited') + ': ' + t('scenarios'), t('unlimited') + ': ' + t('storytelling'), t('profile_generation_heading'), 'Instagram ' + t('profile_analysis')],
    },
    annual: {
      title: "Copy Brain - Annual",
      price: "200€ / " + t('year'),
      features: ["3 " + t('projects_r'), t('unlimited') + ': ' + t('themes'), t('unlimited') + ': ' + t('posts'), t('unlimited') + ': ' + t('scenarios'), t('unlimited') + ': ' + t('storytelling'), t('profile_generation_heading'), 'Instagram ' + t('profile_analysis')],
    }
  };

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${API_URL}/subscription-details`, {
          params: { user_email: user.email },
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        setSelectedPlan(response.data.subscription_plan);
        setSubscriptionDate(response.data.subscription_expires_at);
        setSubscriptionStatus(response.data.subscription_status);
        setsubscriptionСanceled(response.data.subscription_canceled);
        setSubscriptionLoaded(true);
      } catch (error) {
        setErrorMessage(t('subscription_load_error'));
        setSubscriptionLoaded(false);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSubscriptionDetails();
    } else {
      navigate('/login');
    }
  }, [user, token, navigate]);

  const handleChangePlan = async (newPlanName) => {
    const stripe = await stripePromise;

    setLoading(true);

    try {
      // Запрашиваем URL сессии у вашего сервера
      const response = await axios.post(`${API_URL}/create-checkout-session`, {
        plan_name: newPlanName,
        user_email: user.email,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (newPlanName === "free") {
        setSelectedPlan(response.data.subscription_plan);
        setSubscriptionDate(response.data.subscription_expires_at);
      } else {

        const sessionId = response.data.session_id;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });

        if (error) {
        }
      }
    } catch (error) {
      setErrorMessage(t('subscription_link_error'));
    } finally {
      setLoading(false);
    }
  };

  const handlePlanUpgrade = async () => {
    const newPlanName = upgradeModalPlan;

    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/upgrade-subscription`, {
        plan_name: newPlanName,
        user_email: user.email,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSelectedPlan(response.data.subscription_plan);
      setSubscriptionDate(response.data.subscription_expires_at);
      setSubscriptionStatus(response.data.subscription_status);
      setsubscriptionСanceled(response.data.subscription_canceled);
    } catch (error) {
      setErrorMessage(t('subscription_link_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/cancel-subscription`, {
        user_email: user.email
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSelectedPlan(response.data.subscription_plan);
      setSubscriptionDate(response.data.subscription_expires_at);
      setSubscriptionStatus(response.data.subscription_status);
      setsubscriptionСanceled(response.data.subscription_canceled);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setErrorMessage(t('subscription_cancel_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleRenewSubscription = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/renew-subscription`, {
        user_email: user.email
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSelectedPlan(response.data.subscription_plan);
      setSubscriptionDate(response.data.subscription_expires_at);
      setSubscriptionStatus(response.data.subscription_status);
      setsubscriptionСanceled(response.data.subscription_canceled);
    } catch (error) {
      console.error('Error reneweding subscription:', error);
      setErrorMessage(t('subscription_renew_error'));
    } finally {
      setLoading(false);
    }
  };

  const isSubscriptionActive = () => {
    const currentDate = new Date();
    const expirationDate = new Date(subscriptionDate * 1000); // Преобразование Unix timestamp в JavaScript Date
    return expirationDate > currentDate;
  };

  function CancelModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h1>{t('confirm_cancellation')}</h1>
          <p>{t('are_you_sure_cancel_subscription')}</p>
          <button onClick={() => {onConfirm(); onClose();}} className="modal-billing-button">{t('confirm')}</button>
          <button onClick={onClose} className="modal-cancel-button">{t('cancel')}</button>
        </div>
      </div>
    );
  }

  function RenewModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h1>{t('confirm_renew')}</h1>
          <p>{t('are_you_sure_renew_subscription')}</p>
          <button onClick={() => {onConfirm(); onClose();}} className="modal-billing-button">{t('confirm')}</button>
          <button onClick={onClose} className="modal-cancel-button">{t('cancel')}</button>
        </div>
      </div>
    );
  }

  function UpgradeModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;

    const modalText = t('subscription_upgrade_modal_text').split('\n').map((text, index) => (
      <p key={index}>{text}</p>
    ));

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h1>{t('subscription_upgrade')}</h1>
          {modalText}
          <button onClick={() => {onConfirm(); onClose();}} className="modal-billing-button">{t('confirm')}</button>
          <button onClick={onClose} className="modal-cancel-button">{t('cancel')}</button>
        </div>
      </div>
    );
  }

  function handlePlanClick(plan, key) {
    if (selectedPlan === 'free') {
      if (plan.title === 'Free') {
        handleOpenCancelModal();
      } else {
        handleChangePlan(key);
      }
    } else {
      if (plan.title === 'Free') {
        handleOpenCancelModal();
      } else {
        handleOpenUpgradeModal(key);
      }
    }
  }

  return (
    <div className="user-profile-outer-container">
      <h1>{t('profile')}</h1>

      <div className={`user-profile-container ${loading ? 'loading' : ''}`}>
        <div className="user-details">
          <h2>{t('your_details')}</h2>
          <p><strong>{t('full_name')}:</strong> {user.full_name}</p>
          <p><strong>{t('email')}:</strong> {user.email}</p>
          <p><strong>{t('balance')}:</strong> {user.points} BP <img src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/coin.webp" style={{ width: '22px', marginLeft: '4px' }} /></p>
          
          {subscriptionLoaded && (
            <h3>
              {t('current_plan')}: 
              <span className="current-plan ml-1">
                {selectedPlan === 'free' ? t('free_plan') : selectedPlan}
              </span>
            </h3>
          )}

          {subscriptionLoaded && selectedPlan != 'free' && selectedPlan != 'alice' && (
            <div className="subscription-details mt-3">
              <span className="subscription-date-container">
                {t('subscription_expires_at')}:  <TimestampToDate timestamp={subscriptionDate} />
              </span>
              {subscriptionLoaded && !subscriptionСanceled && (
                <button onClick={handleOpenCancelModal} className="cancel-subscription-button">
                  {t('cancel_subscription')}
                </button>
              )} 
            </div>
          )}

          <CancelModal isOpen={showCancelModal} onClose={handleCloseCancelModal} onConfirm={handleCancelSubscription} />

          {subscriptionLoaded && subscriptionСanceled && (
            <span className="subscription-date-container mt-2">
              {t('subscription_canceled')}

              <button onClick={handleOpenRenewModal} className="subscription-plan-link mt-0 with_left_margin">
                {t('subscription_renew')}
              </button>
            </span>
          )}

          <RenewModal isOpen={showRenewModal} onClose={handleCloseRenewModal} onConfirm={handleRenewSubscription} />

          {subscriptionLoaded && (
            <div className="subscription-plans">
              {Object.entries(plans).map(([key, plan]) => (
                <div key={key} className={`plan ${selectedPlan === key ? 'active' : ''}`}>
                  <h3>{plan.title === 'Free' ? t('free_plan') : plan.title}</h3>
                  <span className="subscription-plan-price">{plan.price}</span>

                  <ul>
                    {plan.features.map(feature => <li key={feature}>{feature}</li>)}
                  </ul>

                  {selectedPlan !== key && (
                    selectedPlan !== 'free' && subscriptionСanceled && key === "free" ? (
                      <div className="subscription-plan-text-info">{t('subscription_plan_will_be_selected')} </div>
                    ) : (
                      <span className="subscription-plan-link" onClick={() => handlePlanClick(plan, key)}>
                        {t('choose')} {plan.title}
                      </span>
                    )
                  )}
                </div>
              ))}
            </div>
          )}

          <UpgradeModal
            isOpen={!!upgradeModalPlan}
            onClose={handleCloseUpgradeModal}
            onConfirm={handlePlanUpgrade}
            plan={plans[upgradeModalPlan]?.title}
          />
        </div>

        {loading && (
          <div className="overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
