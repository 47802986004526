import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuthorSelect = ({ authorId, setAuthorId }) => {
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await axios.get('http://localhost:3000/authors');
        setAuthors(response.data);
      } catch (error) {
        console.error('Ошибка при получении авторов:', error);
      }
    };

    fetchAuthors();
  }, []);

  return (
    <div>
      <label>Author:</label>
      <select value={authorId} onChange={(e) => setAuthorId(e.target.value)}>
        <option value="">Select Author</option>
        {authors.map((author) => (
          <option key={author.id} value={author.id}>
            {author.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AuthorSelect;
