import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import TextToggle from './TextToggle';
import '../css/style.scss';

function Story() {
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [storyId, setStoryId] = useState('');
  const [storyTitle, setStoryTitle] = useState('');
  const [storyContent, setStoryContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const [story, setStory] = useState({
    id: '',
    title: '',
    content: ''
  });

  const token = localStorage.getItem('token');

  useEffect(() => {
    const storyId = location.state?.storyId;

    if (storyId) {
      fetchStory(storyId);
    } else {
      navigate('/projects'); // Направлять пользователя обратно, если storyId не предоставлен
    }
  }, [location.state, navigate]); 

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = story.content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied'));
  };

  const fetchStory = async (storyId) => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_URL}/stories/${storyId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const story = response.data[0];

      setStory({
        id: storyId,
        title: response.data.story_theme || '',
        content: cleanText(response.data.story_text || '')
      });
    } catch (error) {
      if (error.response?.status === 401) {
        logout();
        navigate('/login');
      } else {
        setErrorMessage(t('story_fetch_error'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate(-1)}>{t('back')}</span>

      <h2><TextToggle text={story.title} /></h2>

      {story.content && <div className="post-preview">{story.content}</div>}

      {story.content && (
        <button onClick={handleCopyText}>{t('copy_text')}</button>
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default Story;