import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import '../css/style.scss';

const CountdownComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const targetDate = new Date('July 1, 2024 00:00:00').getTime();

  useEffect(() => {
    navigate('/education/courses/1');
  });

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{t('event_started')}</span>;
    } else {
      return (
        <div className="countdown">
          <div className="countdown_item">
            <div className="countdown_number">
              {days}
            </div>

            <div className="countdown_text">
              {t('days')}
            </div>
          </div>

          <div className="countdown_item">
            <div className="countdown_number">
              {hours}
            </div>

            <div className="countdown_text">
              {t('hours')}
            </div>
          </div>

          <div className="countdown_item">
            <div className="countdown_number">
              {minutes}
            </div>

            <div className="countdown_text">
              {t('minutes')}
            </div>
          </div>

          <div className="countdown_item">
            <div className="countdown_number">
              {seconds}
            </div>

            <div className="countdown_text">
              {t('seconds')}
            </div>
          </div>
        </div>
      );
    }
  };

  return <Countdown date={targetDate} renderer={renderer} />;
};

// Главный компонент
const Game = () => {
  const { t } = useTranslation();

  return (
    <div className="countdown_container">
      <div className="countdown_inner">
      <Link to="/projects" className="post_container_back_link">{t('back')}</Link>

        <div className="countdown_heading">
          <h1>{t('until_travel')}:</h1>
        </div>

        <CountdownComponent />

        <div className="countdown_rocket_container">
          <img className="countdown_rocket" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/rocket.webp" />
        </div>
      </div>
    </div>
  );
};

export default Game;