import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = () => {
    useEffect(() => {
      const hostname = window.location.hostname;
      let title, description;

      if (hostname.includes('copybrain.es')) {
        title = 'CopyBrain: aplicación impulsada por IA para la generación de contenido en redes sociales';
        description = 'CopyBrain es una aplicación para generar temas y crear contenido creativo para redes sociales utilizando IA. Obtén ideas únicas para publicaciones en tu nicho y mejora tu estrategia en redes sociales.';
      } else if (hostname.includes('copybrain.lv')) {
        title = 'CopyBrain: ar mākslīgo intelektu darbināma lietotne sociālo mediju satura ģenerēšanai';
        description = 'CopyBrain ir lietotne, kas izmanto mākslīgo intelektu, lai ģenerētu tēmas un radītu radošu saturu sociālajiem medijiem. Iegūsti unikālas idejas ierakstiem savā nišā un uzlabo savu sociālo mediju stratēģiju.';
      } else {
        title = 'CopyBrain: AI driven app for social media content generation';
        description = 'CopyBrain is an app for generating themes and creating creative content for social media using AI. Get unique ideas for posts in your niche and enhance your social media strategy';
      }

      document.title = title;
      document.querySelector('meta[name="description"]').setAttribute('content', description);
    }, []);

    return (
      <Helmet>
        <title>{document.title}</title>
        <meta name="description" content={document.querySelector('meta[name="description"]').getAttribute('content')} />
      </Helmet>
    );
};

export default MetaTags;
