import React from 'react';
import { useTranslation } from 'react-i18next';

function TimestampToDate({ timestamp }) {
  const { i18n } = useTranslation();
  const date = new Date(timestamp * 1000);

  // Форматируем дату с использованием текущей локали
  const formattedDate = date.toLocaleDateString(i18n.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return <div className="subscription-date-text">{formattedDate}</div>;
}

export default TimestampToDate;
