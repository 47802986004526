import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreateReel() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, duration, postLanguage, reelFormat, reelCta } = location.state || {};
  const { niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, textCustomisation, setTextCustomisation, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();
  const [reelContent, setReelContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const { selectedProjectId, stateActiveTab: stateActiveTab } = location.state || {};

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (theme) { // Убедитесь, что вызов только при наличии темы
      generateReel(theme, duration, reelFormat, reelCta); // Убедитесь, что вызывается только один раз
    }
  }, [theme, duration, reelFormat, reelCta]); // Проверка правильных зависимостей

  const generateReel = async (theme, duration, reelFormat, reelCta) => {
    setLoading(true);

    setProgress(0); // Сбрасываем прогресс
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); // Обновляем прогресс каждые 10%
    }, 150); // Интервал обновления прогресса

    try {
      const contentParts = [];

      if (niche) contentParts.push(`Niche: ${niche}`);
      if (audience) contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Goal: ${contentGoal}`);
      if (textTone) contentParts.push(`Tone and style: ${textTone}`);
      if (productService) contentParts.push(`Product or service: ${productService}`);

      const contentQuery = contentParts.join(', ');

      let customisationPart = '';

      if (textCustomisation) {
        customisationPart = `Adapt the output to match the writing style of the provided sample: ${textCustomisation}. Pay close attention to the tone, word choice, sentence structure, and overall flow of the sample text. Ensure that the final output maintains the individual’s unique voice and style, making it feel as though they wrote it`;
      }

      const languageToUse = postLanguage || selectedLanguage;

      const languagePart = {
        'ru': 'in Russian',
        'lv': 'in Latvian',
        'es': 'in Spanish',
        'it': 'in Italian'
      }[languageToUse] || 'in English';

      // Headings
      const titlesList = [
        "What happens if you…", "A new way to …", "How to become … in … days?", "How to guarantee … with/on …?", 
        "Imagine that…", "A thought that…", "A real … is not about …", "Just one … and you …", 
        "There is only one …", "Why I don’t… (believe in / agree with)", "Honest opinion about …", 
        "The perfect solution for…", "This isn’t a rule, it’s the law of …!", "Where to … in order to …", 
        "It turns out …", "What's actually better: … or …", "This reel will save you …", 
        "Top strategy in …", "The main question in …", "How to make … on …", "How to really … ?", 
        "… requires these …", "How to talk to …", "What not to do when …", "It’s normal to …", 
        "Exclusive for …", "Why haven’t I … yet?", "… for everyone who …", "… unknown facts about …", 
        "The only way to …", "Do this now if you want to …", "Everyone thinks that …, but no", 
        "The sad truth about …", "I couldn't … until I understood this…", "Get ready! Everyone goes through this when…", 
        "The best example of how to …", "While everyone is …, I’m …", "Now all your …", 
        "Where you should/shouldn't …", "A powerful … for …", "My favorite case (in)...", 
        "Ask yourself this if …", "This isn't the most ethical lifehack for …", "You’re not … until/unless you …", 
        "I’ll explain why…", "Two universal tips for …", "Here's why the most … are …", 
        "Bet you've never …", "Bad advice: how to …", "Say thanks to … if ….", "If you have … – do … urgently", 
        "… is the worst decision for …", "A simple formula for …", "Where does … begin?", 
        "How to do … in (a minute/hour/year…)", "Want to …? Forget about …", "The biggest mistake in …", 
        "How I answer the question: “...?”", "The main thing that kept me from …", "You will … until you …"
      ].join(', ');

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-11-20",
        messages: [
          { role: "system", content: "You are an assistant specializing in creating scripts for short Instagram videos." },
          { 
            role: "user", 
            content: `You need to put in effort and create a script for a short video on the topic "${theme}". Based on: ${contentQuery}. Use a title from the list: ${titlesList}. Short video duration: ${duration}. Include an approximate breakdown by seconds. Video format: ${reelFormat}. Add a call-to-action at the end: ${reelCta}. Make sure to write the entire text ${languagePart}. ${customisationPart}. Make it an excellent result, so I can be proud of it!`
          }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;
      const cleanedContent = cleanText(newContent); // Очистка текста
      setReelContent(cleanedContent);
      
      saveReel(theme, cleanedContent);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      setReelContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = reelContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); // Копируем текст в буфер обмена
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); // Сообщение об успешном копировании
  };

  const saveReel = async (theme, content) => {
    try {
      const response = await axios.post(`${API_URL}/reels`, {
        theme_name: theme,
        content: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate('/projects', { state: { selectedProjectId, stateActiveTab: stateActiveTab } })}>{t('back')}</span>

      <h2>{t('reel_result_heading')} <TextToggle text={theme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {reelContent && <div className="post-preview">{reelContent}</div>}

      {reelContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreateReel;