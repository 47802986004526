import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import LessonForm from './LessonForm';
import LessonEditForm from './LessonEditForm';
import PricePlanForm from './PricePlanForm';
import PricePlanEditForm from './PricePlanEditForm';
import '../css/style.scss';

const CourseDetails = ({ match }) => {
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [pricePlans, setPricePlans] = useState([]);
  const [showLessonForm, setShowLessonForm] = useState(false);
  const [showPricePlanForm, setShowPricePlanForm] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);
  const [editingPricePlan, setEditingPricePlan] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation()
  const { logout } = useAuth();

  const API_URL = process.env.REACT_APP_API_URL;

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCourseDetails();
  }, []);

  const fetchCourseDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/courses/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setCourse(response.data.course);

      const sortedLessons = response.data.lessons.sort((a, b) => a.order_number - b.order_number);
      setLessons(sortedLessons);

      setPricePlans(response.data.price_plans);
    } catch (error) {
      if (error.response?.status === 401) {
        logout();
        navigate('/login');
      } else {
        console.error('Error fetching course details:', error);
        // setErrorMessage(t('post_fetch_error'));
      }
    }
  };

  const handleLessonAdded = (lesson) => {
    setLessons([...lessons, lesson]);
    setShowLessonForm(false);
  };

  const handleEditLesson = (lesson) => {
    setEditingLesson(lesson);
  };

  const handleLessonUpdated = (updatedLesson) => {
    setLessons(lessons.map(lesson => lesson.id === updatedLesson.id ? updatedLesson : lesson));
    setEditingLesson(null);
  };

  const handlePricePlanAdded = (pricePlan) => {
    setPricePlans([...pricePlans, pricePlan]);
    setShowPricePlanForm(false);
  };

  const handleEditPricePlan = (pricePlan) => {
    setEditingPricePlan(pricePlan);
  };

  const handlePricePlanUpdated = (updatedPricePlan) => {
    setPricePlans(pricePlans.map(plan => plan.id === updatedPricePlan.id ? updatedPricePlan : plan));
    setEditingPricePlan(null);
  };

  return (
    <div className="course-details">
      {course && (
        <>
          <h1>{course.title}</h1>

          <p dangerouslySetInnerHTML={{ __html: course.description }} className="course_details_text_container mt-4"></p>

          <button onClick={() => setShowLessonForm(!showLessonForm)}>
            {showLessonForm ? 'Спрятать форму урока' : 'Добавить урок'}
          </button>

          {showLessonForm && <LessonForm courseId={id} onLessonAdded={handleLessonAdded} />}

          <button onClick={() => setShowPricePlanForm(!showPricePlanForm)}>
            {showPricePlanForm ? 'Спрятать форму тарифов' : 'Добавить тариф'}
          </button>

          {showPricePlanForm && <PricePlanForm courseId={id} onPricePlanAdded={handlePricePlanAdded} />}

          <div className="hidden_content_outer_container">
            <h2 className="text-left">Уроки</h2>

            <span className="subtext_description">
              Список уроков
            </span>

            <div>
              <div className="dashboard_results_container">
                <div>
                  {lessons.map((lesson) => (
                    <div key={lesson.id} className="dashboard_results_item no_bg">
                      <div>
                        <Link to={`/courses/${id}/lessons/${lesson.id}`}>{lesson.title}</Link>
                      </div>
                      
                      <div className="mt-3">
                        <p dangerouslySetInnerHTML={{ __html: lesson.description }}></p>
                      </div>

                      <div className="">
                        <button onClick={() => handleEditLesson(lesson)}>Edit</button>
                          {editingLesson && editingLesson.id === lesson.id && (
                            <LessonEditForm
                              lesson={editingLesson}
                              courseId={id}
                              onLessonUpdated={(updatedLesson) => {
                                setLessons(lessons.map((l) => (l.id === updatedLesson.id ? updatedLesson : l)));
                                setEditingLesson(null);
                              }}
                            />
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden_content_outer_container">
            <h2 className="text-left">Тарифы</h2>

            <span className="subtext_description">
              Список тарифов
            </span>

            <div>
              <div className="dashboard_results_container">
                <div>
                  {pricePlans.map((pricePlan) => (
                    <div key={pricePlan.id} className="dashboard_results_item no_bg">
                      <h3>{pricePlan.title}</h3>
                      
                      <div className="mt-3">
                        {pricePlan.description}
                      </div>     

                      <div className="mt-3">
                        {pricePlan.price}
                      </div>    

                      <div className="mt-3">
                        {pricePlan.image_url && <img src={pricePlan.image_url} alt={pricePlan.title} />}
                      </div>

                      <div className="">
                        <button onClick={() => handleEditPricePlan(pricePlan)}>Edit</button>
                        {editingPricePlan && editingPricePlan.id === pricePlan.id && (
                          <PricePlanEditForm
                            pricePlan={editingPricePlan}
                            courseId={id}
                            onPricePlanUpdated={(updatedPricePlan) => {
                              setPricePlans(pricePlans.map((p) => (p.id === updatedPricePlan.id ? updatedPricePlan : p)));
                              setEditingPricePlan(null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CourseDetails;
