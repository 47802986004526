import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCourses, deleteCourse } from '../api';
import { useAuth } from '../AuthContext';
import CourseForm from './CourseForm';
import '../css/style.scss';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const coursesData = await getCourses(token);
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    loadCourses();
  }, []);

  const handleSaveCourse = (savedCourse) => {
    if (selectedCourse) {
      setCourses(courses.map((course) => (course.id === savedCourse.id ? savedCourse : course)));
    } else {
      setCourses([...courses, savedCourse]);
    }
    setSelectedCourse(null);
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await deleteCourse(courseId, token);
      setCourses(courses.filter((course) => course.id !== courseId));
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <div className="course-details">
      <div className="hidden_content_outer_container">
        <h2 className="text-left">Курсы</h2>

        <span className="subtext_description">
          Список курсов
        </span>

        <div>
          <div className="dashboard_results_container">
            <div>
              {courses.map((course) => (
                <div key={course.id} className="dashboard_results_item no_bg course_item">
                  <Link to={`/courses/${course.id}`}>{course.title}</Link>

                  <div className="d-flex">
                    <button onClick={() => setSelectedCourse(course)}>Edit</button>
                    <button className="ml-3" onClick={() => handleDeleteCourse(course.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <CourseForm course={selectedCourse} onSave={handleSaveCourse} />
    </div>
  );
};

export default CourseList;
