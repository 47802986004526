import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const QuestDetails = () => {
  const { courseId, lessonId, questId } = useParams();
  const [quest, setQuest] = useState({});
  const [answers, setAnswers] = useState([]);
  const [feedbacks, setFeedbacks] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const [feedback, setFeedback] = useState('');
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [showScreenshot, setShowScreenshot] = useState({});
  const [userPlans, setUserPlans] = useState({});

  const token = localStorage.getItem('token');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchQuest();
    fetchAnswers();
  }, [questId]);

  const fetchQuest = async () => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuest(response.data);
    } catch (error) {
      console.error('Error fetching quest:', error);
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}/answers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnswers(response.data);
      response.data.forEach(answer => {
        fetchUserPlanForAnswer(answer.id);
        fetchFeedbackForAnswer(answer.id); // Добавляем вызов для получения фидбэка
      });
    } catch (error) {
      console.error('Error fetching answers:', error);
    }
  };

  const fetchUserPlanForAnswer = async (answerId) => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}/answers/${answerId}/user_plan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserPlans(prevPlans => ({
        ...prevPlans,
        [answerId]: response.data,
      }));
    } catch (error) {
      console.error('Error fetching user plan for answer:', error);
    }
  };

  const fetchFeedbackForAnswer = async (answerId) => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}/answers/${answerId}/feedbacks/${answerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFeedbacks((prev) => ({
        ...prev,
        [answerId]: response.data,
      }));
    } catch (error) {
      console.error('Error fetching feedback for answer:', error);
    }
  };

  const handleFeedbackSubmit = async (answerId) => {
    try {
      const response = await axios.post(
        `${API_URL}/quests/${questId}/answers/${answerId}/feedbacks/create_or_update`,
        { feedback: { description: feedback } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFeedback('');
      setSelectedAnswerId(null);
      setIsEditing((prev) => ({ ...prev, [answerId]: false }));

      // Обновление состояния feedbacks
      setFeedbacks((prev) => ({
        ...prev,
        [answerId]: response.data,
      }));
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleEditFeedback = (answerId) => {
    setSelectedAnswerId(answerId);
    setFeedback(feedbacks[answerId]?.description || '');
    setIsEditing((prev) => ({ ...prev, [answerId]: true }));
  };

  const toggleScreenshot = (answerId) => {
    setShowScreenshot((prev) => ({ ...prev, [answerId]: !prev[answerId] }));
  };

  const acceptAnswer = async (answerId) => {
    const formData = new FormData();

    try {
      const pointsMultiplier = userPlans[answerId]?.price_plan_id === 4 ? 2 : 1; // Если тариф "АЛИСА", удваиваем очки
      formData.append('points_multiplier', pointsMultiplier);

      const response = await axios.patch(
        `${API_URL}/quests/${questId}/answers/${answerId}/accept`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setAnswers((prev) =>
        prev.map((answer) =>
          answer.id === answerId ? { ...answer, accepted: true } : answer
        )
      );
    } catch (error) {
      console.error('Error accepting answer:', error);
    }
  };

  return (
    <div className="course-details">
      <h2>{quest.title}</h2>
      <div className="course_details_text_container mt-4">
        <p dangerouslySetInnerHTML={{ __html: quest.description }} className="course_details_description"></p>

        <div className="mt-3">Код: {quest.code}</div>
        <div className="mt-3">Очки: {quest.points}</div>
        <div className="mt-3">Порядковый номер: {quest.order_number}</div>
        <div className="mt-3">Ответ текстом: {quest.has_text ? 'Да' : 'Нет'}</div>
        <div className="mt-3">Ответ скриншотом: {quest.has_screenshot ? 'Да' : 'Нет'}</div>
        <div className="mt-3">Нужно подтверждение: {quest.needs_approval ? 'Да' : 'Нет'}</div>
        <div className="mt-3">Активное: {quest.active ? 'Да' : 'Нет'}</div>

        <h3 className="mt-3">Ответы</h3>

        <div className="asnwers_items_container">
          {answers.map((answer) => (
            <div key={answer.id} className="asnwers_item">
              <div className="asnwers_item_top_heading_container">
                <div>
                  <h4 className="mb-0">{answer.user ? answer.user.full_name : 'Anonymous'}</h4>
                  {answer.user ? answer.user.email : 'Anonymous'}
                </div>
                
                {quest.needs_approval && (
                  answer.accepted ? (
                    <div className="asnwers_item_accepted">Ответ принят</div>
                  ) : (
                    <button onClick={() => acceptAnswer(answer.id)}>Принять ответ</button>
                  )
                )}
              </div>

              <p className="mt-3">Тарифный план: {userPlans[answer.id]?.price_plan_name || 'Неизвестно'}</p>

              <p>{answer.code}</p>

              <p dangerouslySetInnerHTML={{ __html: answer.description ? answer.description.replace(/\r\n/g, '<br/>') : '' }}></p>

              {answer.file && (
                <div>
                  <div>
                    <span className="answer_item_screenshot_link" onClick={() => toggleScreenshot(answer.id)}>
                      {showScreenshot[answer.id] ? 'Спрятать скриншот' : 'Показать скриншот'}
                    </span>
                  </div>
                  {showScreenshot[answer.id] && <img src={answer.file} alt="" />}
                </div>
              )}

              {feedbacks[answer.id] ? (
                isEditing[answer.id] ? (
                  <div className="education_lesson_quests_form_container mt-4">
                    <div className="education_lesson_quests_form_heading">
                      Обратная связь
                    </div>

                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />

                    <button onClick={() => handleFeedbackSubmit(answer.id)}>Сохранить</button>
                  </div>
                ) : (
                  <div className="education_lesson_quests_form_container mt-4">
                    <div className="education_lesson_quests_form_heading">
                      Обратная связь
                    </div>

                    <p dangerouslySetInnerHTML={{ __html: feedbacks[answer.id].description ? feedbacks[answer.id].description.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '' }} className="education_lesson_quests_item_feedback_text"></p>

                    <button onClick={() => handleEditFeedback(answer.id)}>Редактировать ответ</button>
                  </div>
                )
              ) : (
                quest.has_text && (userPlans[answer.id]?.price_plan_id === 3 || userPlans[answer.id]?.price_plan_id === 4) && (
                  <div className="education_lesson_quests_form_container mt-4">
                    <div className="education_lesson_quests_form_heading">
                      Обратная связь
                    </div>

                    <textarea
                      value={selectedAnswerId === answer.id ? feedback : ''}
                      onChange={(e) => {
                        setFeedback(e.target.value);
                        setSelectedAnswerId(answer.id);
                      }}
                    />
                    <button onClick={() => handleFeedbackSubmit(answer.id)}>Отправить</button>
                  </div>
                )
              )}
            </div>
          ))}
        </div>

        <hr />

        <Link to={`/courses/${courseId}/lessons/${lessonId}/quests/${questId}/edit`} className="course_edit_link">Редактировать задание</Link>
      </div>
    </div>
  );
};

export default QuestDetails;
