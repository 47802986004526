import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ArticleDetail from './ArticleDetail';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('http://localhost:3000/articles');
        setArticles(response.data);
      } catch (error) {
        console.error('Ошибка при получении статей:', error);
      }
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  return (
    <div>
      <h2>Articles</h2>
      <ul>
        {articles.map((article) => (
          <li key={article.id} onClick={() => handleArticleClick(article)}>
            {article.title}
          </li>
        ))}
      </ul>
      {selectedArticle && <ArticleDetail article={selectedArticle} />}
    </div>
  );
};

export default ArticleList;
