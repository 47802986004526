import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import '../css/style.scss';

function ContactForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [question, setQuestion] = useState('');
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/questions`, {
        question_text: question,
        user_email: user.email,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setMessage(t('question_message'));
      setQuestion('');
    } catch (error) {
      setMessage(t('question_error'));
    }
  };

  return (
    <div className="contact-form">
      <h1>{t('ask_question')}</h1>
      <p>{t('question_subheading')} {user.email}</p>
      <form>
        <div>
          <label htmlFor="question">Ваш вопрос:</label>

          <textarea
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
        </div>
        
        <button type="button" onClick={handleSubmit}>{t('send')}</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default ContactForm;
