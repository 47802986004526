import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../css/style.scss';

function PaymentSuccess() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      axios.post(`${API_URL}/verify-checkout`, { session_id: sessionId })
        .then(response => {
          // Обрабатываем успешное подтверждение подписки
          // console.log('Subscription confirmed:', response.data);
        })
        .catch(error => {
          // Обрабатываем ошибку подтверждения
          // console.error('Error confirming subscription:', error);
        });
    }
  }, [sessionId, navigate]);

  return (
    <div className="payment-success-container">
      <h1>{t('thank_you_for_your_purchase')}</h1>
      <p>{t('your_subscription_has_been_activated')}</p>
      <Link to="/projects" className="go-to-projects-btn">{t('start_generating_projects')}</Link>
    </div>
  );
}

export default PaymentSuccess;
