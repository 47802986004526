import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const EditQuest = () => {
  const { courseId, lessonId, questId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [points, setPoints] = useState('');
  const [active, setActive] = useState(true);
  const [orderNumber, setOrderNumber] = useState(0);
  const [hasText, setHasText] = useState(false);
  const [hasScreenshot, setHasScreenshot] = useState(false);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const quillRef = useRef(null);

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchQuest();
  }, [questId]);

  const fetchQuest = async () => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { title, description, code, active, order_number, points, has_text, has_screenshot, needs_approval } = response.data;
      setTitle(title);
      setDescription(description);
      setCode(code || '');
      setPoints(points || '');
      setActive(active);
      setOrderNumber(order_number || 0);
      setHasText(has_text);
      setHasScreenshot(has_screenshot);
      setNeedsApproval(needs_approval);
    } catch (error) {
      console.error('Error fetching quest:', error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleImageUpload = async (file) => {
    setLoading(true);

    const params = {
      Bucket: 'gnot-me-public',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const imageUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      setLoading(false);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
      return null;
    }
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const imageUrl = await handleImageUpload(file);
        if (imageUrl) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', imageUrl);
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler,
      }
    }
  }), []);

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleEditQuest = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/quests/${questId}`, {
        title,
        description,
        code,
        points,
        active,
        order_number: orderNumber,
        has_text: hasText,
        has_screenshot: hasScreenshot,
        needs_approval: needsApproval,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error editing quest:', error);
    }
  };

  return (
    <div className="course-details">
      <h2>Edit Quest</h2>

      <div className="dashboard_form_container">
        <form className="project_form" onSubmit={handleEditQuest}>
          <label htmlFor="project-name">Название</label>
          <input
            type="text"
            id="quest-title"
            value={title}
            onChange={handleTitleChange}
            required
          />

          <label htmlFor="project-name">Описание</label>
          <ReactQuill
            ref={quillRef}
            value={description}
            onChange={handleDescriptionChange}
            modules={modules}
            formats={formats}
          />

          <label htmlFor="quest-code">Код</label>
          <input
            type="text"
            id="quest-code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <label htmlFor="quest-points">Очки</label>
          <input
            type="text"
            id="quest-points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />

          <label htmlFor="quest-order-number">Порядковый номер</label>
          <input
            type="number"
            id="quest-order-number"
            value={orderNumber}
            onChange={(e) => setOrderNumber(Number(e.target.value))}
          />

          <label className="dashboard_form_checkbox_container">
            Активное?
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Ответ текстом?
            <input
              type="checkbox"
              id="quest-has-text"
              checked={hasText}
              onChange={(e) => setHasText(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Ответ скрином?
            <input
              type="checkbox"
              id="quest-has-screenshot"
              checked={hasScreenshot}
              onChange={(e) => setHasScreenshot(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Нужно подтверждение?
            <input
              type="checkbox"
              id="quest-needs-approval"
              checked={needsApproval}
              onChange={(e) => setNeedsApproval(e.target.checked)}
            />
          </label>

          <button type="submit" disabled={loading}>Сохранить</button>
        </form>
      </div>
    </div>
  );
};

export default EditQuest;
