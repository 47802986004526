import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import TextToggle from './TextToggle';
import '../css/style.scss';

function Reel() {
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [postId, setPostId] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [postContent, setPostContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const { selectedProjectId, stateActiveTab: stateActiveTab } = location.state || {};

  const [reel, setReel] = useState({
    id: '',
    title: '',
    content: ''
  });

  const token = localStorage.getItem('token');

  useEffect(() => {
    const reelId = location.state?.reelId;

    if (reelId) {
      fetchPost(reelId);
    } else {
      navigate('/projects');
    }
  }, [location.state, navigate]); 

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = reel.content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied'));
  };

  const fetchPost = async (reelId) => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_URL}/reels/${reelId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const reel = response.data[0];

      setReel({
        id: reelId,
        title: response.data.title || '',
        content: cleanText(response.data.content || '')
      });
    } catch (error) {
      if (error.response?.status === 401) {
        logout();
        navigate('/login');
      } else {
        console.error('Error fetching reel:', error);
        setErrorMessage(t('scenario_fetch_error'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate('/projects', { state: { selectedProjectId, stateActiveTab: stateActiveTab } })}>{t('back')}</span>

      <h2><TextToggle text={reel.title} /></h2>

      {reel.content && <div className="post-preview">{reel.content}</div>}

      {reel.content && (
        <button onClick={handleCopyText}>{t('copy_text')}</button>
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default Reel;