import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../css/style.scss';

function ForgotPassword() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Сбрасываем состояние submitted при изменении email
    setSubmitted(false);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading || submitted) return;

    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setErrors({ email: '' });

    // Проверка на заполненность полей
    const newErrors = { email: '' };
    if (!email) newErrors.email = t('email_required');
    
    setErrors(newErrors);

    // Останавливаем отправку формы, если есть ошибки
    if (newErrors.email) {
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${API_URL}/password/forgot`, { email });
      setSuccessMessage(t('check_your_email_for_instructions'));
      setSubmitted(true);  // Устанавливаем флаг submitted после успешной отправки
    } catch (error) {
      setErrorMessage(t('email_not_found'));
    }

    setLoading(false);
  };

  return (
    <div className="signup-container">
      <h2>{t('password_recovery')}</h2>
      <p className="mt-3">{t('enter_email_to_continue')}</p>

      <form onSubmit={handleSubmit} className="signup-form">
        {errorMessage && <div className="error_message_container">{errorMessage}</div>}
        {successMessage && <div className="error_message_container success">{successMessage}</div>}

        <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            className={errors.email ? 'error-input' : ''}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </div>

        <button type="submit" className="login-button" disabled={loading || submitted}>
          {submitted ? t('sent') : t('send')}
        </button>
      </form>

      <p className="login-link">
        {t('remember_password')} <Link to="/login" className="signup_form_link">{t('log_in_here')}</Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
