import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Импортируйте useAuth для доступа к состоянию пользователя

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    // Если пользователь не залогинен, перенаправляем на страницу входа
    return <Navigate to="/login" />;
  }

  return children; // Если пользователь залогинен, отображаем запрашиваемый компонент
};

export default ProtectedRoute;
