import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../css/style.scss';

function Login() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Для отображения ошибок аутентификации
  const [errors, setErrors] = useState({ email: '', password: '' }); // Для отображения ошибок валидации
  const navigate = useNavigate(); // Для перенаправления после успешного входа
  const { login } = useAuth();  // Получаем функцию login из контекста
  const { t, i18n } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    setErrorMessage('');
    setErrors({ email: '', password: '' });

    // Проверка на заполненность полей
    const newErrors = { email: '', password: '' };
    if (!email) newErrors.email = t('email_required');
    if (!password) newErrors.password = t('password_required');
    
    setErrors(newErrors);

    // Останавливаем отправку формы, если есть ошибки
    if (newErrors.email || newErrors.password) {
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/login`, {
        user: {
          email,
          password
        }
      });

      if (response.data.token) {
        // Сохраняем токен в localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Сохраняем данные пользователя
        // Обновляем контекст пользователя
        login(response.data.user, response.data.token); // Предполагаем, что login обновляет состояние пользователя в контексте
        // Перенаправляем пользователя на страницу профиля или дашборда
        navigate('/projects'); // Обновите это согласно вашему маршруту профиля
      }
    } catch (error) {
      if (error.response) {
        const { error: serverError } = error.response.data;
        if (serverError === 'User not found') {
          setErrorMessage(t('user_not_found'));
        } else if (serverError === 'Invalid credentials') {
          setErrorMessage(t('invalid_credentials'));
        } else {
          setErrorMessage(t('login_error'));
        }
      } else {
        // Ошибка установки ответа, или ответ не был получен вообще
        setErrorMessage(t('login_error'));
      }
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="signup-container">
      <h2>{t('login_heading')}</h2>

      <p className="mt-3">{t('login_subheading')}</p>

      <form onSubmit={handleLogin} className="signup-form">
        {errorMessage && <div className="error_message_container">{errorMessage}</div>}

        <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            className={errors.email ? 'error-input' : ''}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </div>

        <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('password')}
            className={errors.password ? 'password_input error-input' : 'password_input'}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className="password-toggle-icon"
          />
          {errors.password && <div className="error-message">{errors.password}</div>}
        </div>

        <button type="submit" className="login-button">{t('login')}</button>
      </form>

      <p className="login-link">
        {t('dont_have_account')} <Link to="/signup" className="signup_form_link">{t('please_register')}</Link>
      </p>

      <p className="login-link">
        {t('forgot_password')} <Link to="/forgot-password" className="signup_form_link">{t('recover')}</Link>
      </p>
    </div>
  );
}

export default Login;
