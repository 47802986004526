// api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getCourses = async (token) => {
  const response = await axios.get(
    `${API_URL}/courses/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getCourse = async (courseId, token) => {
  const response = await axios.get(
    `${API_URL}/courses/${courseId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const createCourse = async (courseData, token) => {
  const response = await axios.post(
    `${API_URL}/courses`,
    courseData,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Передаем токен в заголовок
      },
    }
  );
  return response.data;
};

export const updateCourse = async (courseId, courseData, token) => {
  const response = await axios.put(
    `${API_URL}/courses/${courseId}`,
    courseData,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Передаем токен в заголовок
      },
    }
  );
  return response.data;
};

export const deleteCourse = async (courseId, token) => {
  const response = await axios.delete(
    `${API_URL}/courses/${courseId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Передаем токен в заголовок
      },
    }
  );
  return response.data;
};

export const getPricePlans = async (courseId) => {
  const response = await axios.get(`${API_URL}/courses/${courseId}/price_plans`);
  return response.data;
};

export const createPricePlan = async (courseId, pricePlan) => {
  const response = await axios.post(`${API_URL}/courses/${courseId}/price_plans`, pricePlan);
  return response.data;
};

export const updatePricePlan = async (courseId, pricePlanId, pricePlan) => {
  const response = await axios.put(`${API_URL}/courses/${courseId}/price_plans/${pricePlanId}`, pricePlan);
  return response.data;
};

export const deletePricePlan = async (courseId, pricePlanId) => {
  const response = await axios.delete(`${API_URL}/courses/${courseId}/price_plans/${pricePlanId}`);
  return response.data;
};

export const getLessons = async (courseId) => {
  const response = await axios.get(`${API_URL}/courses/${courseId}/lessons`);
  return response.data;
};

export const createLesson = async (courseId, lesson) => {
  const response = await axios.post(`${API_URL}/courses/${courseId}/lessons`, lesson);
  return response.data;
};

export const updateLesson = async (courseId, lessonId, lesson) => {
  const response = await axios.put(`${API_URL}/courses/${courseId}/lessons/${lessonId}`, lesson);
  return response.data;
};

export const deleteLesson = async (courseId, lessonId) => {
  const response = await axios.delete(`${API_URL}/courses/${courseId}/lessons/${lessonId}`);
  return response.data;
};

export const getQuests = async (courseId, lessonId) => {
  const response = await axios.get(`${API_URL}/courses/${courseId}/lessons/${lessonId}/quests`);
  return response.data;
};

export const createQuest = async (courseId, lessonId, quest) => {
  const response = await axios.post(`${API_URL}/courses/${courseId}/lessons/${lessonId}/quests`, quest);
  return response.data;
};

export const updateQuest = async (courseId, lessonId, questId, quest) => {
  const response = await axios.put(`${API_URL}/courses/${courseId}/lessons/${lessonId}/quests/${questId}`, quest);
  return response.data;
};

export const deleteQuest = async (courseId, lessonId, questId) => {
  const response = await axios.delete(`${API_URL}/courses/${courseId}/lessons/${lessonId}/quests/${questId}`);
  return response.data;
};
