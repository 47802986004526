import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../css/style.scss';

function AdminQuestions() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState('');
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/questions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setQuestions(response.data);
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
          navigate('/login');
        } else {
          console.error('Ошибка при получении списка вопросов:', error);
          setError(t('questions_fetch_error'));
        }
      }
    };

    if (token) {
      fetchQuestions();
    } else {
      logout();
      navigate('/login');
    }
  }, [token]);

  const markAsAnswered = async (questionId) => {
    try {
      await axios.patch(`${API_URL}/questions/${questionId}/answer`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setQuestions(prevQuestions =>
        prevQuestions.map(q =>
          q.id === questionId ? { ...q, answered: true } : q
        )
      );
    } catch (error) {
      console.error('Ошибка при обновлении вопроса:', error);
      setError(t('question_update_error'));
    }
  };

  return (
    <div className="container">
      <h1 className="mt-4 mb-0">Список вопросов ({questions.length})</h1>
      {error && <div>{error}</div>}
      <div className="table-responsive">
        <table className="table table-dark table-striped user-table mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Email Пользователя</th>
              <th>Вопрос</th>
              <th>Дата создания</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {questions.map((question, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{question.user_email}</td>
                <td>{question.question_text}</td>
                <td>{new Date(question.created_at).toLocaleString()}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => markAsAnswered(question.id)}
                    disabled={question.answered}
                  >
                    {question.answered ? 'Answered' : 'Mark as Answered'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminQuestions;
