import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TextToggle = ({ text, maxLength = 150 }) => {
  const { t } = useTranslation();
  
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength) + (text.length > maxLength ? '... ' : '');

  return (
    <div>
      <p className="mb-0">{displayText}</p>
      {text.length > maxLength && (
        <span className="show_more_link" onClick={toggleText}>
          {isExpanded ? t('hide') : t('show_full')}
        </span>
      )}
    </div>
  );
};

export default TextToggle;
