import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const activeLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('lang', lng);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <div className="language-switcher">
      <button className={activeLanguage === 'en' ? 'active' : ''} onClick={() => changeLanguage('en')}>EN</button>
      <button className={activeLanguage === 'it' ? 'active' : ''} onClick={() => changeLanguage('it')}>IT</button>
      <button className={activeLanguage === 'es' ? 'active' : ''} onClick={() => changeLanguage('es')}>ES</button>
      <button className={activeLanguage === 'lv' ? 'active' : ''} onClick={() => changeLanguage('lv')}>LV</button>
      <button className={activeLanguage === 'ru' ? 'active' : ''} onClick={() => changeLanguage('ru')}>RU</button>
    </div>
  );
}

export default LanguageSwitcher;
