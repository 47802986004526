import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

const CoursePage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [user, setUser] = useState(null);
  const [hasAccess, setHasAccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user: authUser, logout } = useAuth();

  const { t } = useTranslation();

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${API_URL}/education/courses/${courseId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCourse(response.data);
      } catch (error) {
        console.error('Error fetching course:', error);
      }
    };

    const checkUserAccess = async () => {
      try {
        const response = await axios.get(`${API_URL}/users/check_paid_plan`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setHasAccess(response.data.access);
      } catch (error) {
        console.error('Error checking user access:', error);
        setHasAccess(false);
      } finally {
        setLoading(false);
      }
    };

    const checkUserData = async () => {
      try {
        const responseUser = await axios.get(`${API_URL}/users/me/course/${courseId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(responseUser.data.user);
      } catch (error) {
        console.error('Error checking user access:', error);
        setHasAccess(false);
      } finally {
        setLoading(false);
      }
    };

    if (authUser) {
      console.log("AUTH USER");
      console.log(authUser);

      checkUserAccess();
      fetchCourse();
      checkUserData();
    } else {
      navigate('/login');
    }
  }, [authUser, token, courseId]);

  if (!course) return <div>Loading...</div>;

  if (!course) return <div>Course not found</div>;

  return (
    <div className="education_lesson_container">
      <div className="user_points_container">
        <img className="user_points_img left" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/coin.webp" />

        <div className="user_points_heading">
          {t('you_earned')}
          <span className="user_points_number">
            {user ? user.points : 'Loading...'} 
          </span>

          {t('brain_points')}
        </div>

        <img className="user_points_img right" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/coin.webp" />
      </div>

      <div className="education_lesson_inner">
        <div className="education_lesson_content_container">
          <div className="education_lesson_img_container">
            <img className="education_lesson_img" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/a-futuristic-abstract-concept-of-a-tunnel-that-lea-0u3XUFjHRn2wwlhOlF5pLQ-LgfsJ-oKSzylZPL8Dq6zsw.webp" />
          </div>

          <h1 className="education_lesson_name mt-4">{course.title}</h1>

          {hasAccess ? (
            <>
              <h2 className="education_lesson_quests_heading">{t('lessons')}</h2>
              <div className="education_lesson_quests_container">
                {course.lessons.filter(lesson => lesson.active).map(lesson => (
                  <div key={lesson.id} className="education_lesson_quests_item">
                    <Link to={`/education/courses/${courseId}/lessons/${lesson.id}`} className="d-block education_lesson_quests_item_heading with_link">
                      {lesson.title}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>
              <p dangerouslySetInnerHTML={{ __html: course.description }} className="education_lesson_desc"></p>

              <div className="education_lesson_no_access">
                <p>{t('to_access_education')}</p>
                <Link to={`/billing`}>{t('continue_to_payment')}</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
