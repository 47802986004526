import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; 

const Footer = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const isLandingPage = location.pathname === '/';

  return (
    <div className={`footer_section ${!isLandingPage ? 'mt-5' : ''}`}>
      <div className="footer_bottom_container">
        {t('created_by')}

        <a className="footer_created_link" href="https://braingency.eu/">br<span className="footer_ai_text">ai</span>ngency</a>
      </div>
    </div>
  );
};

export default Footer;
