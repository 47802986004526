import React from 'react';

const ArticleDetail = ({ article }) => {
  return (
    <div>
      <h3>{article.title}</h3>
      <p><strong>Short Description:</strong> {article.short_description}</p>
      <p><strong>Long Description:</strong> {article.long_description}</p>
      <p><strong>Status:</strong> {article.status}</p>
      <p><strong>Published At:</strong> {article.published_at}</p>
      <p><strong>Category:</strong> {article.category.name}</p>
      <p><strong>Author:</strong> {article.author.name}</p>
    </div>
  );
};

export default ArticleDetail;
