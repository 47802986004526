import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const LessonDetails = () => {
  const { courseId, lessonId } = useParams();
  const [lesson, setLesson] = useState({});
  const [quests, setQuests] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [points, setPoints] = useState('');
  const [active, setActive] = useState(true);
  const [orderNumber, setOrderNumber] = useState(0);
  const [hasText, setHasText] = useState(false);
  const [hasScreenshot, setHasScreenshot] = useState(false);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const quillRef = useRef();

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, [quillRef]);

  useEffect(() => {
    fetchLesson();
    fetchQuests();
  }, [lessonId]);

  const fetchLesson = async () => {
    try {
      const response = await axios.get(`${API_URL}/courses/${courseId}/lessons/${lessonId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLesson(response.data);
    } catch (error) {
      console.error('Error fetching lesson:', error);
    }
  };

  const fetchQuests = async () => {
    try {
      const response = await axios.get(`${API_URL}/lessons/${lessonId}/quests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedQuests = response.data.sort((a, b) => a.order_number - b.order_number);
      setQuests(sortedQuests);
    } catch (error) {
      console.error('Error fetching quests:', error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleImageUpload = async (file) => {
    setLoading(true);

    const params = {
      Bucket: 'gnot-me-public',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const imageUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      setLoading(false);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
      return null;
    }
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const imageUrl = await handleImageUpload(file);
        if (imageUrl) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', imageUrl);
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler,
      }
    }
  }), []);

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleAddQuest = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/lessons/${lessonId}/quests`, {
        title,
        description,
        code,
        points,
        active,
        order_number: orderNumber,
        has_text: hasText,
        has_screenshot: hasScreenshot,
        needs_approval: needsApproval,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedQuests = [...quests, response.data];
      setQuests(updatedQuests.sort((a, b) => a.order_number - b.order_number));
      setTitle('');
      setDescription('');
      setCode('');
      setPoints('');
      setActive(true);
      setOrderNumber(0);
      setHasText(false);
      setHasScreenshot(false);
      setNeedsApproval(false);
    } catch (error) {
      console.error('Error adding quest:', error);
    }
  };

  return (
    <div className="course-details">
      <h1>{lesson.title}</h1>
      <p dangerouslySetInnerHTML={{ __html: lesson.description }} className="mt-4"></p>

      <div className="hidden_content_outer_container">
        <h2 className="text-left">Задания</h2>

        <span className="subtext_description">
          Список заданий
        </span>

        <div className="mt-3">
          {quests.map((quest) => (
            <div key={quest.id} className="dashboard_results_item no_bg">
              <div>
                <Link to={`/courses/${courseId}/lessons/${lessonId}/quests/${quest.id}`}>{quest.title}</Link>
              </div>
              
              <div className="mt-3">
                <p dangerouslySetInnerHTML={{ __html: quest.description }}></p>
              </div>

              <div className="mt-3">
                Код: {quest.code}
              </div>

              <div className="mt-3">
                Очки: {quest.points}
              </div>

              <div className="mt-3">
                Порядковый номер: {quest.order_number}
              </div>

              <div className="mt-3">
                Ответ текстом: {quest.has_text ? 'Да' : 'Нет'}
              </div>

              <div className="mt-3">
                Ответ скриншотом: {quest.has_screenshot ? 'Да' : 'Нет'}
              </div>   

              <div className="mt-3">
                Нужно подтверждение: {quest.needs_approval ? 'Да' : 'Нет'}
              </div>

              <div className="mt-3">
                Активное: {quest.active ? 'Да' : 'Нет'}
              </div>

              <div className="mt-3">
                <Link to={`/courses/${courseId}/lessons/${lessonId}/quests/${quest.id}/edit`} className="course_edit_link">Редактировать</Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <h2 className="text-center mt-5">Добавить задание</h2>

      <div className="dashboard_form_container">
        <form className="project_form" onSubmit={handleAddQuest}>
          <label htmlFor="project-name">Название</label>
          <input
            type="text"
            id="quest-title"
            value={title}
            onChange={handleTitleChange}
            required
          />

          <label htmlFor="project-name">Описание</label>
          <ReactQuill
            ref={quillRef}
            value={description}
            onChange={handleDescriptionChange}
            modules={modules}
            formats={formats}
          />

          <label htmlFor="quest-code">Код</label>
          <input
            type="text"
            id="quest-code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <label htmlFor="quest-points">Очки</label>
          <input
            type="text"
            id="quest-points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />

          <label htmlFor="quest-order-number">Порядковый номер</label>
          <input
            type="number"
            id="quest-order-number"
            value={orderNumber}
            onChange={(e) => setOrderNumber(Number(e.target.value))}
          />

          <label className="dashboard_form_checkbox_container">
            Активное?
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Ответ текстом?
            <input
              type="checkbox"
              id="quest-has-text"
              checked={hasText}
              onChange={(e) => setHasText(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Ответ скрином?
            <input
              type="checkbox"
              id="quest-has-screenshot"
              checked={hasScreenshot}
              onChange={(e) => setHasScreenshot(e.target.checked)}
            />
          </label>

          <label className="dashboard_form_checkbox_container">
            Нужно подтверждение?
            <input
              type="checkbox"
              id="quest-needs-approval"
              checked={needsApproval}
              onChange={(e) => setNeedsApproval(e.target.checked)}
            />
          </label>

          <button type="submit" disabled={loading}>Добавить задание</button>
        </form>
      </div>
    </div>
  );
};

export default LessonDetails;
