import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import '../css/style.scss';

function AdminUsers() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [usersWithoutProjects, setUsersWithoutProjects] = useState([]);
  const [usersWithPaidSubscription, setUsersWithPaidSubscription] = useState([]);
  const [error, setError] = useState('');
  const { user, token, logout } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSubUsersOpen, setIsSubUsersOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isWithoutProjectsUsersOpen, setIsWithoutProjectsUsersOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Получить токен из локального хранилища

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Передаем токен в заголовок
            },
          }
        );
        
        // console.log("USERS");
        // console.log(response.data.all_users);
        setUsers(response.data.all_users);
        setUsersWithoutProjects(response.data.users_without_projects);
        setUsersWithPaidSubscription(response.data.users_with_paid_subscription);
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
          navigate('/login');
        } else {
          console.error('Ошибка при получении списка пользователей:', error);
          setError(t('users_fetch_error'));
        }
      }
    };

    if (token) {
      fetchUsers();
    } else {
      logout();
      navigate('/login');
    }
  }, [token]); // Перепроверка при изменении токена

  const toggleSubUsersEmailList = () => {
    setIsSubUsersOpen(!isSubUsersOpen);
  };

  const toggleUsersEmailList = () => {
    setIsUsersOpen(!isUsersOpen);
  };

  const toggleWithoutProjectsEmailList = () => {
    setIsWithoutProjectsUsersOpen(!isWithoutProjectsUsersOpen);
  };

  const copySubUsersEmailsToClipboard = () => {
    const emailList = usersWithPaidSubscription.map(user => user.email).join(', ');
    navigator.clipboard.writeText(emailList)
      .then(() => {
        alert('Емаилы скопированы в буфер обмена!');
      })
      .catch(err => {
        console.error('Ошибка при копировании емаилов: ', err);
      });
  };

  const copyUsersEmailsToClipboard = () => {
    const emailList = users.map(user => user.email).join(', ');
    navigator.clipboard.writeText(emailList)
      .then(() => {
        alert('Емаилы скопированы в буфер обмена!');
      })
      .catch(err => {
        console.error('Ошибка при копировании емаилов: ', err);
      });
  };  

  const copyWithoutProjectsUsersEmailsToClipboard = () => {
    const emailList = usersWithoutProjects.map(user => user.email).join(', ');
    navigator.clipboard.writeText(emailList)
      .then(() => {
        alert('Емаилы скопированы в буфер обмена!');
      })
      .catch(err => {
        console.error('Ошибка при копировании емаилов: ', err);
      });
  };

  const fetchSubscriptionDetails = async (user_email) => {
    try {
      const response = await axios.get(`${API_URL}/subscription-details`, {
        params: { user_email: user_email },
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Failed to fetch subscription details:', error);
    } finally {
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="container">
        <h1 className="mt-4 mb-0">Пользователи с подпиской ({usersWithPaidSubscription.length})</h1>
        {error && <div>{error}</div>}

        <h5 className="mt-2" onClick={toggleSubUsersEmailList}>Емаилы</h5>

        {isSubUsersOpen && (
          <div>
            <button onClick={copySubUsersEmailsToClipboard} style={{ marginBottom: '10px' }}>
              Скопировать емаилы
            </button>

            <div>
              {usersWithPaidSubscription.map((user, index) => (
                <div key={index}>
                  {user.email}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-dark table-striped user-table mt-4"> 
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Имя</th>
                <th>Email</th>
                <th>Projects</th>
                <th>Posts</th>
                <th>Reels</th>
                <th>Stories</th>
                <th>Points</th>
                <th>Subscription</th>
                <th>Created at</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {usersWithPaidSubscription.map((user, index) => (
                <tr key={index} className={user.plan_name != 'free' ? 'paid_user' : ''}>
                  <td>{index + 1}</td>
                  <td>{user.id}</td>
                  <td>{user.full_name}</td>
                  <td>{user.email}</td>
                  <td>{user.projects_count}</td>
                  <td>{user.posts_count}</td>
                  <td>{user.reels_count}</td>
                  <td>{user.stories_count}</td>
                  <td>{user.points}</td>
                  <td>{user.plan_name}</td>
                  <td>{format(new Date(user.created_at), 'PPP')}</td>
                  <td><span className="check_sub_link" onClick={() => fetchSubscriptionDetails(user.email)}>Check Sub</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="container">
        <h1 className="mt-4 mb-0">Все пользователи ({users.length})</h1>
        {error && <div>{error}</div>}

        <h5 className="mt-2" onClick={toggleUsersEmailList}>Емаилы</h5>

        {isUsersOpen && (
          <div>
            <button onClick={copyUsersEmailsToClipboard} style={{ marginBottom: '10px' }}>
              Скопировать емаилы
            </button>

            <div>
              {users.map((user, index) => (
                <div key={index}>
                  {user.email}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-dark table-striped user-table mt-4"> 
            <thead>
              <tr>
                <th>#</th>
                <th>Имя</th>
                <th>Email</th>
                <th>Projects</th>
                <th>Posts</th>
                <th>Reels</th>
                <th>Stories</th>
                <th>Points</th>
                <th>Country</th>
                <th>Subscription</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {users.map((user, index) => (
                <tr key={index} className={user.plan_name != 'free' ? 'paid_user' : ''}>
                  <td>{index + 1}</td>
                  <td>{user.full_name}</td>
                  <td>{user.email}</td>
                  <td>{user.projects_count}</td>
                  <td>{user.posts_count}</td>
                  <td>{user.reels_count}</td>
                  <td>{user.stories_count}</td>
                  <td>{user.points}</td>
                  <td>{user.country}</td>
                  <td>{user.plan_name}</td>
                  <td>{format(new Date(user.created_at), 'PPP')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="container">
        <h1 className="mt-4 mb-0">Пользователи без проектов ({usersWithoutProjects.length})</h1>
        {error && <div>{error}</div>}

        <h5 className="mt-2" onClick={toggleWithoutProjectsEmailList}>Емаилы</h5>

        {isWithoutProjectsUsersOpen && (
          <div>
            <button onClick={copyWithoutProjectsUsersEmailsToClipboard} style={{ marginBottom: '10px' }}>
              Скопировать емаилы
            </button>

            <div>
              {usersWithoutProjects.map((user, index) => (
                <div key={index}>
                  {user.email}
                </div>
              ))}
            </div>
          </div>
        )}
        
        <div className="table-responsive">
          <table className="table table-dark table-striped user-table mt-4"> 
            <thead>
              <tr>
                <th>#</th>
                <th>Имя</th>
                <th>Email</th>
                <th>Projects</th>
                <th>Posts</th>
                <th>Reels</th>
                <th>Stories</th>
                <th>Subscription</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {usersWithoutProjects.map((user, index) => (
                <tr key={index} className={user.plan_name != 'free' ? 'paid_user' : ''}>
                  <td>{index + 1}</td>
                  <td>{user.full_name}</td>
                  <td>{user.email}</td>
                  <td>{user.projects_count}</td>
                  <td>{user.posts_count}</td>
                  <td>{user.reels_count}</td>
                  <td>{user.stories_count}</td>
                  <td>{user.plan_name}</td>
                  <td>{format(new Date(user.created_at), 'PPP')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
