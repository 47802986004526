import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../css/style.scss';

function ResetPassword() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const { token } = useParams();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage('');
    setSuccessMessage('');
    setErrors({ password: '', confirmPassword: '' });

    // Проверка на заполненность полей
    const newErrors = { password: '', confirmPassword: '' };
    if (!password) newErrors.password = t('enter_new_password');
    if (!confirmPassword) newErrors.confirmPassword = t('new_password_confirm');
    
    setErrors(newErrors);

    if (newErrors.password || newErrors.confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage(t('passwords_do_not_match'));
      return;
    }

    try {
      await axios.post(`${API_URL}/password/reset`, {
        password_token: token,
        password,
      });

      setSuccessMessage(t('password_changed_continue_to_login'));
    } catch (error) {
      setErrorMessage(t('link_is_inactive_generate_new'));
    }
  };

  return (
    <div className="signup-container">
      <h2>{t('password_recovery')}</h2>
      <p className="mt-3">{t('enter_new_password_to_continue')}</p>

      <form onSubmit={handleSubmit} className="signup-form">
        {errorMessage && <div className="error_message_container">{errorMessage}</div>}
        {successMessage && <div className="error_message_container success">{successMessage}</div>}

        {successMessage ? (
          <div className="mb-3"><Link to="/login" className="signup_form_link">{t('log_in_here')}</Link></div>
        ) : (
          <div>
            <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('new_password')}
                className={errors.password ? 'password_input error-input' : 'password_input'}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="password-toggle-icon"
              />
              {errors.password && <div className="error-message">{errors.password}</div>}
            </div>

            <div className={`form-group ${errors.confirmPassword ? 'has-error' : ''}`}>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t('new_password_confirm')}
                className={errors.confirmPassword ? 'password_input error-input' : 'password_input'}
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                onClick={toggleConfirmPasswordVisibility}
                className="password-toggle-icon"
              />
              {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
            </div>

            <button type="submit" className="login-button">{t('save')}</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
