import React, { useState, useRef, useMemo, useEffect } from 'react';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const LessonEditForm = ({ lesson, courseId, onLessonUpdated }) => {
  const [title, setTitle] = useState(lesson.title);
  const [description, setDescription] = useState(lesson.description);
  const [videoUrl, setVideoUrl] = useState(lesson.video_url);
  const [orderNumber, setOrderNumber] = useState(lesson.order_number || 0);
  const [active, setActive] = useState(lesson.active);
  const [loading, setLoading] = useState(false);
  const quillRef = useRef();

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, [quillRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${API_URL}/courses/${courseId}/lessons/${lesson.id}`,
        {
          title,
          description,
          video_url: videoUrl,
          order_number: orderNumber,
          active,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onLessonUpdated(response.data);
    } catch (error) {
    }
  };

  const handleImageUpload = async (file) => {
    const params = {
      Bucket: 'gnot-me-public',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      const imageUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      return imageUrl;
    } catch (error) {
      return null;
    }
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const imageUrl = await handleImageUpload(file);
        if (imageUrl) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          if (range) {
            quill.insertEmbed(range.index, 'image', imageUrl);
          }
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    }
  }), [])

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <form className="lesson_edit_form" onSubmit={handleSubmit}>
      <div>
        <label>Название</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>

      <div>
        <label>Описание</label>
        <ReactQuill
          ref={quillRef}
          value={description}
          onChange={handleDescriptionChange}
          modules={modules}
          formats={formats}
        />
      </div>

      <div>
        <label>Код видео</label>
        <textarea
          type="text"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="quest-order-number">Порядковый номер</label>
        <input
          type="number"
          id="quest-order-number"
          value={orderNumber}
          onChange={(e) => setOrderNumber(Number(e.target.value))}
        />
      </div>

      <div className="dashboard_form_checkbox_container">
        <label>Активный?</label>
        <input
          type="checkbox"
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
        />
      </div>

      <button type="submit" disabled={loading}>
        {loading ? 'Saving...' : 'Update Lesson'}
      </button>
    </form>
  );
};

export default LessonEditForm;
