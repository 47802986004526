import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const CategoryForm = ({ onCategoryCreated }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newCategory = {
      name,
      description,
    };

    try {
      const response = await axios.post(
        `${API_URL}/categories`,
        {
          newCategory
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      onCategoryCreated(response.data);
      setName('');
      setDescription('');
    } catch (error) {
      console.error('Ошибка при создании категории:', error);
    }
  };

  return (
    <div className="dashboard_form_container">
      <Link className="post_container_back_link with_bg" to={`/articles`}>{t('back')}</Link>

      <h1>Новая категория</h1>

      <form className="project_form mt-5" onSubmit={handleSubmit}>
        <div>
          <label>Название</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>Описание</label>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Сохраняем...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default CategoryForm;
