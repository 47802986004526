import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; 
import countries from 'i18n-iso-countries';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import '../css/tilda/tilda-cards-1.0.min.css';
import '../css/tilda/tilda-cover-1.0.min.css';
import '../css/tilda/tilda-grid-3.0.min.css';
import '../css/tilda/tilda-blocks-page52113789.min.css';

import headerBg from '../img/tild6362-3530-4039-b936-653735303031__mshehurina_abstract_.webp';
import logo from '../img/tild3364-3331-4533-a239-623137333739__noroot.webp';
import feature1 from '../img/tild3634-6665-4636-b938-363038646164__mshehurina_overcomin.webp';
import feature2 from '../img/tild3739-3862-4465-b930-393230656333__mshehurina_brain_edu.webp';
import feature3 from '../img/tild3233-6632-4634-a261-633938666534__mshehurina_overcomin.webp';
import feature4 from '../img/tild3762-3535-4231-b332-653561323365__mshehurina_people_ar.webp';
import questImg from '../img/tild3134-6337-4637-b261-346365613164__noroot.webp';
import step1Icon from '../img/lib__icons__tilda__app_application_mobile_code_tag.svg';
import step2Icon from '../img/lib__icons__tilda__brain_knowledge.svg';
import step3Icon from '../img/lib__icons__tilda__hat_graduate_student_degree_education.svg';
import step4Icon from '../img/lib__icons__tilda__analytics.svg';
import freePlanImg from '../img/tild6235-3962-4132-b466-396263326233__mshehurina_abstract_.webp';
import starterPlanImg from '../img/tild3639-3366-4930-a331-383734316163__mshehurina_brain_edu.webp';
import proPlanImg from '../img/tild6231-3439-4463-b464-316338376634__mshehurina_people_ar.webp';
import businessPlanImg from '../img/tild6635-3738-4531-b965-326535303263__mshehurina_people_ar.webp';
import founderM from '../img/founder_m.webp';
import founderN from '../img/founder_n.webp';
import linkedinLogo from '../img/linkedin_logo.webp';

import enLocale from 'i18n-iso-countries/langs/en.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';
import lvLocale from 'i18n-iso-countries/langs/lv.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import itLocale from 'i18n-iso-countries/langs/it.json';

countries.registerLocale(enLocale);
countries.registerLocale(ruLocale);
countries.registerLocale(lvLocale);
countries.registerLocale(esLocale);
countries.registerLocale(itLocale);

const Landing = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '', fullName: '' }); 
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [country, setCountry] = useState('');
  const locale = i18n.language || 'en';

  const [showPassword, setShowPassword] = useState(false);

  const getCountryOptions = () => {
    const locale = i18n.language || 'en'; // Получение текущего языка
    const allCountries = countries.getNames(locale); // Получение всех стран на нужном языке

    return Object.entries(allCountries)
      .map(([code, name]) => ({ value: code, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label)); // Сортировка по алфавиту
  };

  useEffect(() => {
    setCountry(null);

    if (user) {
      navigate('/projects');
    }
  }, [user, navigate, i18n.language]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    setErrorMessage('');
    setErrors({ email: '', password: '', fullName: '' });

    // Проверка на заполненность полей
    const newErrors = { email: '', password: '', fullName: '', country: '' };
    if (!email) {
      newErrors.email = t('email_required');
    } else if (!validateEmail(email)) {
      newErrors.email = t('email_invalid');
    }
    if (!password) newErrors.password = t('password_required');
    if (!fullName) newErrors.fullname = t('fullname_required');
    if (!country) newErrors.country = t('select_country');
    
    setErrors(newErrors);

    // Останавливаем отправку формы, если есть ошибки
    if (newErrors.email || newErrors.password) {
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/signup`, {
        user: {
          email,
          password,
          full_name: fullName,
          country,
        }
      });

      if (response.data.token) {
        // Сохраняем токен в localStorage
        localStorage.setItem('token', response.data.token);
        // Обновляем контекст пользователя
        login(response.data.user, response.data.token); // Предполагаем, что login обновляет состояние пользователя в контексте
        // Перенаправляем пользователя на страницу профиля или дашборда
        navigate('/projects'); // Обновите это согласно вашему маршруту профиля
      }
    } catch (error) {
      if (error.response) {
        const { error: serverError } = error.response.data;
        if (serverError === 'User with this email already exists.') {
          setErrorMessage(t('user_already_exists'));
        } else {
          setErrorMessage(t('registration_error'));
        }
      } else {
        // Ошибка установки ответа, или ответ не был получен вообще
        setErrorMessage(t('registration_error'));
      }
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="t-body" style={{ margin: '0' }}>
      {/* Main Container */}
      <div id="allrecords" data-tilda-export="yes" className="t-records" data-hook="blocks-collection-content-node" data-tilda-project-id="1844789" data-tilda-page-id="52113789" data-tilda-page-alias="copybrain" data-tilda-formskey="1595d483d78be9f7cd1bab5febf50a5d" data-tilda-lazy="yes" data-tilda-root-zone="one" data-tilda-project-headcode="yes">
        
        {/* Cover Section */}
        <div id="rec774562589" className="r t-rec" data-animationappear="off" data-record-type="338" style={{ minHeight: '100vh'}}>
          <div className="t-cover" id="recorddiv774562589" style={{ minHeight: '100vh', backgroundImage: `url(${headerBg})`, backgroundAttachment: 'scroll' }}>
            <div className="t-cover__carrier" id="coverCarry774562589" data-content-cover-id="774562589" data-content-cover-bg={headerBg} data-display-changed="true" data-content-cover-height="100vh" data-content-cover-parallax="" style={{ minHeight: '100vh', backgroundAttachment: 'scroll' }} itemScope itemType="http://schema.org/ImageObject">
              <meta itemProp="image" content={headerBg} />
            </div>
            <div className="t-cover__filter" style={{ minHeight: '100vh', backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.40), rgba(0,0,0,0.70))', filter: 'progid:DXImageTransform.Microsoft.gradient(startColorStr=#99000000, endColorstr=#4c000000)' }}></div>
            <div className="t338">
              <div className="t-container">
                <div className="t-col t-col_8 t-prefix_2">
                  <div className="t-cover__wrapper t-valign_middle" style={{ minHeight: '100vh', position: 'relative', zIndex: 1 }}>
                    <div className="t338 t-align_center">
                      <div data-hook-content="covercontent">
                        <div className="t339__wrapper">
                          <div className="t339__blocklogo">
                            <img className="t338__logo t-img" src={logo} style={{ maxWidth: '150px' }} data-hook-clogo="coverlogo" alt="" />
                          </div>

                          <div className="t338__title t-title t-title_xxs" field="title" style={{ maxWidth: '580px', margin: '0 auto 50px' }}>
                            {t('landing_top_heading')}
                          </div>

                          <div className="t338__descr t-descr t-descr_xl" field="descr" style={{ maxWidth: '580px', margin: '0 auto 63px' }}>
                            {t('landing_top_subheading')}
                          </div>

                          <div className="t338__buttonwrapper t-btnwrapper">
                            <a href="/signup" className="t338__btn t-btn t-btn_md" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: 'fit-content', color: '#ffffff', backgroundColor: '#fa675f', whiteSpace: 'nowrap', margin: '0 auto' }}>
                              {t('landing_get_started')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div id="rec774677139" className="r t-rec t-rec_pt_60 t-rec_pb_60" style={{ paddingTop: '60px', paddingBottom: '60px' }} data-record-type="106">
          <div className="t004">
            <div className="t-container">
              <div className="signup-container mt-0">
                <h2>{t('signup_subheading')}</h2>

                <form onSubmit={handleSignUp} className="signup-form mt-5" noValidate>
                  {errorMessage && <div className="error_message_container">{errorMessage}</div>}
                  <div className={`form-group ${errors.fullname ? 'has-error' : ''}`}>
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder={t('full_name')}
                      className={errors.fullname ? 'error-input' : ''}
                    />
                    {errors.email && <div className="error-message">{errors.fullname}</div>}
                  </div>

                  <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t('email')}
                      className={errors.email ? 'error-input' : ''}
                    />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                  </div>

                  <div className={`form-group ${errors.country ? 'has-error' : ''}`}>
                    <select
                      value={country || ''}  // Убедитесь, что значение не null
                      onChange={(e) => setCountry(e.target.value)}
                      className={`mb-0 ${errors.country ? 'error-input' : ''}`}
                    >
                      <option value="" disabled>{t('select_country')}</option>
                      {getCountryOptions().map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.country && <div className="error-message">{errors.country}</div>}
                  </div>

                  <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={t('password')}
                      className={errors.password ? 'password_input error-input' : 'password_input'}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                      className="password-toggle-icon"
                    />
                    {errors.password && <div className="error-message">{errors.password}</div>}
                  </div>

                  <button type="submit" className="signup-button">{t('signUp')}</button>
                </form>
                <p className="login-link">{t('already_signed_up')} <Link to="/login" className="signup_form_link">{t('log_in_here')}</Link></p>
              </div>
            </div>
          </div>
        </div>

        {/* What CopyBrain Can Do Section */}
        <div id="rec774679663" className="r t-rec t-rec_pt_120 t-rec_pb_135" style={{ paddingTop: '120px', paddingBottom: '135px', backgroundColor: '#eeeeee' }} data-record-type="905" data-bg-color="#eeeeee">
          <div className="t905">
            <div className="t-section__container t-container t-container_flex">
              <div className="t-col t-col_12">
                <div className="t-section__title t-title t-title_xs t-align_center t-margin_auto" field="btitle">
                  {t('landing_what_can_heading')}
                </div>

                <div className="t-section__descr t-descr t-descr_xl t-align_center t-margin_auto" field="bdescr" style={{ marginTop: '24px' }}>
                  {t('landing_what_can_subheading')}
                </div>
              </div>
            </div>

            <div className="t-container t-align_center" style={{ marginTop: '80px' }}>
              <div className="t-col t-col_12">
                <ul role="list" className="t905__wrapper">
                  <li className="t905__card t-align_left t-item">
                    <div className="t905__content">
                      <div className="t905__content_wrapper">
                        <div className="t905__title t-name t-name_xl" field="li_title__3421279294720">
                          {t('landing_what_can_item_1_heading')}
                        </div>

                        <div className="t905__descr t-descr t-descr_xs" field="li_descr__3421279294720">
                          {t('landing_what_can_item_1_text')}
                        </div>
                      </div>
                    </div>
                    <div className="t905__image_wrapper">
                      <div className="t905__image t-bgimg" data-original={feature1} style={{ backgroundImage: `url(${feature1})` }} itemScope itemType="http://schema.org/ImageObject">
                        <meta itemProp="image" content={feature1} />
                      </div>
                    </div>
                  </li>

                  <li className="t905__card t-align_left t-item">
                    <div className="t905__content">
                      <div className="t905__content_wrapper">
                        <div className="t905__title t-name t-name_xl" field="li_title__3421279294721">
                          {t('landing_what_can_item_2_heading')}
                        </div>

                        <div className="t905__descr t-descr t-descr_xs" field="li_descr__3421279294721">
                          {t('landing_what_can_item_2_text')}
                        </div>
                      </div>
                    </div>

                    <div className="t905__image_wrapper">
                      <div className="t905__image t-bgimg" data-original={feature2} style={{ backgroundImage: `url(${feature2})` }} itemScope itemType="http://schema.org/ImageObject">
                        <meta itemProp="image" content={feature2} />
                      </div>
                    </div>
                  </li>

                  <li className="t905__card t-align_left t-item">
                    <div className="t905__content">
                      <div className="t905__content_wrapper">
                        <div className="t905__title t-name t-name_xl" field="li_title__3421279294722">
                          {t('landing_what_can_item_3_heading')}
                        </div>

                        <div className="t905__descr t-descr t-descr_xs" field="li_descr__3421279294722">
                          {t('landing_what_can_item_3_text')}
                        </div>
                      </div>
                    </div>
                    <div className="t905__image_wrapper">
                      <div className="t905__image t-bgimg" data-original={feature3} style={{ backgroundImage: `url(${feature3})` }} itemScope itemType="http://schema.org/ImageObject">
                        <meta itemProp="image" content={feature3} />
                      </div>
                    </div>
                  </li>

                  <li className="t905__card t-align_left t-item">
                    <div className="t905__content">
                      <div className="t905__content_wrapper">
                        <div className="t905__title t-name t-name_xl" field="li_title__3421279294723">
                          {t('landing_what_can_item_4_heading')}
                        </div>

                        <div className="t905__descr t-descr t-descr_xs" field="li_descr__3421279294723">
                          {t('landing_what_can_item_4_text')}
                        </div>
                      </div>
                    </div>

                    <div className="t905__image_wrapper">
                      <div className="t905__image t-bgimg" data-original={feature4} style={{ backgroundImage: `url(${feature4})` }} itemScope itemType="http://schema.org/ImageObject">
                        <meta itemProp="image" content={feature4} />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Educational Quests Section */}
        <div id="rec774569788" className="r t-rec t-rec_pt_30 t-rec_pb_90" style={{ background: '#fff', paddingTop: '30px', paddingBottom: '90px' }} data-record-type="117">
          <div className="t106">
            <div className="t-container t-card__container">
              <div className="t106__wrapper t-card__col t-card__col_withoutbtn" style={{ borderRadius: '30px' }}>
                <div className="t106__block" style={{ backgroundColor: '#eeeeee' }}>
                  <div className="t106__title t-title t-title_xxs" field="title">
                    {t('landing_learn_earn_heading')}
                  </div>

                  <div className="t106__text t-text" field="text">
                    {t('landing_learn_earn_text')}
                  </div>
                </div>

                <div className="t106__block">
                  <img src={questImg} data-original={questImg} className="t106__img t-img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* How CopyBrain Works Section */}
        <div id="rec774671635" className="r t-rec t-rec_pt_30 t-rec_pb_150" style={{ background: '#eee', paddingTop: '100px', paddingBottom: '100px' }} data-record-type="576">
          <div className="t576">
            <div className="t-section__container t-container t-container_flex">
              <div className="t-col t-col_12">
                <div className="t-section__title t-title t-title_xs t-align_center t-margin_auto" field="btitle">
                  {t('landing_how_heading')}
                </div>
              </div>
            </div>

            <div className="t-container t576__container" style={{ marginTop: '80px' }}>
              <div className="t576__item t-item">
                <div className="t-width t-width_9 t576__mainblock">
                  <div className="t576__col t-align_center">
                    <div className="t576__linewrapper">
                      <div className="t576__line" style={{ width: '2px' }}></div>
                      <div className="t576__circle" style={{ borderWidth: '2px' }}>
                        <div className="t576__number t-name t-name_xs">1</div>
                      </div>
                    </div>
                    <div className="t576__block">
                      <img className="t576__img t-img" src={step1Icon} alt="" />

                      <div className="t576__title t-name t-name_lg" field="li_title__3825115271610">
                        {t('landing_how_item_1_heading')}
                      </div>

                      <div className="t576__descr t-text t-text_xs" field="li_descr__3825115271610">
                        {t('landing_how_item_1_text')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="t576__item t-item">
                <div className="t-width t-width_9 t576__mainblock">
                  <div className="t576__col t576__flipped t-align_center">
                    <div className="t576__linewrapper">
                      <div className="t576__line" style={{ width: '2px' }}></div>
                      <div className="t576__circle" style={{ borderWidth: '2px' }}>
                        <div className="t576__number t-name t-name_xs">2</div>
                      </div>
                    </div>
                    <div className="t576__block-flipped">
                      <img className="t576__img t-img" src={step2Icon} alt="" />

                      <div className="t576__title t-name t-name_lg" field="li_title__3825115271611">
                        {t('landing_how_item_2_heading')}
                      </div>

                      <div className="t576__descr t-text t-text_xs" field="li_descr__3825115271611">
                        {t('landing_how_item_2_text')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="t576__item t-item">
                <div className="t-width t-width_9 t576__mainblock">
                  <div className="t576__col t-align_center">
                    <div className="t576__linewrapper">
                      <div className="t576__line" style={{ width: '2px' }}></div>
                      <div className="t576__circle" style={{ borderWidth: '2px' }}>
                        <div className="t576__number t-name t-name_xs">3</div>
                      </div>
                    </div>

                    <div className="t576__block">
                      <img className="t576__img t-img" src={step3Icon} alt="" />

                      <div className="t576__title t-name t-name_lg" field="li_title__3825115271612">
                        {t('landing_how_item_3_heading')}
                      </div>

                      <div className="t576__descr t-text t-text_xs" field="li_descr__3825115271612">
                        {t('landing_how_item_3_text')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="t576__item t-item">
                <div className="t-width t-width_9 t576__mainblock">
                  <div className="t576__col t576__flipped t-align_center">
                    <div className="t576__linewrapper">
                      <div className="t576__line" style={{ width: '2px' }}></div>
                      <div className="t576__circle" style={{ borderWidth: '2px' }}>
                        <div className="t576__number t-name t-name_xs">4</div>
                      </div>
                    </div>

                    <div className="t576__block-flipped">
                      <img className="t576__img t-img" src={step4Icon} alt="" />

                      <div className="t576__title t-name t-name_lg" field="li_title__1721139486420">
                        {t('landing_how_item_4_heading')}
                      </div>

                      <div className="t576__descr t-text t-text_xs" field="li_descr__1721139486420">
                        {t('landing_how_item_4_text')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Plans Section */}

        <div id="rec774655156" className="r t-rec t-rec_pt_30 t-rec_pb_150" style={{ background: '#fff', paddingTop: '100px', paddingBottom: '100px' }} data-animationappear="off" data-record-type="1070">
          <div className="t1070" data-display-changed="true">
            <div className="t-section__container t-container t-container_flex">
              <div className="t-col t-col_12">
                <div className="t-section__title t-title t-title_xs t-align_center t-margin_auto" field="btitle">
                  {t('landing_pricing_heading')}
                </div>

                <div className="t-section__descr t-descr t-descr_xl t-align_center t-margin_auto" field="bdescr" style={{ marginTop: '24px' }}>
                  {t('landing_pricing_text_new')}
                </div>
              </div>
            </div>

            <div className="t-container t-card__container t1070__withfeatured" style={{ display: 'flex', justifyContent: 'center', marginTop: '56px' }}>
              <div className="t1070__row">
                <div className="t1070__col t-card__col t-col t-col_6 t-align_center t1070__featured">
                  <div className="t1070__content" style={{ border: '1px solid #e0e6ed', borderRadius: '9px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', marginTop: '24px' }}>
                    <div className="t1070__imgwrapper">
                      <div className="t1070__bgimg t1070__img_circle t-margin_auto t-bgimg" data-original={proPlanImg} style={{ width: '150px', height: '150px', backgroundImage: `url(${proPlanImg})` }} itemScope itemType="http://schema.org/ImageObject">
                        <meta itemProp="image" content={proPlanImg} />
                      </div>
                    </div>
                    <div className="t-card__title t-name t-name_xl" field="li_title__3944859879782">
                      Copy Brain
                    </div>

                    <div className="t-card__descr t-descr t-descr_xs" field="li_descr__3944859879782">
                      <ul>
                        <li data-list="bullet">3 {t('projects')}</li>
                        <li data-list="bullet">{t('unlimited')} {t('themes_r')}</li>
                        <li data-list="bullet">{t('unlimited')} {t('posts_r')}</li>
                        <li data-list="bullet">{t('unlimited')} {t('scenarios_r')}</li>
                        <li data-list="bullet">{t('unlimited')} {t('storytelling_r')}</li>
                        <li data-list="bullet">{t('profile_generation_heading')}</li>
                        <li data-list="bullet">{t('profile_analysis')}</li>
                        {/*<li data-list="bullet">{t('unlimited')} {t('access_to_quests')}</li>*/}
                      </ul>
                    </div>
                    <div className="t1070__price-wrap">
                      <div className="t1070__price t1070__price_current t-name t-name_md" style={{ width: '100%' }} field="li_price__3944859879782">€25 / {t('month')}</div>

                      <div className="t1070__price t1070__price_current t-name t-name_md" style={{ width: '100%', marginTop: '16px' }} field="li_price__3944859879782">€200 / {t('year')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Team */}
        <div id="rec774671635" className="r t-rec t-rec_pt_30 t-rec_pb_150" style={{ background: '#eee', paddingTop: '100px', paddingBottom: '100px' }} data-record-type="576">
          <div className="t576">
            <div className="t-section__container t-container t-container_flex">
              <div className="t-col t-col_12">
                <div className="t-section__title t-title t-title_xs t-align_center t-margin_auto" field="btitle">
                  {t('landing_team_heading')}
                </div>
              </div>
            </div>

            <div className="landing_team_container">
              <div className="landing_team_item">
                <div className="landing_team_item_img">
                  <img className="" src={founderM} style={{ maxWidth: '300px' }} alt="Michelle Shehurina" />
                </div>

                <div className="landing_team_item_name">
                  Michelle Shehurina

                  <a href="https://www.linkedin.com/in/shehurina/">
                    <img className="" src={linkedinLogo} style={{ maxWidth: '300px' }} alt="Nikita Gnotovs" />
                  </a>
                </div>

                <div className="landing_team_item_position">
                  Co-founder, CEO
                </div>
              </div>

              <div className="landing_team_item">
                <div className="landing_team_item_img">
                  <img className="" src={founderN} style={{ maxWidth: '300px' }} alt="Nikita Gnotovs" />
                </div>

                <div className="landing_team_item_name">
                  Nikita Gnotovs

                  <a href="https://www.linkedin.com/in/gnotov/">
                    <img className="" src={linkedinLogo} style={{ maxWidth: '300px' }} alt="Nikita Gnotovs" />
                  </a>
                </div>

                <div className="landing_team_item_position">
                  Co-founder, CTO
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div id="rec774559648" className="r t-rec t-rec_pt_90 t-rec_pb_45" style={{ paddingTop: '90px', paddingBottom: '45px', backgroundColor: '#1e1e1e' }} data-animationappear="off" data-record-type="128" data-bg-color="#1e1e1e">
          <div className="t120">
            <div className="t-container t-align_center">
              <div className="t-col t-col_8 t-prefix_2">
                <div className="t120__title t-heading t-heading_sm" field="title" style={{ color: '#ffffff' }}>
                  {t('landing_ready_heading')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Get Started Button Section */}
        <div id="rec774559649" className="r t-rec t-rec_pt_30 t-rec_pb_0" style={{ paddingTop: '30px', paddingBottom: '0px', backgroundColor: '#1e1e1e' }} data-animationappear="off" data-record-type="191" data-bg-color="#1e1e1e">
          <div className="t142">
            <div className="t-container_100">
              <div className="t142__wrapone">
                <div className="t142__wraptwo">
                  <a className="t-btn t142__submit t-btn_md" href="/signup" style={{ color: '#ffffff', backgroundColor: '#fa675f', borderRadius: '30px' }}>
                    <span className="t142__text">
                      {t('landing_get_started')}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Social Links Section */}
        <div id="rec774559647" className="r t-rec t-rec_pt_120 t-rec_pb_60" style={{ paddingTop: '120px', paddingBottom: '60px', backgroundColor: '#1e1e1e' }} data-animationappear="off" data-record-type="212" data-bg-color="#1e1e1e">
          <div className="t188">
            <div className="t-container_100">
              <div className="t188__wrapper">
                <div className="t-sociallinks">
                  <ul role="list" className="t-sociallinks__wrapper" aria-label="Social media links">
                    <li className="t-sociallinks__item t-sociallinks__item_linkedin">
                      <a href="https://www.linkedin.com/company/copy-brain" target="_blank" rel="nofollow" aria-label="linkedin" style={{ width: '48px', height: '48px' }}>
                        <svg className="t-sociallinks__svg" role="presentation" width="48px" height="48px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M50 100c27.6142 0 50-22.3858 50-50S77.6142 0 50 0 0 22.3858 0 50s22.3858 50 50 50Zm23-31.0002V52.363c0-8.9114-4.7586-13.0586-11.1079-13.0586-5.1234 0-7.4123 2.8199-8.6942 4.7942v-4.1124h-9.6468c.1297 2.7235 0 29.0136 0 29.0136h9.6484v-16.203c0-.8675.0657-1.731.3203-2.3513.6981-1.7351 2.284-3.5286 4.9491-3.5286 3.4905 0 4.8859 2.6611 4.8859 6.5602v15.5227H73ZM53.1979 44.0986v.094h-.0632c.0069-.0111.0148-.0228.0229-.0346.0137-.0198.0281-.0401.0403-.0594ZM28 31.0123C28 28.1648 30.1583 26 33.4591 26c3.3016 0 5.3302 2.1648 5.3934 5.0123 0 2.7851-2.0918 5.0156-5.4567 5.0156h-.064c-3.2351 0-5.3318-2.2305-5.3318-5.0156Zm10.2177 37.9875h-9.6445V39.9862h9.6445v29.0136Z" fill="#ebebeb" />
                        </svg>
                      </a>
                    </li>
                    <li className="t-sociallinks__item t-sociallinks__item_instagram">
                      <a href="https://www.instagram.com/copybrain.app/" target="_blank" rel="nofollow" aria-label="instagram" style={{ width: '48px', height: '48px' }}>
                        <svg className="t-sociallinks__svg" role="presentation" width="48px" height="48px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM25 39.3918C25 31.4558 31.4566 25 39.3918 25H60.6082C68.5442 25 75 31.4566 75 39.3918V60.8028C75 68.738 68.5442 75.1946 60.6082 75.1946H39.3918C31.4558 75.1946 25 68.738 25 60.8028V39.3918ZM36.9883 50.0054C36.9883 42.8847 42.8438 37.0922 50.0397 37.0922C57.2356 37.0922 63.0911 42.8847 63.0911 50.0054C63.0911 57.1252 57.2356 62.9177 50.0397 62.9177C42.843 62.9177 36.9883 57.1252 36.9883 50.0054ZM41.7422 50.0054C41.7422 54.5033 45.4641 58.1638 50.0397 58.1638C54.6153 58.1638 58.3372 54.5041 58.3372 50.0054C58.3372 45.5066 54.6145 41.8469 50.0397 41.8469C45.4641 41.8469 41.7422 45.5066 41.7422 50.0054ZM63.3248 39.6355C65.0208 39.6355 66.3956 38.2606 66.3956 36.5646C66.3956 34.8687 65.0208 33.4938 63.3248 33.4938C61.6288 33.4938 60.2539 34.8687 60.2539 36.5646C60.2539 38.2606 61.6288 39.6355 63.3248 39.6355Z" fill="#ebebeb" />
                        </svg>
                      </a>
                    </li>
                    <li className="t-sociallinks__item t-sociallinks__item_telegram">
                      <a href="https://t.me/+Nt11bcFPcd81YWZk" target="_blank" rel="nofollow" aria-label="telegram" style={{ width: '48px', height: '48px' }}>
                        <svg className="t-sociallinks__svg" role="presentation" width="48px" height="48px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm21.977-68.056c.386-4.38-4.24-2.576-4.24-2.576-3.415 1.414-6.937 2.85-10.497 4.302-11.04 4.503-22.444 9.155-32.159 13.734-5.268 1.932-2.184 3.864-2.184 3.864l8.351 2.577c3.855 1.16 5.91-.129 5.91-.129l17.988-12.238c6.424-4.38 4.882-.773 3.34.773l-13.49 12.882c-2.056 1.804-1.028 3.35-.129 4.123 2.55 2.249 8.82 6.364 11.557 8.16.712.467 1.185.778 1.292.858.642.515 4.111 2.834 6.424 2.319 2.313-.516 2.57-3.479 2.57-3.479l3.083-20.226c.462-3.511.993-6.886 1.417-9.582.4-2.546.705-4.485.767-5.362Z" fill="#ebebeb" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="rec774559647" className="r t-rec t-rec_pt_120 t-rec_pb_60" style={{ paddingTop: '40px', paddingBottom: '80px', backgroundColor: '#1e1e1e' }} data-animationappear="off" data-record-type="212" data-bg-color="#1e1e1e">
          <div className="supported_heading">
            {t('supported_by')}
          </div>

          <div className="supported_logo">
            <img src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/eworks-PYIUpXnN.svg" />
          </div>
        </div>

        <div className="liia_section">
          <div className="liia_img">
            <img src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/LIDZFINANSE-ES%2BNAP.webp" />
          </div>

          <div className="liia_text">
            Agreement Nr. 17.1-1-L-2024/330
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;