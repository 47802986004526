import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: 'Welcome to CopyBrain!',
      description: 'CopyBrain is a service for generating creative post ideas for social media with AI',
      description_2: 'Start creating creative posts for your business using the power of artificial intelligence',
      signUp: 'Sign Up',
      login: 'Log In',
      logout: 'Log Out',
      generate: 'Generate topics',
      form_project_name_label: 'Project Name',
      form_project_name_text: 'This information is for you',
      form_niche_label: "What's the main theme or niche of your account?",
      form_niche_text: 'For example, fashion, travel, technology, fitness, cooking, education',
      form_audience_label: 'Age, gender, interests, geographical location',
      form_audience_text: 'Who is your target audience?',
      form_audience_characteristics_label: 'What specific characteristics does your target audience have?',
      form_audience_characteristics_text: 'Are there specific problems, issues, or questions that are especially important to them?',
      form_content_goal_label: 'What unique traits does your target audience have?',
      form_content_goal_select_title: 'Choose a goal',
      form_content_goal_select_1: 'Increasing engagement',
      form_content_goal_select_2: 'Growing subscriber count',
      form_content_goal_select_3: 'Product or service sales',
      form_content_goal_select_4: 'Educational content',
      form_text_tone_label: 'What style and tone should the post have?',
      form_text_tone_select_title: 'Choose the style and tone',
      form_text_tone_select_1: 'Informative',
      form_text_tone_select_2: 'Inspiring',
      form_text_tone_select_3: 'Persuasive',
      form_text_tone_select_4: 'Friendly',
      form_product_service_label: 'Which specific product or service will be featured?',
      form_product_service_text: 'If possible, provide a detailed description. (For example, format, duration, case studies, reviews, cost)',
      select_theme_to_generate_post: 'Choose a topic to generate a post',
      generating: 'Working...',
      generate_themes: 'Generate topics',
      themes_form_heading: 'Social Media Post Topic Generator',
      text_copied: 'Text copied!',
      copy_text: 'Copy text',
      error_while_processing: 'An error occurred while processing your request:',
      back: 'Go back',
      post_result_heading: 'Your post on the topic - ',
      select_language: 'Choose a language',
      select_language_text: 'Here you can select the language in which the content will be generated',
      in_latvian: 'Latvian',
      in_english: 'English',
      in_russian: 'Russian',
      in_spanish: 'Spanish',
      in_italian: 'Italian',
      admin_panel: 'Admin Panel',
      project: 'Project',
      projects: 'Projects',
      projects_r: 'Projects',
      login_heading: 'Login to CopyBrain',
      login_subheading: 'Log in to continue',
      signup_heading: 'Welcome to CopyBrain',
      signup_subheading: 'Start making creative posts now',
      email: 'Email',
      password: 'Password',
      full_name: 'Full Name',
      already_signed_up: 'Already signed up? ',
      dont_have_account: 'Don’t have an account? ',
      log_in_here: 'Log in here',
      please_register: 'Register ',
      projects_loading: "Loading projects...",
      projects_not_found: 'Projects not found. You can create a new project.',
      your_projects: 'Your Projects',
      ideas: 'Ideas',
      posts: 'Posts',
      posts_r: 'Posts',
      reels: 'Reels',
      saved_posts_ideas: 'Saved Post Ideas',
      saved_posts: 'Saved Posts',
      information_about_project: 'Information about the Project',
      save_project: 'Save project',
      registration_error: 'An error occurred during registration. Please try again.',
      login_error: 'An error occurred during login. Please try again.',
      users_fetch_error: 'Error fetching user list.',
      projects_fetch_error: 'Error fetching project list.',
      themes_fetch_error: 'Error fetching theme list.',
      post_fetch_error: 'Error fetching the post.',
      profile: "Profile",
      change_password: "Change Password",
      new_password: "New Password",
      password_change_success: "Password Successfully Changed",
      error_changing_password: "Error Changing Password",
      your_details: "Your Details",
      current_plan: "Selected Plan",
      free_plan: "Free",
      unlimited: "Unlimited",
      themes: "Themes",
      themes_r: "Themes",
      month: "month",
      choose: "Choose",
      thank_you_for_your_purchase: "Thank you for your purchase!",
      your_subscription_has_been_activated: "Your subscription has been activated",
      start_generating_projects: "Start generating content",
      subscription_expires_at: "Subscription paid until",
      billing: "Billing",
      modal_heading_plan_needed: "Advanced Features",
      modal_text_projects: "To create a new project, you need to upgrade to one of the paid plans. This will give you access to advanced features and the ability to manage multiple projects.",
      modal_text_themes: "Theme generation is only available on paid plans. Upgrade to one of them to use this feature.",
      continue_to_payment: "Proceed to Payment",
      cancel_subscription: "Cancel Subscription",
      confirm_cancellation: "Confirm Cancellation",
      are_you_sure_cancel_subscription: "Are you sure you want to cancel your subscription? Your subscription will remain active until the end of the current paid period.",
      confirm: "Confirm",
      cancel: "Cancel",
      subscription_canceled: "Subscription canceled",
      subscription_renew: "Renew Subscription",
      confirm_renew: "Renew Subscription",
      are_you_sure_renew_subscription: "Are you sure you want to renew your subscription? This will allow you to regain access to all the benefits of the plan, including unlimited creation of themes and posts.",
      subscription_plan_will_be_selected: "The plan will be selected after the subscription period ends.",
      subscription_upgrade: "Subscription Upgrade",
      subscription_upgrade_modal_text: "You are about to switch to a different subscription plan. Your current plan will be upgraded, and the new amount will be prorated for the remaining time until the end of the current billing period. This means you will only pay for the remaining time at the new plan's cost.\nKey points:\n- Your new plan will take effect immediately.\n- You will receive additional features and benefits according to the selected plan.\n- The prorated amount will be calculated and charged to your account automatically.",
      select_content_type: "Select Content Type",
      create_post: "Create Post",
      create_reel_scenario: "Create Reel Scenario",
      select_reel_duration: "Select Reel Duration",
      seconds: "seconds",
      reel_result_heading: "Your scenario on the topic - ",
      scenarios: "Scenarios",
      saved_scenarios: "Saved Scenarios",
      scenario_fetch_error: "Error fetching scenario",
      scenarios_r: "Scenarios",
      contacts: "Contacts",
      ask_question: "Ask a Question",
      question_subheading: "Please enter your question below. We will send the answer to your email:",
      send: "Send",
      question_message: "Your request has been sent, we will reply as soon as possible.",
      subscription_link_error: "Error proceeding to payment. Please try again.",
      subscription_cancel_error: "Error canceling subscription. Please try again.",
      subscription_renew_error: "Error renewing subscription. Please try again.",
      subscription_load_error: "Error checking subscription. Please try again.",
      questions_list: "List of Questions",
      profiles: "Profile Header",
      saved_profiles: "Saved Profile Headers",
      generate_profiles: "Generate profile header",
      modal_text_profiles: "Profile header generation is only available on paid plans. Upgrade to one of them to use this feature.",
      projects_panel: "Project management panel",
      project_information_subtext: "Fill in the key information about your project. <br/> Based on this information, we will generate content ideas.",
      post_ideas_information_subtext: "Browse saved ideas, posts, and scenarios. <br/> Click on a topic to generate a post or scenario.",
      posts_information_subtext: "List of your generated posts. <br/> Click on a topic to view the post.",
      scenarios_information_subtext: "List of your generated scenarios. <br/> Click on a topic to view the scenario.",
      profiles_information_subtext: "List of your generated profile headers.",
      generate_hint: "Generate response",
      hints_missing_niche_text: "Please fill in the 'theme or niche' field before generating hints.",
      write_for_me: "Write for me",
      profile_analysis: "Profile Analysis",
      analyzing: "Analyzing...",
      upload_analyze: "Upload and Analyze",
      upload_image_for_analysis: "Upload a screenshot of your profile",
      upload_image_for_analysis_text: "Our profile analysis tool allows you to upload a screenshot of your profile and receive recommendations for its improvement. Once the analysis is complete, the results will automatically appear on the screen so you can immediately review the helpful tips.",
      analysis_result: "Profile improvement tips",
      saved_analyses_results: "Saved profile analysis results",
      analyses_information_subtext: "List of your saved profile analyses",
      modal_text_analyses: "Profile analysis is only available on paid plans. Upgrade to one of them to use this feature.",
      profile_generation_heading: "Instagram Profile Header Generation",
      profile_generation_text: "With our tool, you can quickly and easily create the perfect header for your Instagram profile using your project data. Simply fill out the form with information about your project and click 'Generate'. Your new header will be ready in seconds, providing a professional and attractive look to your profile.",
      profile_generated: "Generated profile header",
      custom_theme_heading: "Generate Post Based on Your Theme",
      custom_theme_text: 'Enter text describing the main theme of your post, for example: "New Product Launch". Select the desired language from the dropdown list. Click the "Generate Post" button. You will be redirected to a page with the ready post, where you can view, copy, and use it in your profile.',
      custom_theme_label: "Enter text describing the main theme of your post",
      enter_theme: "Enter the theme",
      theme_required_error: "The theme is required",
      generate_post: "Generate Post",
      modal_text_custom_theme: "Generating posts based on your own theme is only available on paid plans. Upgrade to one of them to use this feature.",
      custom_scenario_theme_heading: "Generate Scenario Based on Your Theme",
      custom_scenario_theme_text: 'Enter a text describing the main theme of your scenario. It can be anything from a product demonstration to a short guide. In the dropdown menu, select the desired language and choose the duration of the video. Click the "Generate Scenario" button. You will be redirected to a page with the finished scenario, where you can view, copy, and use it in your profile.',
      custom_scenario_theme_label: "Enter the text describing the main theme of your scenario",
      generate_scenario: "Generate Scenario",
      modal_text_custom_scenario_theme: "Generating scenarios based on your own theme is only available on paid plans. Upgrade to one of them to use this feature.",
      email_required: "Enter your email",
      password_required: "Enter your password",
      user_not_found: "User with this email not found",
      invalid_credentials: "You have entered an incorrect password",
      fullname_required: "Enter your full name",
      user_already_exists: "User with this email already exists",
      email_invalid: "Invalid email format",
      forgot_password: "Forgot your password? ",
      recover: "Recover",
      password_recovery: "Password Recovery",
      enter_email_to_continue: "Enter your email to continue",
      enter_new_password_to_continue: "Enter your new password to continue",
      enter_new_password: "Enter new password",
      new_password_confirm: "Confirm new password",
      save: "Save",
      check_your_email_for_instructions: "Please check your email for instructions on how to reset your password. This may take a few minutes.",
      email_not_found: "Email address not found. Please check and try again.",
      password_changed_continue_to_login: "Password successfully reset. You can now log in with your new password.",
      link_is_inactive_generate_new: "The link is inactive or expired. Try generating a new link.",
      passwords_do_not_match: "Passwords do not match",
      remember_password: "Remember your password?",
      days: "days",
      hours: "hours",
      minutes: "minutes",
      seconds: "seconds",
      until_travel: "Time left until the journey to the land of Neural Networks",
      game: "Game",
      enter_game: "Enter the game",
      event_started: "Event started",
      admin_courses: "Admin courses",
      project_form_saved: "Project saved",
      sent: "Sent",
      modal_text_no_more_posts: "You have reached the post limit. Further generation is only available on paid plans. Upgrade to one of them to use this feature.",
      modal_text_no_more_scenarios: "You have reached the scenario limit. Further generation is only available on paid plans. Upgrade to one of them to use this feature.",
      storytelling: "Storytelling",
      storytelling_r: "Stories",
      saved_stories: "Saved Stories",
      stories_information_subtext: "List of your generated stories <br/> Click on a theme to view the story",
      custom_story_theme_heading: "Generate Stories with Your Data",
      custom_story_theme_text: "Storytelling is designed to create structured and purposeful stories for various goals, such as selling a product or service, sharing a personal story, or educational stories. Click the 'Generate Story' button. You will be redirected to a page with the completed story, where you can view, copy, and use it in your profile.",
      generate_story: "Generate Story",
      save_story_form: "Save Storytelling Form",
      form_story_goal_label: "Select Storytelling Goal",
      form_story_goal_text: "Select from the list (selling a product or service, sharing a personal story, educational stories)",
      form_story_theme_label: "Main Theme or Idea of the Story",
      form_story_theme_text: "What is the main theme or idea of your story? For example, personal experience, customer success story, product creation process.",
      form_story_character_label: "Main Characters of the Story",
      form_story_character_text: "Who is the main character of your story? (e.g., you, your client, your product) What are the key characteristics of this character? (e.g., age, profession, personal qualities)",
      form_story_problem_label: "Problem Faced by the Main Character at the Beginning of the Story",
      form_story_problem_text: "What problem does the main character face at the beginning of the story?",
      form_story_solution_label: "Proposed Solution to the Problem",
      form_story_solution_text: "What solution did your brand, product, or you propose?",
      form_story_result_label: "Description of Changes After Using the Proposed Solution",
      form_story_result_text: "How did the situation change after using your solution? What are the specific results?",
      form_story_emotions_label: "Emotions You Want to Evoke in the Audience",
      form_story_emotions_text: "What emotions do you want to evoke in the audience? (e.g., inspiration, joy, curiosity)",
      form_story_unique_moments_label: "Inclusion of Unique or Interesting Details in the Story",
      form_story_unique_moments_text: "Are there any unique or interesting details you would like to include in the story?",
      form_story_cta_label: "Desired Action of the Audience After Reading the Story",
      form_story_cta_text: "What action do you want the audience to take after reading your story? (e.g., visit the website, buy the product, subscribe to the newsletter, write a direct message)",
      form_story_qty_label: "Number of Stories Needed to Fully Tell the Story",
      form_story_qty_text: "The Oracle advises choosing between 5 and 7",
      form_story_language_label: "Select Language",
      form_story_language_text: "Here you can select the language in which the content will be created",
      story_goal_select_title: "Select Goal",
      story_goal_select_1: "Selling a Product or Service",
      story_goal_select_2: "Sharing a Personal Story",
      story_goal_select_3: "Educational Stories",
      story_result_heading: 'Your Storytelling on the Theme - ',
      story_form_saved: "Storytelling Form Saved",
      unable_to_save_project: "Unable to Save Project.",
      unable_to_save_story: "Unable to Save Storytelling Form.",
      saving: "Saving...",
      story_fetch_error: 'Error Fetching Storytelling',
      hints_missing_story_goal_text: "Please select a storytelling goal before generating hints.",
      modal_text_story: "Storytelling generation is only available on paid plans. Upgrade to access this feature.",
      form_story_theme_required: "Please fill in the storytelling theme",
      incorrect_code: "Incorrect code. Please enter another code.",
      thanks_answer_accept_awaiting: "Thank you! Your answer is awaiting confirmation!",
      thanks_answer_accepted: "Thank you! Your answer has been accepted! ",
      you_received: "You received",
      received_points: "points.",
      your_answer: "Your answer:",
      see_screenshot: "See screenshot",
      feedback_for_quest: "Feedback for the quest",
      your_answer_for_quest: "Your answer for the quest",
      enter_code: "Enter code",
      answer_text: "Answer text",
      screenshot: "Screenshot",
      quests: "Quests",
      lessons: "Lessons",
      to_access_education: "To access the lessons, you need to purchase a paid plan",
      you_earned: "You earned",
      brain_points: "brain points",
      balance: "Balance",
      landing_top_heading: "Unlock Your Content Creation Potential with CopyBrain",
      landing_top_subheading: "AI-Powered content generation and educational quests to elevate your social media game",
      landing_get_started: "GET STARTED FOR FREE",
      landing_what_can_heading: "What can CopyBrain do for you?",
      landing_what_can_subheading: "Transform your social media strategy with AI-powered features",
      landing_what_can_item_1_heading: "Generate Engaging Content Ideas",
      landing_what_can_item_1_text: "Struggling to find new content ideas? CopyBrain's AI generates fresh, engaging content ideas tailored to your audience.",
      landing_what_can_item_2_heading: "Craft Perfect Social Media Posts, Reels and Stories",
      landing_what_can_item_2_text: "Create compelling social media content effortlessly with our AI-driven content generation tool.",
      landing_what_can_item_3_heading: "Perfect Your Profile Bio",
      landing_what_can_item_3_text: "Craft the perfect bio for your social media profiles with AI-generated suggestions.",
      landing_what_can_item_4_heading: "Analyze Your Profile with a Screenshot",
      landing_what_can_item_4_text: "Upload a screenshot of your Instagram profile and get actionable insights on how to improve it.",
      landing_learn_earn_heading: "Learn and Earn with CopyBrain Educational Quests",
      landing_learn_earn_text: "Introducing our new Education Section! Complete educational quests, collect points, and exchange them for bonuses inside CopyBrain. Enhance your social media skills while having fun.",
      landing_how_heading: "How CopyBrain Works",
      landing_how_item_1_heading: "Sign Up",
      landing_how_item_1_text: "Create your free account to get started.",
      landing_how_item_2_heading: "Generate Content",
      landing_how_item_2_text: "Use our AI tools to generate content ideas, social media posts, and more.",
      landing_how_item_3_heading: "Enhance and Learn",
      landing_how_item_3_text: "Complete educational quests to improve your skills and earn rewards.",
      landing_how_item_4_heading: "Analyze and Improve",
      landing_how_item_4_text: "Upload profile screenshots for in-depth analysis and actionable insights.",
      landing_pricing_heading: "Pricing Plans",
      landing_pricing_text: "Choose the perfect plan for you",
      landing_pricing_item_1_heading: "Free",
      landing_pricing_item_2_heading: "Starter",
      landing_pricing_item_3_heading: "Pro",
      landing_pricing_item_4_heading: "Business",
      landing_ready_heading: "Ready to elevate your social media presence?",
      access_to_quests: "access to educational quests",
      month: "Month",
      select_reel_format: "Select your video format",
      select_reel_cta: "Select call-to-action",
      generate_reel_writing: "Generate caption for reels?",
      thematic_selection: "Thematic selection",
      useful_lifehacks: "Useful lifehacks",
      educational_video: "Educational video",
      success_story: "Success story",
      product_demonstration: "Product demonstration",
      life_stories_and_funny_cases: "Life stories and funny cases",
      before_after: "Before-after",
      scenes_with_characters: "Scenes with characters",
      text_on_background: "Text on background",
      storytelling: "Storytelling",
      talking_head: "Talking head",
      subscribe: "Subscribe",
      more_in_highlights: "More in highlights",
      more_useful_information_in_profile: "More useful information in profile",
      write_the_code_word_in_comments_to_receive: "Write the code word in comments to receive",
      send_the_code_word_in_direct_to_receive: "Send the code word in direct to receive",
      select_reel_settings: "Select reel settings",
      untitled: "Untitled",
      select_country: "Select your country",
      supported_by: "Supported by",
      created_by: "Created by",
      form_text_customisation_label: "Personalisation",
      form_text_customisation_text: "Please upload a sample of your writing. COPY BRAIN will use this to match the style, tone, and structure of the text generated to your unique writing style. Make sure the sample reflects how you typically write.",
      hide: "Hide",
      show_full: "Expand in full",
      select_social: "For which social network is the post?",
      select_post_settings: "Select post settings",
      post_adapted_for: "Post adapted for",
      year: "Year",
      landing_pricing_text_new: "One plan for everyone",
      landing_team_heading: "Team",
    },
  },
  ru: {
    translation: {
      welcome: 'Приветствуем в CopyBrain!',
      description: 'CopyBrain — это сервис для генерации креативных идей для постов в социальных сетях с помощью ИИ',
      description_2: 'Начните создавать креативные посты для своего бизнеса, используя силу искусственного интеллекта',
      signUp: 'Зарегистрироваться',
      login: 'Войти',
      logout: 'Выйти',
      generate: 'Генерировать темы',
      form_project_name_label: 'Название проекта',
      form_project_name_text: 'Эта информация для вас',
      form_niche_label: 'Какая основная тема или ниша вашего аккаунта?',
      form_niche_text: 'Например, мода, путешествия, технологии, фитнес, кулинария, образование',
      form_audience_label: 'Возраст, пол, интересы, географическое расположение',
      form_audience_text: 'Кто ваша целевая аудитория?',
      form_audience_characteristics_label: 'Какие особенности есть у вашей целевой аудитории',
      form_audience_characteristics_text: 'Есть ли определённые проблемы, боли или вопросы, которые особенно важны для них',
      form_content_goal_label: 'Какие цели вы ставите перед своим контентом?',
      form_content_goal_select_title: 'Выберите цель',
      form_content_goal_select_1: 'Увеличение вовлеченности',
      form_content_goal_select_2: 'Рост числа подписчиков',
      form_content_goal_select_3: 'Продажи продуктов или услуг',
      form_content_goal_select_4: 'Образовательный контент',
      form_text_tone_label: 'Какой стиль и тон должен быть в посте?',
      form_text_tone_select_title: 'Выберите стиль и тон',
      form_text_tone_select_1: 'Информативный',
      form_text_tone_select_2: 'Вдохновляющий',
      form_text_tone_select_3: 'Убедительный',
      form_text_tone_select_4: 'Дружелюбный',
      form_product_service_label: 'О каком конкретном продукте или услуге пойдет речь?',
      form_product_service_text: 'Если есть, предоставьте детальное описание. (Например, формат, длительность, кейсы, отзывы, стоимость)',
      select_theme_to_generate_post: 'Выберите тему для генерации поста',
      generating: 'Генерация...',
      generate_themes: 'Сгенерировать темы',
      themes_form_heading: 'Генератор тем для постов соц. сетях',
      text_copied: 'Текст скопирован!',
      copy_text: 'Скопировать текст',
      error_while_processing: 'Произошла ошибка при обработке вашего запроса: ',
      back: 'Назад',
      post_result_heading: 'Ваш пост на тему - ',
      select_language: 'Выберите язык',
      select_language_text: 'Здесь вы можете выбрать язык, на котором будет создан контент',
      in_latvian: 'Латышский',
      in_english: 'Английский',
      in_russian: 'Русский',
      in_spanish: 'Испанский',
      in_italian: 'Итальянский',
      admin_panel: 'Админка',
      project: 'Проект',
      projects: 'Проекты',
      projects_r: 'Проекта',
      login_heading: 'Вход в CopyBrain',
      login_subheading: 'Войдите, чтобы продолжить',
      signup_heading: 'Добро пожаловать в CopyBrain',
      signup_subheading: 'Начните писать креативные посты прямо сейчас',
      email: 'Емаил',
      password: 'Пароль',
      full_name: 'Имя фамилия',
      already_signed_up: 'Уже зарегистрированы?' ,
      dont_have_account: 'Еще не зарегистрированы? ',
      log_in_here: 'Войдите здесь',
      please_register: 'Зарегистрируйтесь ',
      projects_loading: 'Загрузка проектов...',
      projects_not_found: 'Проекты не найдены. Вы можете создать новый проект.',
      your_projects: 'Ваши проекты',
      ideas: 'Идеи',
      posts: 'Посты',
      posts_r: 'Постов',
      reels: 'Рилсы',
      saved_posts_ideas: 'Сохраненные идеи для постов',
      saved_posts: 'Сохраненные посты',
      information_about_project: 'Информация о проекте',
      save_project: 'Сохранить проект',
      registration_error: 'Произошла ошибка при регистрации. Попробуйте снова.',
      login_error: 'Произошла ошибка при входе. Попробуйте снова.',
      users_fetch_error: 'Ошибка при получении списка пользователей',
      projects_fetch_error: 'Ошибка при получении списка проектов',
      themes_fetch_error: 'Ошибка при получении списка тем',
      post_fetch_error: 'Ошибка при получении поста',
      profile: "Профиль",
      change_password: "Изменить пароль",
      new_password: "Новый пароль",
      password_change_success: "Пароль успешно изменен",
      error_changing_password: "Ошибка при изменении пароля",
      your_details: "Ваши данные",
      current_plan: "Выбранный тариф",
      free_plan: "Бесплатный",
      unlimited: "Неограниченно",
      themes: "Темы",
      themes_r: "Тем",
      month: "месяц",
      choose: "Выбрать",
      thank_you_for_your_purchase: "Спасибо за покупку!",
      your_subscription_has_been_activated: "Ваша подписка активирована",
      start_generating_projects: "Начать генерацию контента",
      subscription_expires_at: "Подписка оплачена до",
      billing: "Оплата",
      modal_heading_plan_needed: "Расширенные функции",
      modal_text_projects: "Чтобы создать новый проект, вам необходимо перейти на один из платных планов. Это даст вам доступ к расширенным функциям и возможности управлять несколькими проектами",
      modal_text_themes: "Генерация тем доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      continue_to_payment: "Перейти к оплате",
      cancel_subscription: "Отменить подписку",
      confirm_cancellation: "Подтвердить отмену",
      are_you_sure_cancel_subscription: "Вы уверены, что хотите отменить подписку? Ваша подписка останется активной до конца текущего оплаченного периода.",
      confirm: "Подтвердить",
      cancel: "Отмена",
      subscription_canceled: "Подписка отменена",
      subscription_renew: "Возобновить подписку",
      confirm_renew: "Возобновление подписки",
      are_you_sure_renew_subscription: "Вы уверены, что хотите возобновить вашу подписку? Это позволит вам снова получить доступ ко всем преимуществам плана, включая неограниченное создание тем и постов.",
      subscription_plan_will_be_selected: "План будет выбран после окончания времени подписки",
      subscription_upgrade: "Апгрейд подписки",
      subscription_upgrade_modal_text: "Вы собираетесь перейти на другой план подписки. Ваш текущий план будет обновлен, и новая сумма будет рассчитана пропорционально оставшемуся времени до конца текущего расчетного периода. Это означает, что вы заплатите только за оставшееся время, соответствующее новой стоимости плана.\nОсновные моменты:\n- Ваш новый план начнет действовать немедленно.\n- Вы получите дополнительные возможности и преимущества, соответствующие выбранному плану.\n- Пропорциональная сумма будет рассчитана и списана с вашего счета автоматически.",
      select_content_type: "Выберите тип контента",
      create_post: "Создать пост",
      create_reel_scenario: "Создать сценарий рилс",
      select_reel_duration: "Выберите длительность рилса",
      seconds: "секунд",
      reel_result_heading: 'Ваш сценарий на тему - ',
      scenarios: "Сценарии",
      saved_scenarios: 'Сохраненные сценарии',
      scenario_fetch_error: 'Ошибка при получении сценария',
      scenarios_r: "Сценариев",
      contacts: "Контакты",
      ask_question: "Задать вопрос",
      question_subheading: "Пожалуйста, введите ваш вопрос ниже. Мы отправим ответ на ваш email:",
      send: "Отправить",
      question_message: "Ваш запрос отправлен, мы ответим в ближайшее время.",
      question_error: "Произошла ошибка при отправке запроса.",
      subscription_link_error: "Ошибка при переходе к оплате. Попробуйте снова.",
      subscription_cancel_error: "Ошибка при отмене подписки. Попробуйте снова.",
      subscription_renew_error: "Ошибка при возобновлении подписки. Попробуйте снова.",
      subscription_load_error: "Ошибка при проверке подписки. Попробуйте снова.",
      questions_list: "Список вопросов",
      profiles: "Шапка профиля",
      saved_profiles: "Сохраненные шапки профиля",
      generate_profiles: "Генерировать шапку профиля",
      modal_text_profiles: "Генерация шапок профиля доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      projects_panel: "Панель управления проектами",
      project_information_subtext: "Заполните ключевую информацию о вашем проекте.  <br/> На основе этой информации мы сгенерируем контент-идеи",
      post_ideas_information_subtext: "Просматривайте сохранённые идеи, посты и сценарии <br/> Нажав на тему вы сможете сгенерировать пост или сценарий",
      posts_information_subtext: "Список ваших сгенерированных постов <br/> Нажав на тему вы сможете просмотреть пост",
      scenarios_information_subtext: "Список ваших сгенерированных сценариев <br/> Нажав на тему вы сможете просмотреть сценарий",
      profiles_information_subtext: "Список ваших сгенерированных шапок профиля",
      generate_hint: "Генерировать ответ",
      hints_missing_niche_text: "Пожалуйста, заполните поле 'тема или ниша' перед генерацией подсказок.",
      write_for_me: "Напиши за меня",
      profile_analysis: "Анализ профиля",
      analyzing: "Анализируем...",
      upload_analyze: "Анализировать",
      upload_image_for_analysis: "Загрузите скриншот вашего профиля",
      upload_image_for_analysis_text: "Наш инструмент для анализа профиля позволяет вам загрузить скриншот вашего профиля и получить рекомендации по его улучшению. После завершения анализа, результаты автоматически появятся на экране, чтобы вы могли сразу ознакомиться с полезными советами",
      analysis_result: "Советы по улучшению профиля",
      saved_analyses_results: "Сохраненные результаты анализов профиля",
      analyses_information_subtext: "Список ваших сохранённых анализов профиля",
      modal_text_analyses: "Анализ профиля доступен только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      profile_generation_heading: "Генерация шапки профиля Instagram",
      profile_generation_text: "С нашим инструментом вы можете быстро и легко создать идеальную шапку для вашего профиля Instagram, используя данные вашего проекта. Просто заполните форму с информацией о вашем проекте и нажмите кнопку 'Генерировать'. Ваша новая шапка будет готова за считанные секунды, предоставляя профессиональный и привлекательный вид вашему профилю.",
      profile_generated: "Сгенерированная шапка профиля",
      custom_theme_heading: "Генерация поста по вашей теме",
      custom_theme_text: 'Введите текст, описывающий основную тему вашего поста, например: "Запуск нового продукта". В выпадающем списке выберите нужный язык. Нажмите кнопку "Генерировать пост". Вы будете перенаправлены на страницу с готовым постом, где сможете его просмотреть, скопировать и использовать в вашем профиле.',
      custom_theme_label: "Введите текст, описывающий основную тему вашего поста",
      enter_theme: "Напишите тему",
      theme_required_error: "Обязательно напишите тему",
      generate_post: "Генерировать пост",
      modal_text_custom_theme: "Генерация постов по своей теме доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      custom_scenario_theme_heading: "Генерация сценария по вашей теме",
      custom_scenario_theme_text: 'Введите текст, описывающий основную тему вашего сценария. Это может быть что угодно, от демонстрации продукта до короткого гайда. В выпадающем списке выберите нужный язык и выберите продолжительность видео. Нажмите кнопку "Генерировать сценарий". Вы будете перенаправлены на страницу с готовым сценарием, где сможете его просмотреть, скопировать и использовать в вашем профиле.',
      custom_scenario_theme_label: "Введите текст, описывающий основную тему вашего сценария",
      generate_scenario: "Генерировать сценарий",
      modal_text_custom_scenario_theme: "Генерация сценариев по своей теме доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      email_required: "Введите емаил",
      password_required: "Введите пароль",
      user_not_found: "Пользователь с таким емаил не найден",
      invalid_credentials: "Вы ввели неправильный пароль",
      fullname_required: "Введите имя",
      user_already_exists: "Пользователь с таким емаилом уже существует",
      email_invalid: "Неправильный формат емаила",
      forgot_password: "Забыли пароли? ",
      recover: "Восстановить",
      password_recovery: "Восстановление пароля",
      enter_email_to_continue: "Введите емаил, чтобы продолжить",
      enter_new_password_to_continue: "Введите новый пароль, чтобы продолжить",
      enter_new_password: "Введите новый пароль",
      new_password_confirm: "Повторите новый пароль",
      save: "Сохранить",
      check_your_email_for_instructions: "Пожалуйста, проверьте свою электронную почту для получения инструкций по сбросу. Это может занять несколько минут.",
      email_not_found: "Адрес электронной почты не найден. Пожалуйста, проверьте и попробуйте снова.",
      password_changed_continue_to_login: "Пароль успешно сброшен. Теперь вы можете войти с новым паролем.",
      link_is_inactive_generate_new: "Ссылка недействительна или истек срок ее действия. Попробуйте сгенерировать новую ссылку.",
      passwords_do_not_match: "Пароли не совпадают",
      remember_password: "Вспомнили пароль? ",
      days: "дней",
      hours: "часов",
      minutes: "минут",
      seconds: "секунд",
      until_travel: "До путешествия в страну Нейросетей осталось",
      game: "Игра",
      enter_game: "Войти в игру",
      event_started: "Событие началось",
      admin_courses: "Админка курсов",
      project_form_saved: "Проект сохранен",
      sent: "Отправлено",
      modal_text_no_more_posts: "Вы достигли лимита постов. Дальнейшая генерация доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      modal_text_no_more_scenarios: "Вы достигли лимита сценариев. Дальнейшая генерация доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      storytelling: "Сторителлинг",
      storytelling_r: "Сторителлинга",
      saved_stories: "Сохраненные сторис",
      stories_information_subtext: "Список ваших сгенерированных сторис <br/> Нажав на тему вы сможете просмотреть сторис",
      custom_story_theme_heading: "Генерация сторис по вашим данным",
      custom_story_theme_text: 'Сторителлинг предназначен для создания структурированных и целенаправленных историй для различных целей, таких как продажа продукта или услуги, деление личной историей, или образовательные сторис. Нажмите кнопку "Генерировать историю". Вы будете перенаправлены на страницу с готовой историей, где сможете ее просмотреть, скопировать и использовать в вашем профиле.',
      generate_story: "Генерировать историю",
      save_story_form: "Сохранить форму сторителлинга",
      form_story_goal_label: "Выбор цель сторителлинга",
      form_story_goal_text: "Выбрать из списка (продажа продукта или услуги, поделиться личной историей, образовательные сторис)",
      form_story_theme_label: "Основная тема или идея истории",
      form_story_theme_text: "Какая основная тема или идея вашей истории? Например, личный опыт, история успеха клиента, процесс создания продукта.",
      form_story_character_label: "Главные герои истории",
      form_story_character_text: "Кто является главным героем вашей истории? (например, вы, ваш клиент, ваш продукт) Каковы ключевые характеристики этого персонажа? (например, возраст, профессия, личные качества)",
      form_story_problem_label: "Проблема, с которой сталкивается главный герой в начале истории",
      form_story_problem_text: "С какой проблемой сталкивается главный герой в начале истории?",
      form_story_solution_label: "Предложенное решение проблемы",
      form_story_solution_text: "Какое решение предложил ваш бренд, продукт или вы?",
      form_story_result_label: "Описание изменений после использования предложенного решения",
      form_story_result_text: "Как изменилась ситуация после использования вашего решения? Каковы конкретные результаты?",
      form_story_emotions_label: "Эмоции, которые вы хотите вызвать у аудитории",
      form_story_emotions_text: "Какие эмоции вы хотите вызвать у аудитории? (например, вдохновение, радость, любопытство)",
      form_story_unique_moments_label: "Включение уникальных или интересных деталей в историю",
      form_story_unique_moments_text: "Есть ли какие-то уникальные или интересные детали, которые вы хотели бы включить в историю?",
      form_story_cta_label: "Желаемое действие аудитории после прочтения истории",
      form_story_cta_text: "Какое действие вы хотите, чтобы совершила аудитория после прочтения вашей истории? (например, перейти на сайт, купить продукт, подписаться на рассылку, написать в директ)",
      form_story_qty_label: "Какое количество сторис необходимо для полного рассказа истории",
      form_story_qty_text: "Оракул советует выбрать между 5 и 7",
      form_story_language_label: "Выберите язык",
      form_story_language_text: "Здесь вы можете выбрать язык, на котором будет создан контент",
      story_goal_select_title: "Выберите цель",
      story_goal_select_1: "Продажа продукта или услуги",
      story_goal_select_2: "Поделиться личной историей",
      story_goal_select_3: "Образовательные сторис",
      story_result_heading: 'Ваш сторителлинг на тему - ',
      story_form_saved: "Форма сторителлинга сохранена",
      unable_to_save_project: "Не удалось сохранить проект.",
      unable_to_save_story: "Не удалось сохранить форму сторителлинга.",
      saving: "Сохранение...",
      story_fetch_error: 'Ошибка при получении сторителлинга',
      hints_missing_story_goal_text: "Пожалуйста, выберите цель сторителлинга перед генерацией подсказок.",
      modal_text_story: "Генерация сторителлинга доступна только на платных планах. Перейдите на один из них, чтобы использовать эту функцию.",
      form_story_theme_required: "Заполните тему сторителлинга",
      incorrect_code: "Неверный код. Пожалуйста, введите другой код.",
      thanks_answer_accept_awaiting: "Спасибо! Ваш ответ ожидает подтверждения!",
      thanks_answer_accepted: "Спасибо! Ваш ответ принят! ",
      you_received: "Вам зачислено",
      received_points: "очков.",
      your_answer: "Ваш ответ:",
      see_screenshot: "Посмотреть скриншот",
      feedback_for_quest: "Обратная связь на задание",
      your_answer_for_quest: "Ваш ответ на задание",
      enter_code: "Введите код",
      answer_text: "Текст ответа",
      screenshot: "Скриншот",
      quests: "Задания",
      lessons: "Уроки",
      to_access_education: "Для доступа к урокам необходимо приобрести платный план",
      you_earned: "Вы заработали",
      brain_points: "брейн поинтов",
      balance: "Баланс",
      landing_top_heading: "Раскройте свой потенциал создания контента с CopyBrain",
      landing_top_subheading: "Создание контента на основе ИИ и образовательные квесты для повышения вашего уровня в социальных сетях",
      landing_get_started: "НАЧАТЬ БЕСПЛАТНО",
      landing_what_can_heading: "Что может сделать для вас CopyBrain?",
      landing_what_can_subheading: "Трансформируйте свою стратегию в социальных сетях с помощью функций на основе ИИ",
      landing_what_can_item_1_heading: "Генерация идей для вовлекающего контента",
      landing_what_can_item_1_text: "Трудно найти новые идеи для контента? ИИ CopyBrain генерирует свежие, увлекательные идеи контента, адаптированные под вашу аудиторию.",
      landing_what_can_item_2_heading: "Создание идеальных постов, рилсов и сторис",
      landing_what_can_item_2_text: "Создавайте увлекательный контент для социальных сетей легко с помощью нашего инструмента генерации контента на основе ИИ.",
      landing_what_can_item_3_heading: "Совершенствуйте биографию вашего профиля",
      landing_what_can_item_3_text: "Создавайте идеальную биографию для ваших профилей в социальных сетях с помощью предложений, сгенерированных ИИ.",
      landing_what_can_item_4_heading: "Анализируйте свой профиль с помощью скриншота",
      landing_what_can_item_4_text: "Загрузите скриншот вашего профиля в Instagram и получите практические рекомендации по его улучшению.",
      landing_learn_earn_heading: "Учитесь и зарабатывайте с образовательными квестами CopyBrain",
      landing_learn_earn_text: "Представляем наш новый образовательный раздел! Выполняйте образовательные квесты, собирайте очки и обменивайте их на бонусы внутри CopyBrain. Улучшайте свои навыки в социальных сетях, весело проводя время.",
      landing_how_heading: "Как работает CopyBrain",
      landing_how_item_1_heading: "Зарегистрируйтесь",
      landing_how_item_1_text: "Создайте бесплатную учетную запись, чтобы начать.",
      landing_how_item_2_heading: "Генерируйте контент",
      landing_how_item_2_text: "Используйте наши инструменты на основе ИИ для генерации идей контента, постов в социальных сетях и многого другого.",
      landing_how_item_3_heading: "Улучшайте и учитесь",
      landing_how_item_3_text: "Выполняйте образовательные квесты, чтобы улучшить свои навыки и заработать награды.",
      landing_how_item_4_heading: "Анализируйте и улучшайте",
      landing_how_item_4_text: "Загружайте скриншоты профиля для глубокого анализа и получения практических рекомендаций.",
      landing_pricing_heading: "Планы тарифов",
      landing_pricing_text: "Выберите идеальный план для себя",
      landing_pricing_item_1_heading: "Бесплатный",
      landing_pricing_item_2_heading: "Начальный",
      landing_pricing_item_3_heading: "Профессиональный",
      landing_pricing_item_4_heading: "Бизнес",
      landing_ready_heading: "Готовы повысить свою социальную медиа-присутствие?",
      access_to_quests: "доступ к образовательным квестам",
      month: "в месяц",
      select_reel_format: "Выберите формат вашего видео",
      select_reel_cta: "Выберите призыв к действию",
      generate_reel_writing: "Генерировать подпись к рилс?",
      thematic_selection: "Тематическая подборка",
      useful_lifehacks: "Полезные лайфхаки",
      educational_video: "Образовательное видео",
      success_story: "История успеха",
      product_demonstration: "Демонстрация продуктов",
      life_stories_and_funny_cases: "Истории из жизни и забавные случаи",
      before_after: "До-после",
      scenes_with_characters: "Сценки с персонажами",
      text_on_background: "Текст на фоне",
      storytelling: "Сторителлинг",
      talking_head: "Говорящая голова",
      subscribe: "Подписывайся",
      more_in_highlights: "Подробнее в хайлайты",
      more_useful_information_in_profile: "В профиле больше полезной информации",
      write_the_code_word_in_comments_to_receive: "Напиши кодовое слово в комменатриях, чтобы получить",
      send_the_code_word_in_direct_to_receive: "Отправь кодовое слово в директ, чтобы получить",
      select_reel_settings: "Выберите настройки рилса",
      untitled: "Без названия",
      select_country: "Выберите вашу страну",
      supported_by: "Нас поддерживают",
      created_by: "Создано",
      form_text_customisation_label: "Персонализация",
      form_text_customisation_text: "Пожалуйста, загрузите образец вашего письма. COPY BRAIN использует его для подбора стиля, тона и структуры генерируемого текста в соответствии с вашим уникальным стилем письма. Убедитесь, что образец отражает ваш обычный стиль письма.",
      hide: "Скрыть",
      show_full: "Развернуть полностью",
      select_social: "Для какой социальной сети пост?",
      select_post_settings: "Выберите настройки поста",
      post_adapted_for: "Пост адаптирован для",
      year: "Год",
      landing_pricing_text_new: "Один план для всех",
      landing_team_heading: "Команда",
    },
  },
  lv: {
    translation: {
      welcome: "Laipni lūdzam CopyBrain!",
      description: "CopyBrain ir pakalpojums radošu sociālie tīklu ierakstu ideju ģenerēšanai, izmantojot mākslīgo intelektu",
      description_2: "Sāciet veidot radošus ierakstus sava biznesa vajadzībām, izmantojot mākslīgā intelekta spēku",
      signUp: "Reģistrēties",
      login: "Pieslēgties",
      logout: "Iziet",
      generate: "Ģenerēt tēmas",
      form_project_name_label: 'Projekta nosaukums',
      form_project_name_text: 'Šī informācija ir priekš jums',
      form_niche_label: "Kāds ir jūsu konta galvenais temats vai niša?",
      form_niche_text: "Piemēram, mode, ceļojumi, tehnoloģijas, fitnesa, kulinārija, izglītība",
      form_audience_label: "Vecums, dzimums, intereses, ģeogrāfiskais izvietojums",
      form_audience_text: "Kas ir jūsu mērķauditorija?",
      form_audience_characteristics_label: "Kādas ir jūsu mērķauditorijas īpašības?",
      form_audience_characteristics_text: "Vai ir konkrētas problēmas, sāpes vai jautājumi, kas viņiem ir īpaši svarīgi?",
      form_content_goal_label: "Kādi ir jūsu mērķi attiecībā uz saturu?",
      form_content_goal_select_title: "Izvēlieties mērķi",
      form_content_goal_select_1: "Palielināt iesaisti",
      form_content_goal_select_2: "Palielināt sekotāju skaitu",
      form_content_goal_select_3: "Pārdot produktus vai pakalpojumus",
      form_content_goal_select_4: "Izglītojošs saturs",
      form_text_tone_label: "Kāds ir vēlamais ieraksta stils un tonis?",
      form_text_tone_select_title: "Izvēlieties stilu un toni",
      form_text_tone_select_1: "Informācijas",
      form_text_tone_select_2: "Iedvesmojošs",
      form_text_tone_select_3: "Pārliecinošs",
      form_text_tone_select_4: "Draudzīgs",
      form_product_service_label: "Par kādu konkrētu produktu vai pakalpojumu jūs runāsiet?",
      form_product_service_text: "Ja ir, sniedziet detalizētu aprakstu (piemēram, formāts, ilgums, lietu izpēte, atsauksmes, izmaksas)",
      select_theme_to_generate_post: "Izvēlieties tēmu ieraksta ģenerēšanai",
      generating: "Ģenerēšana...",
      generate_themes: "Ģenerēt tēmas",
      themes_form_heading: "Sociālo mediju ierakstu tēmu ģenerators",
      text_copied: "Teksts ir nokopēts!",
      copy_text: "Kopēt tekstu",
      error_while_processing: "Apstrādes laikā radās kļūda: ",
      back: "Atpakaļ",
      post_result_heading: "Jūsu ieraksts par tēmu - ",
      select_language: "Izvēlieties valodu",
      select_language_text: "Šeit jūs varat izvēlēties valodu, kurā tiks radīts saturs",
      in_latvian: "Latviešu",
      in_english: "Angļu",
      in_russian: "Krievu",
      in_spanish: "Spāņu",
      in_italian: "Itāļu",
      admin_panel: 'Administrēšanas panelis',
      project: 'Projekts',
      projects: 'Projekti',
      projects_r: 'Projekti',
      login_heading: 'Ieiet CopyBrain',
      login_subheading: 'Ieiet, lai turpinātu',
      signup_heading: 'Laipni lūdzam CopyBrain',
      signup_subheading: 'Sāciet veidot radošus ierakstus tūlīt',
      email: 'E-pasts',
      password: 'Parole',
      full_name: 'Pilns vārds',
      already_signed_up: 'Jau reģistrējies? ',
      dont_have_account: 'Vēl nav konta? ',
      log_in_here: 'Ieiet šeit',
      please_register: 'Reģistrējieties ',
      projects_loading: "Projektu ielāde...",
      projects_not_found: 'Projekti nav atrasti. Jūs varat izveidot jaunu projektu.',
      your_projects: 'Jūsu Projekti',
      ideas: 'Idejas',
      posts: 'Ieraksti',
      posts_r: 'Ieraksti',
      reels: 'Rīli',
      saved_posts_ideas: 'Saglabātās idejas ierakstiem',
      saved_posts: 'Saglabātās ieraksti',
      information_about_project: 'Informācija par projektu',
      save_project: 'Saglabāt projektu',
      registration_error: 'Reģistrācijas laikā radās kļūda. Lūdzu, mēģiniet vēlreiz.',
      login_error: 'Pieslēgšanās laikā radās kļūda. Lūdzu, mēģiniet vēlreiz.',
      users_fetch_error: 'Kļūda, iegūstot lietotāju sarakstu.',
      projects_fetch_error: 'Kļūda, iegūstot projektu sarakstu.',
      themes_fetch_error: 'Kļūda, iegūstot tēmu sarakstu.',
      post_fetch_error: 'Kļūda, iegūstot ierakstu.',
      profile: "Profils",
      change_password: "Mainīt paroli",
      new_password: "Jauna parole",
      password_change_success: "Parole veiksmīgi nomainīta",
      error_changing_password: "Kļūda, mainot paroli",
      your_details: "Jūsu dati",
      current_plan: "Izvēlētais tarifs",
      free_plan: "Bezmaksas",
      unlimited: "Neierobežoti",
      themes: "Tēmas",
      themes_r: "Tēmas",
      month: "mēnesis",
      choose: "Izvēlēties",
      thank_you_for_your_purchase: "Paldies par pirkumu!",
      your_subscription_has_been_activated: "Jūsu abonements ir aktivizēts",
      start_generating_projects: "Sākt satura ģenerēšanu",
      subscription_expires_at: "Abonements apmaksāts līdz",
      billing: "Apmaksa",
      modal_heading_plan_needed: "Paplašinātas funkcijas",
      modal_text_projects: "Lai izveidotu jaunu projektu, jums jāpāriet uz kādu no maksas plāniem. Tas jums nodrošinās piekļuvi paplašinātām funkcijām un iespēju pārvaldīt vairākus projektus.",
      modal_text_themes: "Tēmu ģenerēšana ir pieejama tikai maksas plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      continue_to_payment: "Turpināt uz maksājumu",
      cancel_subscription: "Atcelt abonementu",
      confirm_cancellation: "Apstiprināt atcelšanu",
      are_you_sure_cancel_subscription: "Vai esat pārliecināts, ka vēlaties atcelt savu abonementu? Jūsu abonements paliks aktīvs līdz pašreizējā apmaksātā perioda beigām.",
      confirm: "Apstiprināt",
      cancel: "Atcelt",
      subscription_canceled: "Abonements atcelts",
      subscription_renew: "Atjaunot abonementu",
      confirm_renew: "Atjaunot abonementu",
      are_you_sure_renew_subscription: "Vai esat pārliecināts, ka vēlaties atjaunot savu abonementu? Tas ļaus jums atkal iegūt piekļuvi visām plāna priekšrocībām, ieskaitot neierobežotu tēmu un ierakstu izveidi.",
      subscription_plan_will_be_selected: "Plāns tiks izvēlēts pēc abonementa perioda beigām.",
      subscription_upgrade: "Abonementa jaunināšana",
      subscription_upgrade_modal_text: "Jūs gatavojaties pāriet uz citu abonēšanas plānu. Jūsu pašreizējais plāns tiks jaunināts, un jaunā summa tiks aprēķināta proporcionāli atlikušajam laikam līdz pašreizējā norēķinu perioda beigām. Tas nozīmē, ka jūs maksāsiet tikai par atlikušajām dienām atbilstoši jaunā plāna izmaksām.\nGalvenie punkti:\n- Jūsu jaunais plāns stāsies spēkā nekavējoties.\n- Jūs saņemsiet papildu funkcijas un priekšrocības atbilstoši izvēlētajam plānam.\n- Proporcionālā summa tiks aprēķināta un automātiski iekasēta no jūsu konta.",
      select_content_type: "Izvēlieties satura veidu",
      create_post: "Izveidot ierakstu",
      create_reel_scenario: "Izveidot rīla scenāriju",
      select_reel_duration: "Izvēlieties rīla ilgumu",
      seconds: "sekundes",
      reel_result_heading: "Jūsu scenārijs par tēmu - ",
      scenarios: "Scenāriji",
      saved_scenarios: "Saglabātie scenāriji",
      scenario_fetch_error: "Kļūda, iegūstot scenāriju",
      scenarios_r: "Scenāriji",
      contacts: "Kontakti",
      ask_question: "Uzdot jautājumu",
      question_subheading: "Lūdzu, ievadiet savu jautājumu zemāk. Mēs nosūtīsim atbildi uz jūsu e-pastu:",
      send: "Nosūtīt",
      question_message: "Jūsu pieprasījums ir nosūtīts, mēs atbildēsim pēc iespējas ātrāk.",
      subscription_link_error: "Kļūda, pārejot uz maksājumu. Lūdzu, mēģiniet vēlreiz.",
      subscription_cancel_error: "Kļūda, atceļot abonementu. Lūdzu, mēģiniet vēlreiz.",
      subscription_renew_error: "Kļūda, atjaunojot abonementu. Lūdzu, mēģiniet vēlreiz.",
      subscription_load_error: "Kļūda, pārbaudot abonementu. Lūdzu, mēģiniet vēlreiz.",
      questions_list: "Jautājumu saraksts",
      profiles: "Profila virsraksts",
      saved_profiles: "Saglabātie profila virsraksti",
      generate_profiles: "Ģenerēt profila virsrakstu",
      modal_text_profiles: "Profila virsrakstu ģenerēšana ir pieejama tikai maksas plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      projects_panel: "Projektu pārvaldības panelis",
      project_information_subtext: "Aizpildiet galveno informāciju par savu projektu. <br/> Balstoties uz šo informāciju, mēs ģenerēsim satura idejas.",
      post_ideas_information_subtext: "Pārlūkojiet saglabātās idejas, ierakstus un scenārijus. <br/> Noklikšķinot uz tēmas, jūs varēsiet ģenerēt ierakstu vai scenāriju.",
      posts_information_subtext: "Jūsu ģenerēto ierakstu saraksts. <br/> Noklikšķinot uz tēmas, jūs varēsiet apskatīt ierakstu.",
      scenarios_information_subtext: "Jūsu ģenerēto scenāriju saraksts. <br/> Noklikšķinot uz tēmas, jūs varēsiet apskatīt scenāriju.",
      profiles_information_subtext: "Jūsu ģenerēto profila virsrakstu saraksts.",
      generate_hint: "Ģenerēt atbildi",
      hints_missing_niche_text: "Lūdzu, aizpildiet lauku 'temats vai niša' pirms padomu ģenerēšanas.",
      write_for_me: "Uzraksti manā vietā",
      profile_analysis: "Profila analīze",
      analyzing: "Analizējam...",
      upload_analyze: "Augšupielādēt un analizēt",
      upload_image_for_analysis: "Augšupielādējiet sava profila ekrānuzņēmumu",
      upload_image_for_analysis_text: "Mūsu profila analīzes rīks ļauj jums augšupielādēt sava profila ekrānuzņēmumu un saņemt ieteikumus tā uzlabošanai. Kad analīze būs pabeigta, rezultāti automātiski parādīsies ekrānā, lai jūs varētu uzreiz iepazīties ar noderīgiem padomiem.",
      analysis_result: "Profila uzlabošanas padomi",
      saved_analyses_results: "Saglabātie profila analīzes rezultāti",
      analyses_information_subtext: "Jūsu saglabāto profila analīžu saraksts",
      modal_text_analyses: "Profila analīze ir pieejama tikai maksas plānos. Lai izmantotu šo funkciju, pārejiet uz vienu no tiem.",
      profile_generation_heading: "Instagram profila galvenes ģenerēšana",
      profile_generation_text: "Ar mūsu rīku jūs varat ātri un viegli izveidot ideālu galveni savam Instagram profilam, izmantojot sava projekta datus. Vienkārši aizpildiet veidlapu ar informāciju par savu projektu un noklikšķiniet uz 'Ģenerēt'. Jūsu jaunā galvene būs gatava dažu sekunžu laikā, nodrošinot profesionālu un pievilcīgu izskatu jūsu profilam.",
      profile_generated: "Ģenerēta profila galvene",
      custom_theme_heading: "Ģenerēt ierakstu pēc jūsu tēmas",
      custom_theme_text: 'Ievadiet tekstu, kas apraksta jūsu ieraksta galveno tēmu, piemēram: "Jauna produkta palaišana". Izvēlieties vēlamo valodu no nolaižamās izvēlnes. Noklikšķiniet uz pogas "Ģenerēt ierakstu". Jūs tiksiet pāradresēts uz lapu ar gatavo ierakstu, kur varēsiet to apskatīt, kopēt un izmantot savā profilā.',
      custom_theme_label: "Ievadiet tekstu, kas apraksta jūsu ieraksta galveno tēmu",
      enter_theme: "Ievadiet tēmu",
      theme_required_error: "Tēma ir obligāta",
      generate_post: "Ģenerēt ierakstu",
      modal_text_custom_theme: "Ierakstu ģenerēšana pēc savas tēmas ir pieejama tikai apmaksātajos plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      custom_scenario_theme_heading: "Ģenerēt scenāriju pēc savas tēmas",
      custom_scenario_theme_text: 'Ievadiet tekstu, kas apraksta jūsu scenārija galveno tēmu. Tas var būt jebkas, sākot no produkta demonstrācijas līdz īsam ceļvedim. Nolaižamajā izvēlnē izvēlieties vēlamo valodu un izvēlieties video ilgumu. Nospiediet pogu "Ģenerēt scenāriju". Jūs tiksiet novirzīts uz lapu ar pabeigto scenāriju, kur varēsiet to skatīt, kopēt un izmantot savā profilā.',
      custom_scenario_theme_label: "Ievadiet tekstu, kas apraksta jūsu scenārija galveno tēmu",
      generate_scenario: "Ģenerēt scenāriju",
      modal_text_custom_scenario_theme: "Scenāriju ģenerēšana pēc savas tēmas ir pieejama tikai apmaksātajos plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      email_required: "Ievadiet savu e-pastu",
      password_required: "Ievadiet savu paroli",
      user_not_found: "Lietotājs ar šo e-pastu nav atrasts",
      invalid_credentials: "Jūs ievadījāt nepareizu paroli",
      fullname_required: "Ievadiet savu vārdu un uzvārdu",
      user_already_exists: "Lietotājs ar šo e-pastu jau pastāv",
      email_invalid: "Nederīgs e-pasta formāts",
      forgot_password: "Aizmirsāt paroli? ",
      recover: "Atjaunot",
      password_recovery: "Paroles atjaunošana",
      enter_email_to_continue: "Ievadiet savu e-pastu, lai turpinātu",
      enter_new_password_to_continue: "Ievadiet jauno paroli, lai turpinātu",
      enter_new_password: "Ievadiet jauno paroli",
      new_password_confirm: "Apstipriniet jauno paroli",
      save: "Saglabāt",
      check_your_email_for_instructions: "Lūdzu, pārbaudiet savu e-pastu, lai saņemtu instrukcijas par paroles atjaunošanu. Tas var aizņemt dažas minūtes.",
      email_not_found: "E-pasta adrese nav atrasta. Lūdzu, pārbaudiet un mēģiniet vēlreiz.",
      password_changed_continue_to_login: "Parole veiksmīgi atiestatīta. Tagad varat pieteikties ar jauno paroli.",
      link_is_inactive_generate_new: "Saite ir neaktīva vai tās derīguma termiņš ir beidzies. Mēģiniet ģenerēt jaunu saiti.",
      passwords_do_not_match: "Paroles nesakrīt",
      remember_password: "Atcerējāties paroli?",
      days: "dienas",
      hours: "stundas",
      minutes: "minūtes",
      seconds: "sekundes",
      until_travel: "Līdz ceļojumam uz Neirotīklu zemi palicis",
      game: "Spēle",
      enter_game: "Ieej spēlē",
      event_started: "Pasākums ir sācies",
      admin_courses: "Kursu admin panelis",
      project_form_saved: "Projekts saglabāts",
      sent: "Nosūtīts",
      modal_text_no_more_posts: "Jūs esat sasniedzis ierakstu limitu. Turpmāka ģenerēšana ir pieejama tikai apmaksātos plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      modal_text_no_more_scenarios: "Jūs esat sasniedzis scenāriju limitu. Turpmāka ģenerēšana ir pieejama tikai apmaksātos plānos. Pārejiet uz vienu no tiem, lai izmantotu šo funkciju.",
      storytelling: "Stāstu stāstīšana",
      storytelling_r: "Stāstījumi",
      saved_stories: "Saglabātie stāsti",
      stories_information_subtext: "Jūsu ģenerēto stāstu saraksts <br/> Noklikšķiniet uz tēmas, lai apskatītu stāstu",
      custom_story_theme_heading: "Ģenerēt stāstus ar jūsu datiem",
      custom_story_theme_text: "Stāstu stāstīšana ir paredzēta strukturētu un mērķtiecīgu stāstu veidošanai dažādiem mērķiem, piemēram, produkta vai pakalpojuma pārdošanai, personīgā stāsta dalīšanai vai izglītojošiem stāstiem. Noklikšķiniet uz pogas 'Ģenerēt stāstu'. Jūs tiksiet novirzīts uz lapu ar pabeigto stāstu, kur varēsiet to apskatīt, kopēt un izmantot savā profilā.",
      generate_story: "Ģenerēt stāstu",
      save_story_form: "Saglabāt stāstu stāstīšanas formu",
      form_story_goal_label: "Izvēlēties stāstu stāstīšanas mērķi",
      form_story_goal_text: "Izvēlieties no saraksta (produkta vai pakalpojuma pārdošana, personīgā stāsta dalīšana, izglītojoši stāsti)",
      form_story_theme_label: "Galvenā stāsta tēma vai ideja",
      form_story_theme_text: "Kāda ir jūsu stāsta galvenā tēma vai ideja? Piemēram, personīgā pieredze, klienta panākumu stāsts, produkta izveides process.",
      form_story_character_label: "Galvenie stāsta varoņi",
      form_story_character_text: "Kas ir jūsu stāsta galvenais varonis? (piemēram, jūs, jūsu klients, jūsu produkts) Kādas ir šī varoņa galvenās īpašības? (piemēram, vecums, profesija, personīgās īpašības)",
      form_story_problem_label: "Problēma, ar kuru saskaras galvenais varonis stāsta sākumā",
      form_story_problem_text: "Ar kādu problēmu saskaras galvenais varonis stāsta sākumā?",
      form_story_solution_label: "Piedāvātais problēmas risinājums",
      form_story_solution_text: "Kāds risinājums tika piedāvāts no jūsu zīmola, produkta vai jums?",
      form_story_result_label: "Izmaiņu apraksts pēc piedāvātā risinājuma izmantošanas",
      form_story_result_text: "Kā situācija mainījās pēc jūsu risinājuma izmantošanas? Kādi ir konkrētie rezultāti?",
      form_story_emotions_label: "Emocijas, kuras vēlaties izraisīt auditorijā",
      form_story_emotions_text: "Kādas emocijas vēlaties izraisīt auditorijā? (piemēram, iedvesma, prieks, zinātkāre)",
      form_story_unique_moments_label: "Unikālu vai interesantu detaļu iekļaušana stāstā",
      form_story_unique_moments_text: "Vai ir kādas unikālas vai interesantas detaļas, kuras vēlaties iekļaut stāstā?",
      form_story_cta_label: "Vēlamā darbība auditorijai pēc stāsta izlasīšanas",
      form_story_cta_text: "Kāda darbība vēlaties, lai auditorija veic pēc jūsu stāsta izlasīšanas? (piemēram, apmeklēt vietni, iegādāties produktu, abonēt jaunumu vēstuli, uzrakstīt tiešu ziņojumu)",
      form_story_qty_label: "Stāstu skaits, kas nepieciešams pilnam stāstam",
      form_story_qty_text: "Orākuls iesaka izvēlēties starp 5 un 7",
      form_story_language_label: "Izvēlieties valodu",
      form_story_language_text: "Šeit varat izvēlēties valodu, kurā tiks veidots saturs",
      story_goal_select_title: "Izvēlieties mērķi",
      story_goal_select_1: "Produkta vai pakalpojuma pārdošana",
      story_goal_select_2: "Personīgā stāsta dalīšana",
      story_goal_select_3: "Izglītojoši stāsti",
      story_result_heading: 'Jūsu stāstu stāstīšana par tēmu - ',
      story_form_saved: "Stāstu stāstīšanas forma saglabāta",
      unable_to_save_project: "Neizdevās saglabāt projektu.",
      unable_to_save_story: "Neizdevās saglabāt stāstu stāstīšanas formu.",
      saving: "Saglabāšana...",
      story_fetch_error: 'Kļūda, iegūstot stāstu stāstīšanu',
      hints_missing_story_goal_text: "Lūdzu, izvēlieties stāstu stāstīšanas mērķi pirms ģenerēšanas norādēm.",
      modal_text_story: "Stāstu ģenerēšana ir pieejama tikai maksas plānos. Pārejiet uz kādu no tiem, lai izmantotu šo funkciju.",
      form_story_theme_required: "Lūdzu, aizpildiet stāstu tēmu",
      incorrect_code: "Nepareizs kods. Lūdzu, ievadiet citu kodu.",
      thanks_answer_accept_awaiting: "Paldies! Jūsu atbilde gaida apstiprinājumu!",
      thanks_answer_accepted: "Paldies! Jūsu atbilde ir pieņemta! ",
      you_received: "Jūs saņēmāt",
      received_points: "punkti.",
      your_answer: "Jūsu atbilde:",
      see_screenshot: "Skatīt ekrānuzņēmumu",
      feedback_for_quest: "Atsauksmes par uzdevumu",
      your_answer_for_quest: "Jūsu atbilde uz uzdevumu",
      enter_code: "Ievadiet kodu",
      answer_text: "Atbildes teksts",
      screenshot: "Ekrānuzņēmums",
      quests: "Uzdevumi",
      lessons: "Stundas",
      to_access_education: "Lai piekļūtu stundām, ir jāiegādājas maksas plāns",
      you_earned: "Jūs nopelnījāt",
      brain_points: "brain points",
      balance: "Bilance",
      landing_top_heading: "Atklājiet savu satura veidošanas potenciālu ar CopyBrain",
      landing_top_subheading: "Ar mākslīgo intelektu darbināta satura ģenerēšana un izglītojoši uzdevumi, lai paaugstinātu jūsu līmeni sociālajos tīklos",
      landing_get_started: "SĀKT BEZ MAKSAS",
      landing_what_can_heading: "Ko CopyBrain var darīt jums?",
      landing_what_can_subheading: "Pārveidojiet savu sociālo tīklu stratēģiju ar mākslīgā intelekta funkcijām",
      landing_what_can_item_1_heading: "Ģenerējiet iesaistošas satura idejas",
      landing_what_can_item_1_text: "Grūti atrast jaunas satura idejas? CopyBrain mākslīgais intelekts ģenerē svaigas, saistošas satura idejas, pielāgotas jūsu auditorijai.",
      landing_what_can_item_2_heading: "Veidojiet perfektus sociālo tīklu ierakstus, rullīšus un stāstus",
      landing_what_can_item_2_text: "Izveidojiet saistošu saturu sociālajiem tīkliem viegli, izmantojot mūsu ar mākslīgo intelektu darbināmo satura ģenerēšanas rīku.",
      landing_what_can_item_3_heading: "Pilnveidojiet sava profila biogrāfiju",
      landing_what_can_item_3_text: "Izveidojiet ideālu biogrāfiju saviem profiliem sociālajos tīklos, izmantojot mākslīgā intelekta radītus ieteikumus.",
      landing_what_can_item_4_heading: "Analizējiet savu profilu ar ekrānuzņēmumu",
      landing_what_can_item_4_text: "Augšupielādējiet sava Instagram profila ekrānuzņēmumu un saņemiet praktiskus ieteikumus, kā to uzlabot.",
      landing_learn_earn_heading: "Mācieties un pelniet ar CopyBrain izglītojošiem uzdevumiem",
      landing_learn_earn_text: "Iepazīstinām ar mūsu jauno izglītības sadaļu! Veiciet izglītojošus uzdevumus, krājiet punktus un apmainiet tos pret bonusiem CopyBrain iekšienē. Uzlabojiet savas sociālo tīklu prasmes, izklaidējoties.",
      landing_how_heading: "Kā darbojas CopyBrain",
      landing_how_item_1_heading: "Reģistrējieties",
      landing_how_item_1_text: "Izveidojiet bezmaksas kontu, lai sāktu.",
      landing_how_item_2_heading: "Ģenerējiet saturu",
      landing_how_item_2_text: "Izmantojiet mūsu ar mākslīgo intelektu darbinātos rīkus, lai ģenerētu satura idejas, sociālo tīklu ierakstus un daudz ko citu.",
      landing_how_item_3_heading: "Uzlabojiet un mācieties",
      landing_how_item_3_text: "Veiciet izglītojošus uzdevumus, lai uzlabotu savas prasmes un nopelnītu atlīdzības.",
      landing_how_item_4_heading: "Analizējiet un uzlabojiet",
      landing_how_item_4_text: "Augšupielādējiet profila ekrānuzņēmumus padziļinātai analīzei un praktiskiem ieteikumiem.",
      landing_pricing_heading: "Cenu plāni",
      landing_pricing_text: "Izvēlieties sev piemērotāko plānu",
      landing_pricing_item_1_heading: "Bezmaksas",
      landing_pricing_item_2_heading: "Iesācējs",
      landing_pricing_item_3_heading: "Pro",
      landing_pricing_item_4_heading: "Bizness",
      landing_ready_heading: "Gatavi paaugstināt savu klātbūtni sociālajos tīklos?",
      access_to_quests: "piekļuve izglītojošiem uzdevumiem",
      month: "mēnesī",
      select_reel_format: "Izvēlieties sava video formātu",
      select_reel_cta: "Izvēlieties aicinājumu uz darbību",
      generate_reel_writing: "Ģenerēt parakstu rīlim?",
      thematic_selection: "Tematiska izlase",
      useful_lifehacks: "Noderīgi padomi",
      educational_video: "Izglītojošs video",
      success_story: "Veiksmes stāsts",
      product_demonstration: "Produkta demonstrācija",
      life_stories_and_funny_cases: "Dzīves stāsti un smieklīgi gadījumi",
      before_after: "Pirms-pēc",
      scenes_with_characters: "Ainas ar varoņiem",
      text_on_background: "Teksts uz fona",
      storytelling: "Stāstu stāstīšana",
      talking_head: "Runājošā galva",
      subscribe: "Abonējiet",
      more_in_highlights: "Vairāk iezīmēs",
      more_useful_information_in_profile: "Profilā vairāk noderīgas informācijas",
      write_the_code_word_in_comments_to_receive: "Uzrakstiet kodu vārdu komentāros, lai saņemtu",
      send_the_code_word_in_direct_to_receive: "Nosūtiet kodu vārdu tiešā veidā, lai saņemtu",
      select_reel_settings: "Izvēlieties rīla iestatījumus",
      untitled: "Bez nosaukuma",
      select_country: "Izvēlieties savu valsti",
      supported_by: "Mūs atbalsta",
      created_by: "Izveidoja",
      form_text_customisation_label: "Personalizācija",
      form_text_customisation_text: "Lūdzu, augšupielādējiet sava rakstības stila paraugu. COPY BRAIN izmantos šo paraugu, lai pielāgotu ģenerētā teksta stilu, toni un struktūru jūsu unikālajam rakstības stilam. Pārliecinieties, ka paraugs atspoguļo jūsu ierasto rakstības stilu.",
      hide: "Paslēpt",
      show_full: "Paplašināt pilnībā",
      select_social: "Kuram sociālajam tīklam paredzēts ieraksts?",
      select_post_settings: "Izvēlieties ieraksta iestatījumus",
      post_adapted_for: "Ieraksts pielāgots",
      year: "Gads",
      landing_pricing_text_new: "Viens plāns visiem",
      landing_team_heading: "Komanda",
    }
  },
  es: {
    translation: {
      welcome: '¡Bienvenido a CopyBrain!',
      description: 'CopyBrain es un servicio para generar ideas creativas para publicaciones en redes sociales utilizando IA',
      description_2: 'Comience a crear publicaciones creativas para su negocio utilizando el poder de la inteligencia artificial',
      signUp: 'Registrarse',
      login: 'Iniciar sesión',
      logout: 'Cerrar sesión',
      generate: 'Generar temas',
      form_project_name_label: 'Nombre del proyecto',
      form_project_name_text: 'Esta información es para usted',
      form_niche_label: '¿Cuál es el tema o nicho principal de su cuenta?',
      form_niche_text: 'Por ejemplo, moda, viajes, tecnología, fitness, cocina, educación',
      form_audience_label: 'Edad, género, intereses, ubicación geográfica',
      form_audience_text: '¿Quién es su audiencia objetivo?',
      form_audience_characteristics_label: '¿Qué características tiene su audiencia objetivo?',
      form_audience_characteristics_text: '¿Hay problemas, dolores o preguntas específicos que sean especialmente importantes para ellos?',
      form_content_goal_label: '¿Cuáles son sus objetivos para su contenido?',
      form_content_goal_select_title: 'Seleccione un objetivo',
      form_content_goal_select_1: 'Incrementar la participación',
      form_content_goal_select_2: 'Aumentar el número de seguidores',
      form_content_goal_select_3: 'Ventas de productos o servicios',
      form_content_goal_select_4: 'Contenido educativo',
      form_text_tone_label: '¿Cuál debe ser el estilo y tono de la publicación?',
      form_text_tone_select_title: 'Seleccione un estilo y tono',
      form_text_tone_select_1: 'Informativo',
      form_text_tone_select_2: 'Inspirador',
      form_text_tone_select_3: 'Persuasivo',
      form_text_tone_select_4: 'Amistoso',
      form_product_service_label: '¿De qué producto o servicio específico se trata?',
      form_product_service_text: 'Si lo hay, proporcione una descripción detallada. (Por ejemplo, formato, duración, casos de estudio, reseñas, precio)',
      select_theme_to_generate_post: 'Seleccione un tema para generar una publicación',
      generating: 'Generando...',
      generate_themes: 'Generar temas',
      themes_form_heading: 'Generador de temas para publicaciones en redes sociales',
      text_copied: '¡Texto copiado!',
      copy_text: 'Copiar texto',
      error_while_processing: 'Se produjo un error al procesar su solicitud: ',
      back: 'Atrás',
      post_result_heading: 'Su publicación sobre el tema - ',
      select_language: 'Seleccionar idioma',
      select_language_text: 'Aquí puede seleccionar el idioma en el que se creará el contenido',
      in_latvian: 'Letón',
      in_english: 'Inglés',
      in_russian: 'Ruso',
      in_spanish: 'Español',
      in_italian: 'Italiano',
      admin_panel: 'Panel de administración',
      project: 'Proyecto',
      projects: 'Proyectos',
      projects_r: 'Proyectos',
      login_heading: 'Iniciar sesión en CopyBrain',
      login_subheading: 'Inicie sesión para continuar',
      signup_heading: 'Bienvenido a CopyBrain',
      signup_subheading: 'Comience a escribir publicaciones creativas ahora mismo',
      email: 'Correo electrónico',
      password: 'Contraseña',
      full_name: 'Nombre completo',
      already_signed_up: '¿Ya está registrado?',
      dont_have_account: '¿Aún no tiene cuenta?',
      log_in_here: 'Inicie sesión aquí',
      please_register: 'Por favor regístrese ',
      projects_loading: 'Cargando proyectos...',
      projects_not_found: 'No se encontraron proyectos. Puede crear un nuevo proyecto.',
      your_projects: 'Sus proyectos',
      ideas: 'Ideas',
      posts: 'Publicaciones',
      posts_r: 'Publicaciones',
      reels: 'Reels',
      saved_posts_ideas: 'Ideas guardadas para publicaciones',
      saved_posts: 'Publicaciones guardadas',
      information_about_project: 'Información sobre el proyecto',
      save_project: 'Guardar proyecto',
      registration_error: 'Se produjo un error al registrarse. Inténtelo de nuevo.',
      login_error: 'Se produjo un error al iniciar sesión. Inténtelo de nuevo.',
      users_fetch_error: 'Error al obtener la lista de usuarios',
      projects_fetch_error: 'Error al obtener la lista de proyectos',
      themes_fetch_error: 'Error al obtener la lista de temas',
      post_fetch_error: 'Error al obtener la publicación',
      profile: "Perfil",
      change_password: "Cambiar contraseña",
      new_password: "Nueva contraseña",
      password_change_success: "Contraseña cambiada exitosamente",
      error_changing_password: "Error al cambiar la contraseña",
      your_details: "Sus datos",
      current_plan: "Plan actual",
      free_plan: "Gratis",
      unlimited: "Ilimitado",
      themes: "Temas",
      themes_r: "Temas",
      month: "mes",
      choose: "Elegir",
      thank_you_for_your_purchase: "¡Gracias por su compra!",
      your_subscription_has_been_activated: "Su suscripción ha sido activada",
      start_generating_projects: "Comenzar a generar contenido",
      subscription_expires_at: "La suscripción vence el",
      billing: "Facturación",
      modal_heading_plan_needed: "Funciones avanzadas",
      modal_text_projects: "Para crear un nuevo proyecto, necesita cambiar a uno de los planes pagos. Esto le dará acceso a funciones avanzadas y la capacidad de gestionar múltiples proyectos.",
      modal_text_themes: "La generación de temas solo está disponible en planes pagos. Cambie a uno de ellos para usar esta función.",
      continue_to_payment: "Continuar con el pago",
      cancel_subscription: "Cancelar suscripción",
      confirm_cancellation: "Confirmar cancelación",
      are_you_sure_cancel_subscription: "¿Está seguro de que desea cancelar la suscripción? Su suscripción permanecerá activa hasta el final del período pagado actual.",
      confirm: "Confirmar",
      cancel: "Cancelar",
      subscription_canceled: "Suscripción cancelada",
      subscription_renew: "Renovar suscripción",
      confirm_renew: "Renovar suscripción",
      are_you_sure_renew_subscription: "¿Está seguro de que desea renovar su suscripción? Esto le permitirá acceder nuevamente a todos los beneficios del plan, incluida la creación ilimitada de temas y publicaciones.",
      subscription_plan_will_be_selected: "El plan será seleccionado después del final del período de suscripción",
      subscription_upgrade: "Mejorar suscripción",
      subscription_upgrade_modal_text: "Está a punto de cambiar a otro plan de suscripción. Su plan actual se actualizará y el nuevo importe se calculará proporcionalmente al tiempo restante del período de facturación actual. Esto significa que solo pagará por el tiempo restante correspondiente al nuevo costo del plan.\nPuntos principales:\n- Su nuevo plan comenzará a funcionar de inmediato.\n- Obtendrá funciones y beneficios adicionales correspondientes al plan seleccionado.\n- El importe proporcional se calculará y se cargará automáticamente a su cuenta.",
      select_content_type: "Seleccione el tipo de contenido",
      create_post: "Crear publicación",
      create_reel_scenario: "Crear guion de reel",
      select_reel_duration: "Seleccione la duración del reel",
      seconds: "segundos",
      reel_result_heading: 'Su guion sobre el tema - ',
      scenarios: "Guiones",
      saved_scenarios: 'Guiones guardados',
      scenario_fetch_error: 'Error al obtener el guion',
      scenarios_r: "Guiones",
      contacts: "Contactos",
      ask_question: "Hacer una pregunta",
      question_subheading: "Por favor, introduzca su pregunta a continuación. Enviaremos la respuesta a su correo electrónico:",
      send: "Enviar",
      question_message: "Su solicitud ha sido enviada, responderemos lo antes posible.",
      question_error: "Se produjo un error al enviar la solicitud.",
      subscription_link_error: "Error al redirigir al pago. Inténtelo de nuevo.",
      subscription_cancel_error: "Error al cancelar la suscripción. Inténtelo de nuevo.",
      subscription_renew_error: "Error al renovar la suscripción. Inténtelo de nuevo.",
      subscription_load_error: "Error al verificar la suscripción. Inténtelo de nuevo.",
      questions_list: "Lista de preguntas",
      profiles: "Biografía",
      saved_profiles: "Biografías guardadas",
      generate_profiles: "Generar biografía",
      modal_text_profiles: "La generación de biografías solo está disponible en planes pagos. Cambie a uno de ellos para usar esta función.",
      projects_panel: "Panel de gestión de proyectos",
      project_information_subtext: "Complete la información clave sobre su proyecto. <br/> Con base en esta información generaremos ideas de contenido.",
      post_ideas_information_subtext: "Revise las ideas, publicaciones y guiones guardados <br/> Al hacer clic en el tema podrá generar una publicación o un guion.",
      posts_information_subtext: "Lista de sus publicaciones generadas <br/> Al hacer clic en el tema podrá ver la publicación.",
      scenarios_information_subtext: "Lista de sus guiones generados <br/> Al hacer clic en el tema podrá ver el guion.",
      profiles_information_subtext: "Lista de sus biografías generadas",
      generate_hint: "Generar respuesta",
      hints_missing_niche_text: "Por favor, complete el campo 'tema o nicho' antes de generar sugerencias.",
      write_for_me: "Escribe por mí",
      profile_analysis: "Análisis de perfil",
      analyzing: "Analizando...",
      upload_analyze: "Analizar",
      upload_image_for_analysis: "Cargue una captura de pantalla de su perfil",
      upload_image_for_analysis_text: "Nuestra herramienta de análisis de perfil le permite cargar una captura de pantalla de su perfil y recibir recomendaciones para mejorarlo. Después de completar el análisis, los resultados aparecerán automáticamente en la pantalla para que pueda revisar los consejos útiles de inmediato.",
      analysis_result: "Consejos para mejorar el perfil",
      saved_analyses_results: "Resultados de análisis de perfil guardados",
      analyses_information_subtext: "Lista de sus análisis de perfil guardados",
      modal_text_analyses: "El análisis de perfil solo está disponible en planes pagos. Cambie a uno de ellos para usar esta función.",
      profile_generation_heading: "Generación de biografía de Instagram",
      profile_generation_text: "Con nuestra herramienta puede crear rápida y fácilmente la biografía perfecta para su perfil de Instagram utilizando los datos de su proyecto. Simplemente complete el formulario con la información de su proyecto y haga clic en el botón 'Generar'. Su nueva biografía estará lista en segundos, proporcionando un aspecto profesional y atractivo a su perfil.",
      profile_generated: "Biografía generada",
      custom_theme_heading: "Generación de publicación según su tema",
      custom_theme_text: 'Ingrese el texto que describe el tema principal de su publicación, por ejemplo: "Lanzamiento de un nuevo producto". En la lista desplegable, seleccione el idioma deseado. Haga clic en el botón "Generar publicación". Será redirigido a la página con la publicación lista, donde podrá revisarla, copiarla y usarla en su perfil.',
      custom_theme_label: "Ingrese el texto que describe el tema principal de su publicación",
      enter_theme: "Ingrese el tema",
      theme_required_error: "Es necesario ingresar un tema",
      generate_post: "Generar publicación",
      modal_text_custom_theme: "La generación de publicaciones según su tema solo está disponible en planes pagos. Cambie a uno de ellos para usar esta función.",
      custom_scenario_theme_heading: "Generación de guion según su tema",
      custom_scenario_theme_text: 'Ingrese el texto que describe el tema principal de su guion. Puede ser cualquier cosa, desde una demostración de producto hasta una guía breve. En la lista desplegable, seleccione el idioma deseado y elija la duración del video. Haga clic en el botón "Generar guion". Será redirigido a la página con el guion listo, donde podrá revisarlo, copiarlo y usarlo en su perfil.',
      custom_scenario_theme_label: "Ingrese el texto que describe el tema principal de su guion",
      generate_scenario: "Generar guion",
      modal_text_custom_scenario_theme: "La generación de guiones según su tema solo está disponible en planes pagos. Cambie a uno de ellos para usar esta función.",
      email_required: "Ingrese el correo electrónico",
      password_required: "Ingrese la contraseña",
      user_not_found: "Usuario no encontrado con ese correo electrónico",
      invalid_credentials: "Ha ingresado una contraseña incorrecta",
      fullname_required: "Ingrese el nombre completo",
      user_already_exists: "Ya existe un usuario con ese correo electrónico",
      email_invalid: "Formato de correo electrónico inválido",
      forgot_password: "¿Olvidó su contraseña?",
      recover: "Recuperar",
      password_recovery: "Recuperación de contraseña",
      enter_email_to_continue: "Ingrese el correo electrónico para continuar",
      enter_new_password_to_continue: "Ingrese la nueva contraseña para continuar",
      enter_new_password: "Ingrese la nueva contraseña",
      new_password_confirm: "Confirme la nueva contraseña",
      save: "Guardar",
      check_your_email_for_instructions: "Por favor, revise su correo electrónico para obtener instrucciones sobre el restablecimiento. Esto puede tardar unos minutos.",
      email_not_found: "Correo electrónico no encontrado. Por favor, verifique e intente nuevamente.",
      password_changed_continue_to_login: "Contraseña restablecida exitosamente. Ahora puede iniciar sesión con la nueva contraseña.",
      link_is_inactive_generate_new: "El enlace no es válido o ha caducado. Intente generar un nuevo enlace.",
      passwords_do_not_match: "Las contraseñas no coinciden",
      remember_password: "¿Recordó su contraseña?",
      days: "días",
      hours: "horas",
      minutes: "minutos",
      seconds: "segundos",
      until_travel: "Hasta el viaje al país de las Redes Neuronales quedan",
      game: "Juego",
      enter_game: "Entrar en el juego",
      event_started: "El evento ha comenzado",
      admin_courses: "Administración de cursos",
      project_form_saved: "¡Proyecto guardado",
      sent: "Enviado",
      modal_text_no_more_posts: "Has alcanzado el límite de publicaciones. La generación adicional solo está disponible en planes de pago. Actualízate a uno de ellos para usar esta función.",
      modal_text_no_more_scenarios: "Has alcanzado el límite de escenarios. La generación adicional solo está disponible en planes de pago. Actualízate a uno de ellos para usar esta función.",
      storytelling: "Narración de historias",
      storytelling_r: "De la narración",
      saved_stories: "Historias guardadas",
      stories_information_subtext: "Lista de tus historias generadas <br/> Haz clic en un tema para ver la historia",
      custom_story_theme_heading: "Generar historias con tus datos",
      custom_story_theme_text: "La narración de historias está diseñada para crear historias estructuradas y con propósito para varios objetivos, como vender un producto o servicio, compartir una historia personal o historias educativas. Haz clic en el botón 'Generar historia'. Serás redirigido a una página con la historia completa, donde podrás verla, copiarla y usarla en tu perfil.",
      generate_story: "Generar historia",
      save_story_form: "Guardar formulario de narración de historias",
      form_story_goal_label: "Seleccionar objetivo de narración de historias",
      form_story_goal_text: "Selecciona de la lista (vender un producto o servicio, compartir una historia personal, historias educativas)",
      form_story_theme_label: "Tema principal o idea de la historia",
      form_story_theme_text: "¿Cuál es el tema principal o la idea de tu historia? Por ejemplo, experiencia personal, historia de éxito de un cliente, proceso de creación del producto.",
      form_story_character_label: "Personajes principales de la historia",
      form_story_character_text: "¿Quién es el personaje principal de tu historia? (por ejemplo, tú, tu cliente, tu producto) ¿Cuáles son las características clave de este personaje? (por ejemplo, edad, profesión, cualidades personales)",
      form_story_problem_label: "Problema al que se enfrenta el personaje principal al principio de la historia",
      form_story_problem_text: "¿Con qué problema se enfrenta el personaje principal al principio de la historia?",
      form_story_solution_label: "Solución propuesta al problema",
      form_story_solution_text: "¿Qué solución propuso tu marca, producto o tú?",
      form_story_result_label: "Descripción de los cambios después de usar la solución propuesta",
      form_story_result_text: "¿Cómo cambió la situación después de usar tu solución? ¿Cuáles son los resultados específicos?",
      form_story_emotions_label: "Emociones que quieres evocar en la audiencia",
      form_story_emotions_text: "¿Qué emociones quieres evocar en la audiencia? (por ejemplo, inspiración, alegría, curiosidad)",
      form_story_unique_moments_label: "Incorporación de detalles únicos o interesantes en la historia",
      form_story_unique_moments_text: "¿Hay algún detalle único o interesante que quieras incluir en la historia?",
      form_story_cta_label: "Acción deseada de la audiencia después de leer la historia",
      form_story_cta_text: "¿Qué acción quieres que tome la audiencia después de leer tu historia? (por ejemplo, visitar el sitio web, comprar el producto, suscribirse al boletín, escribir un mensaje directo)",
      form_story_qty_label: "Número de historias necesarias para contar completamente la historia",
      form_story_qty_text: "El Oráculo aconseja elegir entre 5 y 7",
      form_story_language_label: "Seleccionar idioma",
      form_story_language_text: "Aquí puedes seleccionar el idioma en el que se creará el contenido",
      story_goal_select_title: "Seleccionar objetivo",
      story_goal_select_1: "Vender un producto o servicio",
      story_goal_select_2: "Compartir una historia personal",
      story_goal_select_3: "Historias educativas",
      story_result_heading: 'Tu narración de historias sobre el tema - ',
      story_form_saved: "Formulario de narración de historias guardado",
      unable_to_save_project: "No se pudo guardar el proyecto.",
      unable_to_save_story: "No se pudo guardar el formulario de narración de historias.",
      saving: "Guardando...",
      story_fetch_error: 'Error al obtener la narración de historias',
      hints_missing_story_goal_text: "Por favor, selecciona un objetivo de narración de historias antes de generar sugerencias.",
      modal_text_story: "La generación de narraciones solo está disponible en planes de pago. Actualiza para acceder a esta función.",
      form_story_theme_required: "Por favor, complete el tema de la narración",
      incorrect_code: "Código incorrecto. Por favor, introduce otro código.",
      thanks_answer_accept_awaiting: "¡Gracias! Tu respuesta está esperando confirmación.",
      thanks_answer_accepted: "¡Gracias! Tu respuesta ha sido aceptada. ",
      you_received: "Has recibido",
      received_points: "puntos.",
      your_answer: "Tu respuesta:",
      see_screenshot: "Ver captura de pantalla",
      feedback_for_quest: "Comentarios para la misión",
      your_answer_for_quest: "Tu respuesta para la misión",
      enter_code: "Introduce el código",
      answer_text: "Texto de la respuesta",
      screenshot: "Captura de pantalla",
      quests: "Misiones",
      lessons: "Lecciones",
      to_access_education: "Para acceder a las lecciones, necesitas adquirir un plan de pago",
      you_earned: "Has ganado",
      brain_points: "brain points",
      balance: "Saldo",
      landing_top_heading: "Desbloquea tu potencial de creación de contenido con CopyBrain",
      landing_top_subheading: "Generación de contenido impulsada por IA y misiones educativas para mejorar tu estrategia en redes sociales",
      landing_get_started: "COMENZAR GRATIS",
      landing_what_can_heading: "¿Qué puede hacer CopyBrain por ti?",
      landing_what_can_subheading: "Transforma tu estrategia en redes sociales con funciones impulsadas por IA",
      landing_what_can_item_1_heading: "Generar Ideas de Contenido Atractivo",
      landing_what_can_item_1_text: "¿Dificultad para encontrar nuevas ideas de contenido? La IA de CopyBrain genera ideas frescas y atractivas de contenido adaptadas a tu audiencia.",
      landing_what_can_item_2_heading: "Crea Publicaciones, Reels y Historias Perfectas para Redes Sociales",
      landing_what_can_item_2_text: "Crea contenido atractivo para redes sociales sin esfuerzo con nuestra herramienta de generación de contenido impulsada por IA.",
      landing_what_can_item_3_heading: "Perfecciona tu Biografía de Perfil",
      landing_what_can_item_3_text: "Crea la biografía perfecta para tus perfiles en redes sociales con sugerencias generadas por IA.",
      landing_what_can_item_4_heading: "Analiza tu Perfil con una Captura de Pantalla",
      landing_what_can_item_4_text: "Sube una captura de pantalla de tu perfil de Instagram y obtén información práctica sobre cómo mejorarlo.",
      landing_learn_earn_heading: "Aprende y Gana con las Misiones Educativas de CopyBrain",
      landing_learn_earn_text: "¡Presentamos nuestra nueva Sección Educativa! Completa misiones educativas, acumula puntos y cámbialos por bonificaciones dentro de CopyBrain. Mejora tus habilidades en redes sociales mientras te diviertes.",
      landing_how_heading: "Cómo Funciona CopyBrain",
      landing_how_item_1_heading: "Regístrate",
      landing_how_item_1_text: "Crea tu cuenta gratuita para comenzar.",
      landing_how_item_2_heading: "Genera Contenido",
      landing_how_item_2_text: "Utiliza nuestras herramientas impulsadas por IA para generar ideas de contenido, publicaciones en redes sociales y más.",
      landing_how_item_3_heading: "Mejora y Aprende",
      landing_how_item_3_text: "Completa misiones educativas para mejorar tus habilidades y ganar recompensas.",
      landing_how_item_4_heading: "Analiza y Mejora",
      landing_how_item_4_text: "Sube capturas de pantalla de tu perfil para obtener un análisis profundo e información práctica.",
      landing_pricing_heading: "Planes de Precios",
      landing_pricing_text: "Elige el plan perfecto para ti",
      landing_pricing_item_1_heading: "Gratis",
      landing_pricing_item_2_heading: "Inicial",
      landing_pricing_item_3_heading: "Pro",
      landing_pricing_item_4_heading: "Empresarial",
      landing_ready_heading: "¿Listo para mejorar tu presencia en redes sociales?",
      access_to_quests: "acceso a misiones educativas",
      month: "por mes",
      select_reel_format: "Seleccione el formato de su video",
      select_reel_cta: "Seleccione llamada a la acción",
      generate_reel_writing: "¿Generar leyenda para reels?",
      thematic_selection: "Selección temática",
      useful_lifehacks: "Trucos útiles",
      educational_video: "Video educativo",
      success_story: "Historia de éxito",
      product_demonstration: "Demostración de productos",
      life_stories_and_funny_cases: "Historias de vida y casos divertidos",
      before_after: "Antes y después",
      scenes_with_characters: "Escenas con personajes",
      text_on_background: "Texto sobre fondo",
      storytelling: "Narración",
      talking_head: "Cabeza parlante",
      subscribe: "Suscríbete",
      more_in_highlights: "Más en destacados",
      more_useful_information_in_profile: "Más información útil en el perfil",
      write_the_code_word_in_comments_to_receive: "Escribe la palabra clave en los comentarios para recibir",
      send_the_code_word_in_direct_to_receive: "Envía la palabra clave por mensaje directo para recibir",
      select_reel_settings: "Seleccione la configuración del reel",
      untitled: "Sin título",
      select_country: "Seleccione su país",
      supported_by: "Con el apoyo de",
      created_by: "Creado por",
      form_text_customisation_label: "Personalización",
      form_text_customisation_text: "Por favor, sube una muestra de tu escritura. COPY BRAIN utilizará esta muestra para igualar el estilo, tono y estructura del texto generado a tu estilo único de escritura. Asegúrate de que la muestra refleje tu forma típica de escribir.",
      hide: "Ocultar",
      show_full: "Expandir completamente",
      select_social: "¿Para qué red social es la publicación?",
      select_post_settings: "Selecciona la configuración de la publicación",
      post_adapted_for: "Publicación adaptada para",
      year: "Año",
      landing_pricing_text_new: "Un plan para todos",
      landing_team_heading: "Equipo",
    },
  },
  it: {
    translation: {
      welcome: 'Benvenuto su CopyBrain!',
      description: 'CopyBrain è un servizio per generare idee creative per post sui social media con l\'AI',
      description_2: 'Inizia a creare post creativi per il tuo business utilizzando il potere dell\'intelligenza artificiale',
      signUp: 'Registrati',
      login: 'Accedi',
      logout: 'Esci',
      generate: 'Genera argomenti',
      form_project_name_label: 'Nome del Progetto',
      form_project_name_text: 'Questa informazione è per te',
      form_niche_label: 'Qual è il tema principale o la nicchia del tuo account?',
      form_niche_text: 'Ad esempio, moda, viaggi, tecnologia, fitness, cucina, educazione',
      form_audience_label: 'Età, genere, interessi, posizione geografica',
      form_audience_text: 'Chi è il tuo pubblico di riferimento?',
      form_audience_characteristics_label: 'Quali caratteristiche specifiche ha il tuo pubblico di riferimento?',
      form_audience_characteristics_text: 'Ci sono problemi, questioni o domande specifiche che sono particolarmente importanti per loro?',
      form_content_goal_label: 'Quali obiettivi hai per il contenuto?',
      form_content_goal_select_title: 'Scegli un obiettivo',
      form_content_goal_select_1: 'Aumentare l\'engagement',
      form_content_goal_select_2: 'Aumentare il numero di follower',
      form_content_goal_select_3: 'Vendita di prodotti o servizi',
      form_content_goal_select_4: 'Contenuto educativo',
      form_text_tone_label: 'Che stile e tono dovrebbe avere il post?',
      form_text_tone_select_title: 'Scegli lo stile e il tono',
      form_text_tone_select_1: 'Informativo',
      form_text_tone_select_2: 'Ispirante',
      form_text_tone_select_3: 'Persuasivo',
      form_text_tone_select_4: 'Amichevole',
      form_product_service_label: 'Quale prodotto o servizio specifico sarà evidenziato?',
      form_product_service_text: 'Se possibile, fornisci una descrizione dettagliata. (Ad esempio, formato, durata, casi studio, recensioni, costo)',
      select_theme_to_generate_post: 'Scegli un argomento per generare un post',
      generating: 'Elaborazione in corso...',
      generate_themes: 'Genera argomenti',
      themes_form_heading: 'Generatore di argomenti per post sui social media',
      text_copied: 'Testo copiato!',
      copy_text: 'Copia testo',
      error_while_processing: 'Si è verificato un errore durante l\'elaborazione della tua richiesta:',
      back: 'Torna indietro',
      post_result_heading: 'Il tuo post sull\'argomento - ',
      select_language: 'Scegli una lingua',
      select_language_text: 'Qui puoi selezionare la lingua in cui sarà generato il contenuto',
      in_latvian: 'Lettone',
      in_english: 'Inglese',
      in_russian: 'Russo',
      in_spanish: 'Spagnolo',
      in_italian: 'Italiano',
      admin_panel: 'Pannello di Amministrazione',
      project: 'Progetto',
      projects: 'Progetti',
      projects_r: 'Progetti',
      login_heading: 'Accedi a CopyBrain',
      login_subheading: 'Accedi per continuare',
      signup_heading: 'Benvenuto su CopyBrain',
      signup_subheading: 'Inizia subito a creare post creativi',
      email: 'Email',
      password: 'Password',
      full_name: 'Nome Completo',
      already_signed_up: 'Hai già un account? ',
      dont_have_account: 'Non hai un account? ',
      log_in_here: 'Accedi qui',
      please_register: 'Registrati ',
      projects_loading: 'Caricamento progetti...',
      projects_not_found: 'Progetti non trovati. Puoi creare un nuovo progetto.',
      your_projects: 'I tuoi Progetti',
      ideas: 'Idee',
      posts: 'Post',
      posts_r: 'Post',
      reels: 'Reels',
      saved_posts_ideas: 'Idee di Post Salvate',
      saved_posts: 'Post Salvati',
      information_about_project: 'Informazioni sul Progetto',
      save_project: 'Salva progetto',
      registration_error: 'Si è verificato un errore durante la registrazione. Riprova.',
      login_error: 'Si è verificato un errore durante l\'accesso. Riprova.',
      users_fetch_error: 'Errore nel recupero della lista utenti.',
      projects_fetch_error: 'Errore nel recupero della lista progetti.',
      themes_fetch_error: 'Errore nel recupero della lista argomenti.',
      post_fetch_error: 'Errore nel recupero del post.',
      profile: 'Profilo',
      change_password: 'Cambia Password',
      new_password: 'Nuova Password',
      password_change_success: 'Password modificata con successo',
      error_changing_password: 'Errore nella modifica della password',
      your_details: 'I tuoi Dati',
      current_plan: 'Piano Selezionato',
      free_plan: 'Gratuito',
      unlimited: 'Illimitato',
      themes: 'Temi',
      themes_r: 'Temi',
      month: 'mese',
      choose: 'Scegli',
      thank_you_for_your_purchase: 'Grazie per il tuo acquisto!',
      your_subscription_has_been_activated: 'Il tuo abbonamento è stato attivato',
      start_generating_projects: 'Inizia a generare contenuti',
      subscription_expires_at: 'Abbonamento attivo fino al',
      billing: 'Fatturazione',
      modal_heading_plan_needed: 'Funzionalità Avanzate',
      modal_text_projects: 'Per creare un nuovo progetto, è necessario passare a uno dei piani a pagamento. Questo ti darà accesso a funzionalità avanzate e alla possibilità di gestire più progetti.',
      modal_text_themes: 'La generazione di argomenti è disponibile solo con i piani a pagamento. Passa a uno di essi per utilizzare questa funzione.',
      continue_to_payment: 'Procedi al Pagamento',
      cancel_subscription: 'Annulla Abbonamento',
      confirm_cancellation: 'Conferma Cancellazione',
      are_you_sure_cancel_subscription: 'Sei sicuro di voler annullare il tuo abbonamento? L\'abbonamento rimarrà attivo fino al termine del periodo attuale.',
      confirm: 'Conferma',
      cancel: 'Annulla',
      subscription_canceled: 'Abbonamento annullato',
      subscription_renew: 'Rinnova Abbonamento',
      confirm_renew: 'Rinnova Abbonamento',
      are_you_sure_renew_subscription: 'Sei sicuro di voler rinnovare il tuo abbonamento? Questo ti permetterà di riottenere l\'accesso a tutti i vantaggi del piano, inclusa la creazione illimitata di argomenti e post.',
      subscription_plan_will_be_selected: 'Il piano sarà selezionato al termine del periodo di abbonamento.',
      subscription_upgrade: 'Upgrade dell\'Abbonamento',
      subscription_upgrade_modal_text: 'Stai per passare a un piano di abbonamento diverso. Il tuo piano attuale sarà aggiornato, e il nuovo importo sarà calcolato in proporzione al tempo rimanente fino alla fine del periodo di fatturazione. Ciò significa che pagherai solo per il tempo rimanente al costo del nuovo piano.\nPunti chiave:\n- Il nuovo piano avrà effetto immediato.\n- Riceverai funzionalità e vantaggi aggiuntivi in base al piano selezionato.\n- L\'importo proporzionato sarà calcolato e addebitato automaticamente sul tuo account.',
      select_content_type: 'Seleziona Tipo di Contenuto',
      create_post: 'Crea Post',
      create_reel_scenario: 'Crea Scenario per Reel',
      select_reel_duration: 'Seleziona Durata del Reel',
      seconds: 'secondi',
      reel_result_heading: 'Il tuo scenario sull\'argomento - ',
      scenarios: 'Scenari',
      saved_scenarios: 'Scenari Salvati',
      scenario_fetch_error: 'Errore nel recupero dello scenario',
      scenarios_r: 'Scenari',
      contacts: 'Contatti',
      ask_question: 'Fai una Domanda',
      question_subheading: 'Inserisci la tua domanda qui sotto. Invieremo la risposta alla tua email:',
      send: 'Invia',
      question_message: 'La tua richiesta è stata inviata, ti risponderemo al più presto.',
      subscription_link_error: 'Errore nel procedere al pagamento. Riprova.',
      subscription_cancel_error: 'Errore nella cancellazione dell\'abbonamento. Riprova.',
      subscription_renew_error: 'Errore nel rinnovo dell\'abbonamento. Riprova.',
      subscription_load_error: 'Errore nel controllo dell\'abbonamento. Riprova.',
      questions_list: 'Elenco delle Domande',
      profiles: 'Intestazione del Profilo',
      saved_profiles: 'Intestazioni di Profilo Salvate',
      generate_profiles: 'Genera intestazione del Profilo',
      modal_text_profiles: "La generazione dell'intestazione del profilo è disponibile solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      projects_panel: "Pannello di gestione dei progetti",
      project_information_subtext: "Compila le informazioni chiave sul tuo progetto. <br/> In base a queste informazioni, genereremo idee per i contenuti.",
      post_ideas_information_subtext: "Sfoglia le idee salvate, i post e gli scenari. <br/> Clicca su un argomento per generare un post o uno scenario.",
      posts_information_subtext: "Elenco dei tuoi post generati. <br/> Clicca su un argomento per visualizzare il post.",
      scenarios_information_subtext: "Elenco dei tuoi scenari generati. <br/> Clicca su un argomento per visualizzare lo scenario.",
      profiles_information_subtext: "Elenco delle intestazioni del profilo da te generate.",
      generate_hint: "Genera risposta",
      hints_missing_niche_text: "Compila il campo 'tema o nicchia' prima di generare suggerimenti.",
      write_for_me: "Scrivi per me",
      profile_analysis: "Analisi del profilo",
      analyzing: "Analisi in corso...",
      upload_analyze: "Carica e analizza",
      upload_image_for_analysis: "Carica uno screenshot del tuo profilo",
      upload_image_for_analysis_text: "Il nostro strumento di analisi del profilo ti consente di caricare uno screenshot del tuo profilo e di ricevere raccomandazioni per migliorarlo. Una volta completata l'analisi, i risultati appariranno automaticamente sullo schermo, così potrai rivedere immediatamente i suggerimenti utili.",
      analysis_result: "Suggerimenti per il miglioramento del profilo",
      saved_analyses_results: "Risultati dell'analisi del profilo salvati",
      analyses_information_subtext: "Elenco delle tue analisi del profilo salvate",
      modal_text_analyses: "L'analisi del profilo è disponibile solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      profile_generation_heading: "Generazione intestazione profilo Instagram",
      profile_generation_text: "Con il nostro strumento, puoi creare rapidamente e facilmente l'intestazione perfetta per il tuo profilo Instagram utilizzando i dati del tuo progetto. Compila semplicemente il modulo con le informazioni sul tuo progetto e clicca su 'Genera'. La tua nuova intestazione sarà pronta in pochi secondi, offrendo un aspetto professionale e attraente al tuo profilo.",
      profile_generated: "Intestazione del profilo generata",
      custom_theme_heading: "Genera post in base al tuo tema",
      custom_theme_text: 'Inserisci un testo che descriva il tema principale del tuo post, ad esempio: "Lancio di un nuovo prodotto". Seleziona la lingua desiderata dal menu a tendina. Clicca sul pulsante "Genera post". Sarai reindirizzato a una pagina con il post pronto, dove potrai visualizzarlo, copiarlo e utilizzarlo nel tuo profilo.',
      custom_theme_label: "Inserisci il testo che descrive il tema principale del tuo post",
      enter_theme: "Inserisci il tema",
      theme_required_error: "Il tema è obbligatorio",
      generate_post: "Genera post",
      modal_text_custom_theme: "La generazione di post basati sul proprio tema è disponibile solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      custom_scenario_theme_heading: "Genera scenario basato sul tuo tema",
      custom_scenario_theme_text: 'Inserisci un testo che descriva il tema principale del tuo scenario. Può essere qualsiasi cosa, dalla dimostrazione di un prodotto a una guida rapida. Nel menu a tendina, seleziona la lingua desiderata e la durata del video. Clicca sul pulsante "Genera scenario". Sarai reindirizzato a una pagina con lo scenario completato, dove potrai visualizzarlo, copiarlo e utilizzarlo nel tuo profilo.',
      custom_scenario_theme_label: "Inserisci il testo che descrive il tema principale del tuo scenario",
      generate_scenario: "Genera scenario",
      modal_text_custom_scenario_theme: "La generazione di scenari basati sul proprio tema è disponibile solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      email_required: "Inserisci la tua email",
      password_required: "Inserisci la tua password",
      user_not_found: "Utente con questa email non trovato",
      invalid_credentials: "Hai inserito una password errata",
      fullname_required: "Inserisci il tuo nome completo",
      user_already_exists: "L'utente con questa email esiste già",
      email_invalid: "Formato email non valido",
      forgot_password: "Hai dimenticato la password? ",
      recover: "Recupera",
      password_recovery: "Recupero password",
      enter_email_to_continue: "Inserisci la tua email per continuare",
      enter_new_password_to_continue: "Inserisci la tua nuova password per continuare",
      enter_new_password: "Inserisci nuova password",
      new_password_confirm: "Conferma nuova password",
      save: "Salva",
      check_your_email_for_instructions: "Controlla la tua email per le istruzioni su come reimpostare la password. Potrebbero volerci alcuni minuti.",
      email_not_found: "Indirizzo email non trovato. Verifica e riprova.",
      password_changed_continue_to_login: "Password reimpostata con successo. Ora puoi accedere con la nuova password.",
      link_is_inactive_generate_new: "Il link è inattivo o scaduto. Prova a generare un nuovo link.",
      passwords_do_not_match: "Le password non corrispondono",
      remember_password: "Ricordi la password?",
      days: "giorni",
      hours: "ore",
      minutes: "minuti",
      seconds: "secondi",
      until_travel: "Tempo rimanente fino al viaggio verso la terra delle reti neurali",
      game: "Gioco",
      enter_game: "Entra nel gioco",
      event_started: "Evento iniziato",
      admin_courses: "Corsi per amministratori",
      project_form_saved: "Progetto salvato",
      sent: "Inviato",
      modal_text_no_more_posts: "Hai raggiunto il limite di post. Ulteriori generazioni sono disponibili solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      modal_text_no_more_scenarios: "Hai raggiunto il limite di scenari. Ulteriori generazioni sono disponibili solo nei piani a pagamento. Passa a uno di essi per utilizzare questa funzione.",
      storytelling: "Storytelling",
      storytelling_r: "Storie",
      saved_stories: "Storie salvate",
      stories_information_subtext: "Elenco delle storie generate <br/> Clicca su un tema per visualizzare la storia",
      custom_story_theme_heading: "Genera storie con i tuoi dati",
      custom_story_theme_text: "Lo storytelling è progettato per creare storie strutturate e mirate per vari obiettivi, come la vendita di un prodotto o servizio, la condivisione di una storia personale o storie educative. Clicca sul pulsante 'Genera storia'. Sarai reindirizzato a una pagina con la storia completata, dove potrai visualizzarla, copiarla e utilizzarla nel tuo profilo.",
      generate_story: "Genera storia",
      save_story_form: "Salva modulo di storytelling",
      form_story_goal_label: "Seleziona l'obiettivo dello storytelling",
      form_story_goal_text: "Seleziona dall'elenco (vendita di un prodotto o servizio, condivisione di una storia personale, storie educative)",
      form_story_theme_label: "Tema principale o idea della storia",
      form_story_theme_text: "Qual è il tema principale o l'idea della tua storia? Ad esempio, esperienza personale, storia di successo di un cliente, processo di creazione del prodotto.",
      form_story_character_label: "Personaggi principali della storia",
      form_story_character_text: "Chi è il personaggio principale della tua storia? (ad esempio, tu, il tuo cliente, il tuo prodotto) Quali sono le caratteristiche principali di questo personaggio? (ad esempio, età, professione, qualità personali)",
      form_story_problem_label: "Problema affrontato dal personaggio principale all'inizio della storia",
      form_story_problem_text: "Quale problema affronta il personaggio principale all'inizio della storia?",
      form_story_solution_label: "Soluzione proposta al problema",
      form_story_solution_text: "Quale soluzione ha proposto il tuo marchio, prodotto o tu?",
      form_story_result_label: "Descrizione dei cambiamenti dopo l'uso della soluzione proposta",
      form_story_result_text: "Come è cambiata la situazione dopo l'uso della tua soluzione? Quali sono i risultati specifici?",
      form_story_emotions_label: "Emozioni che vuoi suscitare nel pubblico",
      form_story_emotions_text: "Quali emozioni vuoi suscitare nel pubblico? (ad esempio, ispirazione, gioia, curiosità)",
      form_story_unique_moments_label: "Inclusione di dettagli unici o interessanti nella storia",
      form_story_unique_moments_text: "Ci sono dettagli unici o interessanti che vorresti includere nella storia?",
      form_story_cta_label: "Azione desiderata dal pubblico dopo aver letto la storia",
      form_story_cta_text: "Quale azione vuoi che il pubblico intraprenda dopo aver letto la tua storia? (ad esempio, visitare il sito web, acquistare il prodotto, iscriversi alla newsletter)",
      form_story_qty_label: "Numero di Storie Necessarie per Raccontare Completamente la Storia",
      form_story_qty_text: "L'Oracolo consiglia di scegliere tra 5 e 7",
      form_story_language_label: "Seleziona la Lingua",
      form_story_language_text: "Qui puoi selezionare la lingua in cui verrà creato il contenuto",
      story_goal_select_title: "Seleziona Obiettivo",
      story_goal_select_1: "Vendita di un Prodotto o Servizio",
      story_goal_select_2: "Condivisione di una Storia Personale",
      story_goal_select_3: "Storie Educative",
      story_result_heading: 'Il Tuo Storytelling sul Tema - ',
      story_form_saved: "Modulo di Storytelling Salvato",
      unable_to_save_project: "Impossibile Salvare il Progetto.",
      unable_to_save_story: "Impossibile Salvare il Modulo di Storytelling.",
      saving: "Salvataggio in corso...",
      story_fetch_error: 'Errore durante il Recupero dello Storytelling',
      hints_missing_story_goal_text: "Seleziona un obiettivo di storytelling prima di generare suggerimenti.",
      modal_text_story: "La generazione dello storytelling è disponibile solo nei piani a pagamento. Passa a un piano superiore per accedere a questa funzione.",
      form_story_theme_required: "Compila il tema dello storytelling",
      incorrect_code: "Codice errato. Inserisci un altro codice.",
      thanks_answer_accept_awaiting: "Grazie! La tua risposta è in attesa di conferma!",
      thanks_answer_accepted: "Grazie! La tua risposta è stata accettata!",
      you_received: "Hai ricevuto",
      received_points: "punti.",
      your_answer: "La tua risposta:",
      see_screenshot: "Vedi screenshot",
      feedback_for_quest: "Feedback per la missione",
      your_answer_for_quest: "La tua risposta per la missione",
      enter_code: "Inserisci codice",
      answer_text: "Testo della risposta",
      screenshot: "Screenshot",
      quests: "Missioni",
      lessons: "Lezioni",
      to_access_education: "Per accedere alle lezioni, è necessario acquistare un piano a pagamento",
      you_earned: "Hai guadagnato",
      brain_points: "punti cervello",
      balance: "Saldo",
      landing_top_heading: "Sblocca il Tuo Potenziale di Creazione di Contenuti con CopyBrain",
      landing_top_subheading: "Generazione di contenuti basata sull'intelligenza artificiale e missioni educative per migliorare il tuo gioco sui social media",
      landing_get_started: "INIZIA GRATIS",
      landing_what_can_heading: "Cosa può fare CopyBrain per te?",
      landing_what_can_subheading: "Trasforma la tua strategia sui social media con funzionalità basate sull'intelligenza artificiale",
      landing_what_can_item_1_heading: "Genera Idee di Contenuti Coinvolgenti",
      landing_what_can_item_1_text: "Hai difficoltà a trovare nuove idee di contenuti? L'intelligenza artificiale di CopyBrain genera idee di contenuti fresche e coinvolgenti su misura per il tuo pubblico.",
      landing_what_can_item_2_heading: "Crea Post, Reel e Storie Perfetti per i Social Media",
      landing_what_can_item_2_text: "Crea facilmente contenuti coinvolgenti per i social media con il nostro strumento di generazione di contenuti basato sull'intelligenza artificiale.",
      landing_what_can_item_3_heading: "Perfeziona la Tua Biografia del Profilo",
      landing_what_can_item_3_text: "Crea la biografia perfetta per i tuoi profili social con suggerimenti generati dall'intelligenza artificiale.",
      landing_what_can_item_4_heading: "Analizza il Tuo Profilo con uno Screenshot",
      landing_what_can_item_4_text: "Carica uno screenshot del tuo profilo Instagram e ricevi approfondimenti utili su come migliorarlo.",
      landing_learn_earn_heading: "Impara e Guadagna con le Missioni Educative di CopyBrain",
      landing_learn_earn_text: "Presentiamo la nostra nuova sezione Educazione! Completa le missioni educative, raccogli punti e scambiali con bonus all'interno di CopyBrain. Migliora le tue competenze sui social media divertendoti.",
      landing_how_heading: "Come Funziona CopyBrain",
      landing_how_item_1_heading: "Registrati",
      landing_how_item_1_text: "Crea il tuo account gratuito per iniziare.",
      landing_how_item_2_heading: "Genera Contenuti",
      landing_how_item_2_text: "Usa i nostri strumenti di intelligenza artificiale per generare idee di contenuti, post sui social media e altro ancora.",
      landing_how_item_3_heading: "Migliora e Impara",
      landing_how_item_3_text: "Completa missioni educative per migliorare le tue competenze e guadagnare ricompense.",
      landing_how_item_4_heading: "Analizza e Migliora",
      landing_how_item_4_text: "Carica screenshot del profilo per un'analisi approfondita e consigli utili.",
      landing_pricing_heading: "Piani Tariffari",
      landing_pricing_text: "Scegli il piano perfetto per te",
      landing_pricing_item_1_heading: "Gratis",
      landing_pricing_item_2_heading: "Starter",
      landing_pricing_item_3_heading: "Pro",
      landing_pricing_item_4_heading: "Business",
      landing_ready_heading: "Pronto a migliorare la tua presenza sui social media?",
      access_to_quests: "accesso alle missioni educative",
      month: "Mese",
      select_reel_format: "Seleziona il formato del video",
      select_reel_cta: "Seleziona la call-to-action",
      generate_reel_writing: "Generare una didascalia per i reel?",
      thematic_selection: "Selezione tematica",
      useful_lifehacks: "Trucchi utili",
      educational_video: "Video educativo",
      success_story: "Storia di successo",
      product_demonstration: "Dimostrazione del prodotto",
      life_stories_and_funny_cases: "Storie di vita e casi divertenti",
      before_after: "Prima-dopo",
      scenes_with_characters: "Scene con personaggi",
      text_on_background: "Testo su sfondo",
      storytelling: "Storytelling",
      talking_head: "Testa parlante",
      subscribe: "Iscriviti",
      more_in_highlights: "Altro nelle storie in evidenza",
      more_useful_information_in_profile: "Altre informazioni utili nel profilo",
      write_the_code_word_in_comments_to_receive: "Scrivi la parola in codice nei commenti per ricevere",
      send_the_code_word_in_direct_to_receive: "Invia la parola in codice in direct per ricevere",
      select_reel_settings: "Seleziona le impostazioni del reel",
      untitled: "Senza titolo",
      select_country: "Seleziona il tuo paese",
      supported_by: "Supportato da",
      created_by: "Creato da",
      form_text_customisation_label: "Personalizzazione",
      form_text_customisation_text: "Carica un campione della tua scrittura. COPY BRAIN utilizzerà questo per adattare lo stile, il tono e la struttura del testo generato al tuo stile di scrittura unico. Assicurati che il campione rifletta il tuo modo abituale di scrivere.",
      hide: "Nascondi",
      show_full: "Espandi tutto",
      select_social: "Per quale social network è il post?",
      select_post_settings: "Seleziona le impostazioni del post",
      post_adapted_for: "Post adattato per",
      year: "Anno",
      landing_pricing_text_new: "Un piano per tutti",
      landing_team_heading: "Squadra",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getInitialLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

function getInitialLanguage() {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');

  if (lang) {
    localStorage.setItem('selectedLanguage', lang);
    
    return lang;
  }
  
  const hostname = window.location.hostname;

  if (hostname.includes('copybrain.es')) {
    return 'es';
  } else if (hostname.includes('copybrain.lv')) {
    return 'lv';
  }

  return 'en';
}

export default i18n;
