import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createCourse, updateCourse } from '../api';
import { useAuth } from '../AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CourseForm = ({ course, onSave }) => {
  const [title, setTitle] = useState(course?.title || '');
  const [shortDescription, setShortDescription] = useState(course?.short_description || '');
  const [description, setDescription] = useState(course?.description || '');
  const [active, setActive] = useState(course?.active || false);

  const token = localStorage.getItem('token');

  const quillRefShortDescription = useRef(null);
  const quillRefDescription = useRef(null);

  useEffect(() => {
    if (course) {
      setTitle(course.title);
      setShortDescription(course.short_description);
      setDescription(course.description);
      setActive(course.active);
    }
  }, [course]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const courseData = { 
      title, 
      short_description: shortDescription, 
      description, 
      active 
    };
    try {
      let savedCourse;
      if (course) {
        savedCourse = await updateCourse(course.id, courseData, token);
      } else {
        savedCourse = await createCourse(courseData, token);
      }
      onSave(savedCourse);
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };

  const modules = useMemo(() => ({
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ]
  }), []);

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <div className="dashboard_form_container">
      <form className="project_form" onSubmit={handleSubmit}>
        <label htmlFor="project-name">Название</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          required
        />

        <label htmlFor="project-name">Короткое описание</label>
        <textarea
          type="text"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          placeholder="Short Description"
          required
        />

        <label htmlFor="project-name">Описание</label>
        <ReactQuill
          ref={quillRefDescription}
          value={description}
          onChange={setDescription}
          modules={modules}
          formats={formats}
        />

        <label className="dashboard_form_checkbox_container">
          Active:
          <input
            type="checkbox"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </label>
        <button type="submit">{course ? 'Update Course' : 'Create Course'}</button>
      </form>
    </div>
  );
};

export default CourseForm;
