import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function Dashboard() {
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const { projectId, setProjectId, projectName, setProjectName, niche, setNiche, audience, setAudience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const resultsRef = useRef(null); // Создание рефа для контейнера с результатами
  const { t } = useTranslation();
  const { user, token, logout } = useAuth();

  useEffect(() => {
    if (typeof user?.first_user === 'undefined') {
      logout();
      navigate('/login');
    }
  }, [user, logout, navigate]);

  // Загрузка проектов при монтировании компонента
  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:3030/projects', {
  //         headers: {
  //           'Authorization': `Bearer ${token}`, // Используем токен для аутентификации
  //         },
  //       });

  //       console.log(response);
  //       console.log(response.data);

  //       // setProjects(response.data); // Сохраняем список проектов
  //     } catch (error) {
  //       console.error('Error fetching projects:', error);
  //     }
  //   };

  //   fetchProjects(); // Загружаем проекты
  // }, []);

  const handleGenerateIdeas = async (event) => {
    event.preventDefault();

    setLoading(true);

    setProgress(0); // Сбрасываем прогресс
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); // Обновляем прогресс каждые 10%
    }, 150); // Интервал обновления прогресса

    try {
      const contentParts = [];

      // Добавляем в запрос только заполненные поля
      if (niche) contentParts.push(`Ниша: ${niche}`);
      if (audience) contentParts.push(`Аудитория: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Особенности аудитории: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Цель: ${contentGoal}`);
      if (textTone) contentParts.push(`Стиль и тон: ${textTone}`);
      if (productService) contentParts.push(`Продукт или услуга: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const languagePart = selectedLanguage === 'ru'
        ? 'на русском языке'
        : selectedLanguage === 'lv'
        ? 'на латышском языке'
        : selectedLanguage === 'es'
        ? 'на испанском языке'
        : 'на английском языке';

      const model = user?.first_user ? "gpt-4-turbo-2024-04-09" : "gpt-3.5-turbo";

      console.log(model);
    
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: model,
        // model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: "Вы являетесь помощником, специализирующимся на создании идей для постов в Instagram. Отвечайте только темами, без комментариев и объяснений." },
          { role: "user", content: `Придумать список из 20 тем для постов в Instagram на основе: ${contentQuery}. Весь ответ напиши ${languagePart}. ` }
        ],
        temperature: 0.7,
        top_p: 0.8,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });
      const newIdeas = response.data.choices[0].message.content.trim().split('\n').filter(line => line);
      setIdeas(newIdeas);

      console.log(resultsRef.current);

      setTimeout(() => {
        if (resultsRef.current) {
          resultsRef.current.scrollIntoView({ behavior: 'smooth' }); // Прокрутка к результатам
        }
      }, 100);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      alert('Error when calling API: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIdeaClick = (idea) => {
    // Передаем нишу, аудиторию и тему в маршрут создания поста
    navigate('/create-post', { state: { niche, audience, theme: idea.split('. ')[1] } });
  };

  return (
    <div className="dashboard_form_container">
      <h1>{t('themes_form_heading')}</h1>
      <form onSubmit={handleGenerateIdeas}>
        <input
          type="hidden"
          id="project-id"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value)}
        />

        <label htmlFor="project-name">{t('form_project_name_label')}</label>

        <span className="dashboard_form_label_subtext">
          {t('form_project_name_text')}
        </span>

        <input
          type="text"
          id="project-name"
          value={projectName}
          onChange={e => setProjectName(e.target.value)}
          placeholder={t('form_project_name_label')}
        />

        <label htmlFor="niche">{t('form_niche_label')}</label>

        <span className="dashboard_form_label_subtext">
          {t('form_niche_text')}
        </span>

        <textarea
          type="text"
          id="niche"
          value={niche}
          onChange={e => setNiche(e.target.value)}
          placeholder={t('form_niche_label')}
        />

        <label htmlFor="audience">{t('form_audience_text')}</label>

        <span className="dashboard_form_label_subtext">
          {t('form_audience_label')}
        </span>

        <textarea
          type="text"
          id="audience"
          value={audience}
          onChange={e => setAudience(e.target.value)}
          placeholder={t('form_audience_label')}
        />

        <label htmlFor="audience-characteristics">{t('form_audience_characteristics_label')}</label>

        <span className="dashboard_form_label_subtext">
          {t('form_audience_characteristics_text')}
        </span>

        <textarea
          type="text"
          id="audience-characteristics"
          value={audienceCharacteristics}
          onChange={e => setAudienceCharacteristics(e.target.value)}
          placeholder={t('form_audience_characteristics_label')}
        />

        <label htmlFor="content-goal">{t('form_content_goal_label')}</label>

        <select
          id="content-goal"
          value={contentGoal}
          onChange={(e) => setContentGoal(e.target.value)}
        >
          <option value="" disabled>{t('form_content_goal_select_title')}</option>
          <option value={t('form_content_goal_select_1')}>{t('form_content_goal_select_1')}</option>
          <option value={t('form_content_goal_select_2')}>{t('form_content_goal_select_2')}</option>
          <option value={t('form_content_goal_select_3')}>{t('form_content_goal_select_3')}</option>
          <option value={t('form_content_goal_select_4')}>{t('form_content_goal_select_4')}</option>
        </select>

        <label htmlFor="text-tone">{t('form_text_tone_label')}</label>

        <select
          id="text-tone"
          value={textTone}
          onChange={(e) => setTextTone(e.target.value)}
        >
          <option value="" disabled>{t('form_text_tone_select_title')}</option>
          <option value={t('form_text_tone_select_1')}>{t('form_text_tone_select_1')}</option>
          <option value={t('form_text_tone_select_2')}>{t('form_text_tone_select_2')}</option>
          <option value={t('form_text_tone_select_3')}>{t('form_text_tone_select_3')}</option>
          <option value={t('form_text_tone_select_4')}>{t('form_text_tone_select_4')}</option>
        </select>

        <label htmlFor="product-service">{t('form_product_service_label')}</label>

        <span className="dashboard_form_label_subtext">
          {t('form_product_service_text')}
        </span>

        <textarea
          id="product-service"
          value={productService}
          onChange={e => setProductService(e.target.value)}
          placeholder={t('form_product_service_label')}
        />

        <label htmlFor="language">{t('select_language')}:</label>

        <span className="dashboard_form_label_subtext">
          {t('select_language_text')}
        </span>

        <select
          id="language"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="" disabled>{t('select_language')}</option>
          <option value="lv">{t('in_latvian')}</option>
          <option value="en">{t('in_english')}</option>
          <option value="ru">{t('in_russian')}</option>
        </select>

        {loading ? (
          <div className="loading-progress-bar">
            <ProgressBar animated now={progress} label={`${progress}%`} /> 
          </div>
        ) : (
          <button type="submit" disabled={loading}>{loading ? t('generating') : t('generate_themes')}</button>
        )}
      </form>

      {ideas.length > 0 && (
        <div ref={resultsRef} className="dashboard_results_container">
          <h2>{t('select_theme_to_generate_post')}:</h2>

          <div>
            {ideas.map((idea, index) => (
              <div className="dashboard_results_item" key={index} onClick={() => handleIdeaClick(idea)}>
                <p>{idea}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;