import React, { createContext, useContext, useState } from 'react';

// Контекст для хранения данных
const StoryContext = createContext();

export const useStory = () => useContext(StoryContext);

export const StoryProvider = ({ children }) => {
  const [storyId, setStoryId] = useState('');
  const [storyGoal, setStoryGoal] = useState('');
  const [storyTheme, setStoryTheme] = useState('');
  const [storyCharacter, setStoryCharacter] = useState('');
  const [storyProblem, setStoryProblem] = useState('');
  const [storySolution, setStorySolution] = useState('');
  const [storyResult, setStoryResult] = useState('');
  const [storyEmotions, setStoryEmotions] = useState('');
  const [storyMoments, setStoryMoments] = useState('');
  const [storyCta, setStoryCta] = useState('');
  const [storyQty, setStoryQty] = useState('5');
  const [selectedStoryLanguage, setSelectedStoryLanguage] = useState('en');

  return (
    <StoryContext.Provider value={{ storyId, setStoryId, storyGoal, setStoryGoal, storyTheme, setStoryTheme, storyCharacter, setStoryCharacter, storyProblem, setStoryProblem, storySolution, setStorySolution, storyResult, setStoryResult, storyEmotions, setStoryEmotions, storyMoments, setStoryMoments, storyCta, setStoryCta, storyQty, setStoryQty, selectedStoryLanguage, setSelectedStoryLanguage }}>
      {children}
    </StoryContext.Provider>
  );
};
