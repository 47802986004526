import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../css/homepage.css';

function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      navigate('/projects');
    }
  }, [user, navigate]);

  return (
    <div className="home-page">
      <div className="left-section">
        
      </div>

      <div className="right-section">
        <div className="homepage_top_inner_container">
          <h1>{t('welcome')}</h1>
          <p>{t('description')}</p>
          <p>{t('description_2')}</p>
          {user ? (
            <span></span>
          ) : (
            <div className="button-container">
              <button onClick={() => navigate('/signup')} className="home-button">{t('signUp')}</button>
              <button onClick={() => navigate('/login')} className="home-button">{t('login')}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
